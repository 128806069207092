import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/AssignmentInd";
import DescriptionIcon from "@material-ui/icons/Description";
import History from "@material-ui/icons/History";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import classnames from "classnames";
import startCase from "lodash/startCase";
import propTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  setProfileDrawerLoading,
  setProfileDrawerVisible,
  setProfileDrawerHeaderText,
} from "../../../actions/shared/uiActions";

import { buttonSets } from "../../../util/styleHelpers";

import AgencyChat from "../../employer/JobCasts/view/Candidates/view/CandidateDrawerTabs/AgencyChat";
import {
  getDataStatusFromStageLabel,
  PRE_ASSIGNMENT,
} from "../../employer/TempJobCasts/view/constants";
import IconTabs from "../../forms/custom/IconTabs";
import JobCastLocations from "../../General/JobCastLocations";
import EmployerChat from "../../recruiter/Candidates/view/CandidateDrawerTabs/EmployerChat";

import HistoryTab from "../CandidateDrawer/CandidateDrawerTabs/HistoryTab";

import ResumeTab from "../CandidateDrawer/CandidateDrawerTabs/ResumeTab";

import CandidateDrawerHeader from "./CandidateDrawerHeader";
import ContractWorkTab from "./CandidateDrawerTabs/ContractWorkTab/ContractWorkTab";

import ResponsesTab from "./CandidateDrawerTabs/ResponsesTab/ResponsesTab";
import {
  NEW,
  AGENCY_CHAT,
  EMPLOYER_CHAT,
  HISTORY,
  RESUME,
  RESPONSES,
  VIEW_RESUME,
  VIEW_RESPONSES,
  EVENT_HISTORY,
  ARCHIVED,
  UNCONFIRMED,
  AWAITING_SORT,
  CONTRACT_ACTIVE,
  CONTRACT_WORK_TAB,
  OFFER,
  HIRED,
} from "./constants";

const style = (theme) => ({
  profileDrawerButton: {
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "1px",
    padding: "6px 16px",
    height: "36px",
    boxShadow: "none",
    margin: "9px 0px 6px 0px",
  },
  footer: {
    flex: 1,
    backgroundColor: "#F5F5F5",
  },
  loadingContent: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  candidateHeaderContainer: {
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("900")]: {
      width: 900,
    },
  },
  tabContent: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "100%",
    [theme.breakpoints.down("900")]: {
      width: 900,
    },
  },
  headerTitle: {
    marginLeft: 20,
  },
});

const determineInitialTab = (navSelection) => {
  switch (navSelection) {
    case AGENCY_CHAT:
    case EMPLOYER_CHAT:
      return 0;
    case VIEW_RESUME:
      return 1;
    case VIEW_RESPONSES:
      return 2;
    case EVENT_HISTORY:
      return 3;
    case CONTRACT_WORK_TAB:
      return 4;
    default:
      return -1;
  }
};

class CandidateDrawer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: determineInitialTab(props.navSelection),
      loaded: false,
    };
  }

  fetchData = () => {
    const loadCandidate = () => {
      const { candidateId, fetchCandidate } = this.props;

      return fetchCandidate(candidateId);
    };

    const loadJobcast = async () => {
      const { jobcast, candidate, fetchJobcast } = this.props;

      if (!jobcast) {
        return fetchJobcast(candidate.attributes.jobcastId);
      }
    };

    loadCandidate()
      .then(loadJobcast)
      .then(() => this.setState({ loaded: true }));
  };

  componentDidMount() {
    this.props.setProfileDrawerHeaderText(this.handlePositionInfoHeaderText());
    this.fetchData();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.loaded && !prevState.loaded) {
      this.props.setProfileDrawerHeaderText(
        this.handlePositionInfoHeaderText()
      );
    }
  }

  handleTabChange = (_event, value) => {
    this.setState({ tabValue: value });
  };

  renderLoadingContent() {
    return (
      <div className={this.props.classes.loadingContent}>
        <CircularProgress size={128} style={{ marginTop: "50px" }} />
      </div>
    );
  }

  handlePositionInfoHeaderText() {
    const { jobcast, classes } = this.props;

    if (jobcast) {
      const { title, locations } = jobcast.attributes;
      return (
        <div className={classes.headerTitle}>
          <JobCastLocations
            locations={locations}
            preLocationText={`${title} - `}
            maxWidth={700}
          />
        </div>
      );
    }
    return "";
  }

  renderContentHeader() {
    const { status, offPlatform } = this.props.candidate.attributes;
    const {
      jobcast,
      classes,
      candidate,
      currentIndividual: { employer },
    } = this.props;

    const jobcastStatus = jobcast?.attributes?.status;
    const archived = jobcastStatus === ARCHIVED && (
      offPlatform ?
        ![OFFER, HIRED, CONTRACT_ACTIVE].includes(status) :
        ![HIRED, CONTRACT_ACTIVE].includes(status)
    );

    const roleBasedTooltip = employer
      ? "Agency chat is available after a candidate is sorted"
      : `The Chat tab is not available while the candidate is in ${status
        .split("_")
        .join(" ")} stage.`;

    const chatIconLabel = employer
      ? startCase(AGENCY_CHAT)
      : startCase(EMPLOYER_CHAT);

    const tooltipText = () => {
      if (
        status === NEW ||
        status === UNCONFIRMED ||
        status === AWAITING_SORT
      ) {
        return roleBasedTooltip;
      }
      if (jobcastStatus === ARCHIVED) {
        return "Agency Chat is disabled on Archived JobCasts";
      }
      return "";
    };

    const shouldShowContractInfo =
      candidate.attributes.hiredAt ||
      candidate.attributes.status ===
        getDataStatusFromStageLabel(PRE_ASSIGNMENT);

    const tabItems = [
      {
        icon: <QuestionAnswerIcon />,
        label: chatIconLabel,
        disabled:
          status === NEW ||
          status === UNCONFIRMED ||
          archived ||
          status === AWAITING_SORT,
        tooltipText: tooltipText(),
        show_badge: this.props.unread_chat_count > 0,
        badge_count: this.props.unread_chat_count,
      },
      { icon: <DescriptionIcon />, label: RESUME, disabled: false },
      { icon: <Assignment />, label: RESPONSES, disabled: false },
      {
        icon: <History />,
        label: HISTORY,
        disabled: false,
        show_badge: this.props.unread_history_count > 0,
        badge_count: this.props.unread_history_count,
      },
      {
        icon: <Assignment />,
        label: CONTRACT_WORK_TAB.replace("_", " "),
        disabled: !shouldShowContractInfo,
        tooltipText: shouldShowContractInfo
          ? undefined
          : "Contract work information is visible after a candidate is moved to Pre-Assignment",
      },
    ];

    return (
      <div
        className={classnames(
          "candidate-header-container",
          classes.candidateHeaderContainer
        )}
      >
        <CandidateDrawerHeader
          candidate={this.props.candidate}
          archived={archived}
          employer={employer}
        />
        <IconTabs
          items={tabItems}
          style={{ width: 900 }}
          value={this.state.tabValue}
          handleChange={this.handleTabChange}
        />
      </div>
    );
  }

  renderTabContent() {
    const {
      candidate,
      jobcast,
      jobcastStatus,
      currentIndividual: { employer },
    } = this.props;

    switch (this.state.tabValue) {
      case 0:
      default:
        return employer ? (
          <AgencyChat
            candidate={candidate}
            showIntroSection
            buttonStyle={buttonSets.intercom}
            jobcastStatus={jobcastStatus}
            jobcast={jobcast}
            multiTextStyle={{
              width: "70%",
              paddingTop: 12,
            }}
          />
        ) : (
          <EmployerChat
            candidate={candidate}
            showIntroSection
            buttonStyle={buttonSets.intercom}
            jobcastStatus={jobcastStatus}
            jobcast={jobcast}
            multiTextStyle={{
              width: "70%",
              paddingTop: 12,
            }}
          />
        );
      case 1:
        return <ResumeTab candidate={candidate} />;
      case 2:
        return <ResponsesTab candidate={candidate} employer={employer} />;
      case 3:
        return (
          <HistoryTab
            candidate={candidate}
            subjectType={[
              "EmployerCandidateUpdateNotification",
              "RecruiterCandidateUpdateNotification",
              "Transfer",
            ]}
          />
        );
      case 4:
        return <ContractWorkTab {...{ candidate, employer }} />;
    }
  }

  renderContent() {
    if (!this.state.loaded) {
      return this.renderLoadingContent();
    }
    return (
      <div
        className="drawer-content-container"
        data-cy="drawer-content-container"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowY: "auto",
          height: "100%",
        }}
      >
        {this.renderContentHeader()}
        <div
          className={this.props.classes.tabContent}
          data-cy="candidate-drawer-tab-content"
        >
          {this.renderTabContent()}
        </div>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.renderContent()}
        <footer className={classes.footer} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setProfileDrawerLoading: (isLoading) =>
    dispatch(setProfileDrawerLoading(isLoading)),
  setProfileDrawerNotVisible: () => dispatch(setProfileDrawerVisible(false)),
  setProfileDrawerHeaderText: (text) =>
    dispatch(setProfileDrawerHeaderText(text)),
});

CandidateDrawer.propTypes = {
  loading: propTypes.bool,
  candidate: propTypes.object.isRequired,
  navSelection: propTypes.string.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(style)(CandidateDrawer));
