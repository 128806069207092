import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { connect } from "formik";
import React from "react";

import { useSelector } from "react-redux";

import { getCurrentIndividual } from "../../../../../../selectors/individualSelectors";
import { getCurrentOrganization } from "../../../../../../selectors/organizationSelectors";
import OptimizedRatesIcon from "../../../../../shared/Icons/OptimizedRatesIcon";
import FieldLabel from "../../../../JobCasts/view/Description/sections/FieldLabel";

function FeeType({ temp = false, formik: { values, setFieldValue } }) {
  const currentIndividual = useSelector((state) => getCurrentIndividual(state));
  const currentOrganization = useSelector((state) => getCurrentOrganization(state));

  const customFeeLabelSuffix = currentIndividual.agencyManager
    ? ""
    : " - only an option for Agency Managers";

  return (
    <div>
      <FieldLabel name="Select A Fee Type" />
      <RadioGroup
        id={temp ? "tempFeeType" : "feeType"}
        onChange={(_, value) => setFieldValue(temp ? "tempFeeType" : "feeType", value)}
        style={{ padding: "0 0 16px 32px" }}
        value={temp ? values.tempFeeType : values.feeType}
      >
        <FormControlLabel
          control={<Radio color="primary" />}
          label={(
            <div style={{ display: "flex", alignItems: "center" }}>
              <OptimizedRatesIcon style={{ marginRight: 4, fontSize: 24 }} />
              RecruitiFi Optimized Fee
              {" "}
              <a
                style={{ marginLeft: 4 }}
                href="https://help.recruitifi.com/en/articles/5485229-what-is-the-recruitifi-optimized-fee-and-how-does-it-work"
                target="_blank"
                rel="noopener noreferrer"
              >
                (Learn More)
              </a>
            </div>
          )}
          value="optimized"
        />
        {currentOrganization.hasStandardizedPreferredFees ? (
          <FormControlLabel
            control={<Radio color="primary" />}
            label={`${currentOrganization.name}'s Standardized Fees`}
            value="standardized"
            disabled={!currentIndividual.agencyManager}
          />
        ) : (
          <FormControlLabel
            control={<Radio color="primary" />}
            label={`Custom Fee${customFeeLabelSuffix}`}
            value="custom"
            disabled={!currentIndividual.agencyManager}
            data-cy="customFee"
          />
        )}
      </RadioGroup>
    </div>
  );
}

export default connect(FeeType);
