import Badge from "@material-ui/core/Badge";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";

import {
  setNotificationsDrawerVisible,
  setNotificationsDrawerContent,
} from "../../../../actions/shared/uiActions";
import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import { getUnansweredPositionCorrespondences } from "../../../../selectors/jobcastUpdatesSelectors";
import {
  makeGetFilteredNotifications,
  UNREAD
} from "../../../../selectors/notificationsSelectors";
import NotificationsCenter from "../../../shared/NotificationsDrawer/NotificationsCenter";

const styles = () => ({
  badge: {
    height: 20,
    width: 20,
    minWidth: 20,
    minHeight: 20,
    top: 5,
    right: 3,
    color: "#fff",
    backgroundColor: "#FF6D6D"
  },
  activeIcon: {
    fontSize: 30,
    color: "#008DAE",
    cursor: "pointer"
  },
  inactiveIcon: {
    fontSize: 30,
    color: "#90A4AE",
    cursor: "pointer"
  }
});

function UserSectionAlerts({
  notificationsCount,
  unansweredQuestionsCount,
  classes,
  openNotificationsDrawer
}) {
  const withBadge = content => (
    <Badge
      classes={{ badge: classes.badge }}
      badgeContent={notificationsCount}
    >
      {content}
    </Badge>
  );

  if (notificationsCount) {
    return (
      <IconButton
        style={{
          height: 60, width: 60, color: "#90A4AE", marginRight: 4
        }}
        onClick={openNotificationsDrawer}
      >
        {withBadge(
          <Icon className={classes.activeIcon}>notifications_active</Icon>
        )}
      </IconButton>
    );
  }
  return (
    <IconButton
      style={{
        height: 60, width: 60, color: "#90A4AE", marginRight: 4
      }}
      onClick={openNotificationsDrawer}
    >
      <Icon
        className={classes.inactiveIcon}
      >
        notifications
      </Icon>
    </IconButton>
  );
}

const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();

  const mapStateToProps = state => {
    const currentIndividual = getCurrentIndividual(state);
    const filteredNotifications = getFilteredNotifications(state, {
      [UNREAD]: true,
      recipientId: currentIndividual.id,
    });

    return {
      notificationsCount: filteredNotifications.length,
      unansweredQuestionsCount: getUnansweredPositionCorrespondences(state).length
    };
  };

  return mapStateToProps;
};

const mapDisatchToProps = dispatch => ({
  openNotificationsDrawer: () => {
    dispatch(setNotificationsDrawerContent(<NotificationsCenter />));
    dispatch(setNotificationsDrawerVisible(true));
  }
});

export default connect(
  makeMapStateToProps,
  mapDisatchToProps
)(withStyles(styles)(UserSectionAlerts));
