import omit from "lodash/omit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import NumberFormat from "react-number-format";

const withDebouncedErrors = InputComponent => {
  class InputWithDebouncedErrors extends Component {
    state = { showError: false };

    componentDidUpdate({ touched: prevTouched }) {
      const { touched } = this.props;

      if (touched && !prevTouched) {
        this.killAwait();
      } else if (prevTouched && !touched) {
        clearTimeout(this.awaitingInputTimeout);
        this.setState({ showError: false });
      }
    }

    componentWillUnmount() {
      clearTimeout(this.awaitingInputTimeout);
    }

    awaitInput = () => {
      clearTimeout(this.awaitingInputTimeout);
      this.setState({ showError: false }, () => {
        this.awaitingInputTimeout = setTimeout(
          () => this.setState({ showError: true }),
          this.props.delayTime
        );
      });
    };

    killAwait = () => {
      clearTimeout(this.awaitingInputTimeout);
      this.setState({ showError: true });
    };

    handleFocus = (...args) => {
      this.awaitInput();
      this.props.onFocus && this.props.onFocus(...args);
    };

    handleChange = (...args) => {
      this.awaitInput();
      this.props.onChange && this.props.onChange(...args);
    };

    handleBlur = (...args) => {
      this.killAwait();
      this.props.onBlur && this.props.onBlur(...args);
    };

    render() {
      const { error, renderError, renderHelperText } = this.props;
      const { showError } = this.state;

      const errorMessage = (showError && error) || "";

      return (
        <InputComponent
          {...omit(this.props, ["touched"])}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          error={renderError(Boolean(errorMessage))}
          helperText={renderHelperText(errorMessage)}
        />
      );
    }
  }

  InputWithDebouncedErrors.defaultProps = {
    // customize the error prop on the input component
    renderError: hasError => hasError,
    // customize the helperText prop on the input component
    renderHelperText: debouncedErrorMessage => debouncedErrorMessage,
    delayTime: 3000
  };

  InputWithDebouncedErrors.propTypes = {
    error: PropTypes.node,
    touched: PropTypes.bool,
    renderError: PropTypes.func.isRequired,
    renderHelperText: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    delayTime: PropTypes.number
  };

  return InputWithDebouncedErrors;
};

export default withDebouncedErrors;

export const DebouncedNumberFormat = withDebouncedErrors(NumberFormat);
