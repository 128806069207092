import moment from "moment";
import React from "react";
import { Col, Row } from "react-grid-system";

import PageCardRowHeader from "../../../../../General/PageCardRowHeader";
import FieldSection, { Field } from "../../ResponsesTab/FieldSection";

function ContractStatusField({ contract }) {
  const determineStatusText = () => {
    if (!contract.attributes.activatedAt) return "Pending Pre-Assignment";

    if (moment(contract.attributes.endedAt).isBefore(moment())) return "Closed";

    return "Open";
  };

  return <Field label="Contract Status">{determineStatusText()}</Field>;
}

function JobTitleField({ jobcast }) {
  return <Field label="Job Title">{jobcast.attributes.title}</Field>;
}

function StartDateField({ contract }) {
  return (
    <Field label="Start Date">
      {moment(contract.attributes.scheduledStartDate).format("MMM Do, YYYY")}
    </Field>
  );
}

function EndDateField({ contract }) {
  return (
    <Field label="End Date">
      {contract.attributes.endedAt
        ? moment(contract.attributes.endedAt).format("MMM Do, YYYY")
        : "N/A"}
    </Field>
  );
}

function ContractLength({ contract }) {
  let length = -1;
  if (contract.attributes.endedAt) {
    const start = moment(contract.attributes.scheduledStartDate);
    if (moment().isAfter(start)) {
      length = moment(contract.attributes.endedAt).diff(start, "days");
    }
  } else {
    length = moment().diff(contract.attributes.scheduledStartDate, "days");
  }

  let content;
  if (length < 0) content = "N/A";
  else if (length <= 1) content = "1 Day";
  else content = `${length} Days`;

  return <Field label="Contract Length">{content}</Field>;
}

function ScheduledEndDate({ contract }) {
  return (
    <Field label="Tentative End Date">
      {moment(contract.attributes.scheduledEndDate).format("MMM Do, YYYY")}
    </Field>
  );
}

function PrimaryApproversField({ contract }) {
  return (
    <Field label="Primary Approvers">
      {contract.attributes.primaryApprovers.map((approver) => (
        <div key={approver.id}>{approver.name}</div>
      ))}
    </Field>
  );
}

function BillRateField({ candidate }) {
  return (
    <Field label="Bill Rate">
      {`$${candidate.attributes.hourlyBillRate.toFixed(2)} / hr`}
    </Field>
  );
}

function PayRateField({ candidate }) {
  return (
    <Field label="Pay Rate">
      {candidate.attributes.hourlyPayRate
        ? `$${candidate.attributes.hourlyPayRate.toFixed(2)} / hr`
        : "Not Provided by Agency"}
    </Field>
  );
}

function ContractInformation({ candidate, contract, jobcast }) {
  return (
    <>
      <PageCardRowHeader title="Contract Information" />
      <FieldSection>
        <Row>
          <Col xs={3}>
            <ContractStatusField contract={contract} />
          </Col>
          <Col xs={3}>
            <JobTitleField jobcast={jobcast} />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <StartDateField contract={contract} />
          </Col>
          <Col xs={3}>
            <EndDateField contract={contract} />
          </Col>
          <Col xs={3}>
            <ContractLength contract={contract} />
          </Col>
          <Col xs={3}>
            <ScheduledEndDate contract={contract} />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <PrimaryApproversField contract={contract} />
          </Col>
          <Col xs={3}>
            <BillRateField candidate={candidate} />
          </Col>
          <Col xs={3}>
            <PayRateField candidate={candidate} />
          </Col>
        </Row>
      </FieldSection>
    </>
  );
}

export default ContractInformation;
