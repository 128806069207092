import normalize from "json-api-normalizer";

import { GET, PATCH, POST } from "../../util/apiHelpers";

import {
  receiveCandidates,
  receiveCandidate,
  receiveConversation,
  receiveMessage,
} from "../shared/candidateActions";

export const fetchCandidates = (id) => (dispatch) => GET(`/api/v3/employer/jobcasts/${id}/candidates`).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(receiveCandidates(normalized.candidate));
});

export const fetchCandidate = (id) => (dispatch) => GET(`/api/v3/employer/candidates/${id}`).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(receiveCandidate(normalized.candidate[res.data.data.id]));
});

export const fetchConversation = (id) => (dispatch) => GET(`/api/v3/employer/candidates/${id}/conversations`).then((res) => {
  const normalized = normalize(res.data);
  const normalizedMessages = normalize(
    res.data.data.attributes.messages
  ).message;
  normalized.conversation[res.data.data.id].attributes.messages =
      normalizedMessages;
  return dispatch(
    receiveConversation(normalized.conversation[res.data.data.id])
  );
});

export const createMessage = (id, message) => (dispatch) => POST(
  `/api/v3/employer/candidates/${id}/conversations/messages`,
  message
).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(
    receiveMessage({
      message: normalized.message,
      conversationId:
          normalized.message[res.data.data.id].attributes.conversationId,
    })
  );
});

export const returnCandidate = (id, data) => (dispatch) => PATCH(
  `/api/v3/employer/candidates/${id}/return_to_previous_stage`,
  data
)
  .then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveCandidate(normalized.candidate[res.data.data.id]));
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const rejectCandidate = (data) => (dispatch) => PATCH(`/api/v3/employer/candidates/${data.candidateId}/reject`, data)
  .then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveCandidate(normalized.candidate[res.data.data.id]));
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const restoreCandidate = (data) => (dispatch) => PATCH(`/api/v3/employer/candidates/${data.candidateId}/restore`, data)
  .then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveCandidate(normalized.candidate[res.data.data.id]));
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const advanceCandidate = (id, data) => (dispatch) => PATCH(`/api/v3/employer/candidates/${id}/advance`, data)
  .then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveCandidate(normalized.candidate[res.data.data.id]));
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const shareCandidates = (data) => (dispatch) => POST("/api/v3/employer/candidates/share", data).catch((errors) => Promise.reject(errors.response.data));

export const transferCandidate =
  ({ candidateId, ...data }) => (dispatch) => PATCH(`/api/v3/employer/candidates/${candidateId}/transfer`, data)
    .then((res) => {
      const normalized = normalize(res.data);
      return dispatch(
        receiveCandidate(normalized.candidate[res.data.data.id])
      );
    })
    .catch((errors) => Promise.reject(errors.response.data));

export const submitReportFailure = (placementId, data) => (dispatch) => PATCH(
  `/api/v3/employer/placements/${placementId}/report_failure`,
  data
)
  .then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveCandidate(normalized.candidate[res.data.data.id]));
  })
  .catch((errors) => Promise.reject(errors.response.data));
