import filter from "lodash/filter";
import { createSelector } from "reselect";

export const individualsSelector = (state) => state.individuals.items;
export const currentIndividualIdSelector = (state) => state.individuals.currentIndividualId;

export const getCurrentIndividual = createSelector(
  individualsSelector,
  currentIndividualIdSelector,
  (individuals, currentIndividualId) => currentIndividualId &&
    individuals[currentIndividualId] &&
    individuals[currentIndividualId].attributes
);

export const getViewingAs = (state) => state.individuals.viewing_as;

export const getViewingAsOtherId = createSelector(
  [getViewingAs],
  (viewingAs) => viewingAs && (viewingAs.key === "me" ? null : viewingAs.key)
);

export const makeGetViewingIndividualId = () => createSelector(
  [getCurrentIndividual, getViewingAsOtherId],
  (currentIndividual, viewingAsOtherId) => viewingAsOtherId || currentIndividual.id
);

export const getCommunityIndividuals = createSelector(
  [individualsSelector],
  (individuals) => filter(individuals, ({ attributes }) => attributes.isCommunity && !attributes.isBlocked)
);

export const getBlockedCommunityIndividuals = createSelector(
  [individualsSelector],
  (individuals) => filter(individuals, ({ attributes }) => attributes.isBlocked)
);
