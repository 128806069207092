import normalize from "json-api-normalizer";

import { batch } from "react-redux";

import { GET, PATCH } from "../../util/apiHelpers";

import { receiveCandidate } from "../shared/candidateActions";
import { receivePlacements } from "../shared/financeActions";
import { receiveJobCast } from "../shared/jobcastActions";

export const RECEIVE_BILLING_STATEMENTS = "RECEIVE_BILLING_STATEMENTS";
export const RECEIVE_OPEN_TRANSACTIONS = "RECEIVE_OPEN_TRANSACTIONS";

export const receiveBillingStatements = (employer_statements) => ({
  type: RECEIVE_BILLING_STATEMENTS,
  payload: employer_statements,
});

export const receiveOpenTransactions = (open_transactions) => ({
  type: RECEIVE_OPEN_TRANSACTIONS,
  payload: open_transactions,
});

export const fetchBillingStatements = () => (dispatch) => GET("/api/v3/employer/organization/employer-statements").then(
  (res) => dispatch(receiveBillingStatements(res.data))
);

export const fetchOpenTransactions = () => (dispatch) => GET("/api/v3/employer/organization/employer-transactions").then(
  (res) => {
    dispatch(receiveOpenTransactions(res.data.data.attributes));
  }
);

export const fetchPlacement = (id) => (dispatch) => GET(`/api/v3/employer/placements/${id}`).then((res) => {
  const normalized = normalize(res.data);
  const { placement, jobcast, candidate } = normalized;

  return batch(() => {
    dispatch(receivePlacements(placement));
    dispatch(receiveJobCast(jobcast[res.data.data.attributes.position_id]));
    dispatch(
      receiveCandidate(
        candidate[res.data.data.attributes.recruiter_submission_id]
      )
    );
  });
});

export const updatePlacement = (id, data) => (dispatch) => PATCH(`/api/v3/employer/placements/${id}`, data).then((res) => {
  const normalized = normalize(res.data);
  const { placement, jobcast, candidate } = normalized;

  return batch(() => {
    dispatch(receivePlacements(placement));
    dispatch(receiveJobCast(jobcast[res.data.data.attributes.position_id]));
    dispatch(
      receiveCandidate(
        candidate[res.data.data.attributes.recruiter_submission_id]
      )
    );
  });
});
