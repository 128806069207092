import { withFormik } from "formik";
import map from "lodash/map";
import round from "lodash/round";
import React from "react";
import { connect } from "react-redux";

import { updateAgencyFees } from "../../../../actions/employers/agencyActions";
import { clearModal } from "../../../../actions/shared/uiActions";
import { makeGetAgencyOrganization } from "../../../../selectors/employer/agencySelectors";

import { getCurrentOrganization } from "../../../../selectors/organizationSelectors";
import { string, object, array } from "../../../../util/yup";
import {
  PERCENTAGE_OF_COMPENSATION,
  FLAT_FEE,
} from "../../../forms/formik/FormikFeeOptions/fieldHelpers";
import FormikFeeOptions from "../../../forms/formik/FormikFeeOptions/FormikFeeOptions";
import ActionModalContainer from "../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../General/ActionModal/ActionModalHeader";
import withSnackbar from "../../../General/withSnackbar";
import { FeeOptionsList } from "../../JobCastSettings/AgencyFees/sections/StandardizedFees";

import AdvancedOptionsSection from "../TempInviteAgency/AdvancedOptions";
import EmployerAbsorptionRateField from "../TempInviteAgency/shared/EmployerAbsorptionRateField";

import FeeType from "./AddAsLegacyModal/FormComponents/FeeType";

class ChangeFeeModal extends React.PureComponent {
  state = { loading: false };

  componentDidUpdate(prevProps) {
    const prevValues = prevProps.values;
    const currValues = this.props.values;

    if (
      currValues.feeType !== prevValues.feeType ||
      currValues.fee_options.length !== prevValues.fee_options.length
    ) {
      this.props.setFieldValue(
        "multiple_fees",
        currValues.feeType === "custom" && currValues.fee_options.length > 1
      );
    }
  }

  handleMultipleFeesClick = () => {
    const newFeeOptions = this.props.values.fee_options;
    newFeeOptions.push({ fee: "", fee_note: "" });
    this.props.setFieldValue("fee_options", newFeeOptions);
  };

  renderFeeOptions() {
    const { values, errors } = this.props;

    switch (values.feeType) {
      case "custom":
        return (
          <div style={{ paddingBottom: 10 }}>
            <FormikFeeOptions
              fieldArrayName="fee_options"
              formProps={{ values, errors }}
            />
          </div>
        );
      case "standardized":
        return (
          <div style={{ paddingBottom: 10, paddingLeft: 24 }}>
            <FeeOptionsList />
          </div>
        );
      case "optimized":
      default:
        return <></>;
    }
  }

  renderContent() {
    return (
      <>
        <div style={{ textAlign: "left" }}>
          If you update, add, or remove a Preferred Agency fee the agency will
          be notified via email.
          <br />
          New fee(s) will be applied to all future candidate submissions. This
          will not affect any hires that have been marked previously. For more
          on processing fees{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://help.recruitifi.com/en/articles/5014829-does-recruitifi-take-a-fee-from-agencies-on-hires"
          >
            click here.
          </a>
        </div>
        <div style={{ paddingTop: 20 }}>
          <FeeType />
        </div>
        {this.renderFeeOptions()}
        <AdvancedOptionsSection
          overrideStyle={{ container: { paddingLeft: 0, marginTop: 8 } }}
        >
          <EmployerAbsorptionRateField fieldId="employerAbsorptionRate" />
        </AdvancedOptionsSection>
      </>
    );
  }

  render() {
    const { handleSubmit, isSubmitting, agency } = this.props;
    const { organization } = agency;

    return (
      <ActionModalContainer color="grey">
        <ActionModalHeader
          title="Preferred Agency Fee Change"
          subtitle={organization.attributes.name}
        />
        <ActionModalContent>{this.renderContent()}</ActionModalContent>
        <ActionModalFooter
          actionText="Update Fee"
          handleAction={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </ActionModalContainer>
    );
  }
}

const ChangeFeeModalForm = withFormik({
  handleSubmit: (values, { props, setSubmitting }) => {
    const { clearModal, agency, updateAgencyFees } = props;

    const new_fee_options = map(values.fee_options, (fee_option) => ({
      fee_percentage:
        fee_option.fee_type === PERCENTAGE_OF_COMPENSATION
          ? round(parseFloat(fee_option.fee) / 100, 4)
          : null,
      fee_cents:
        fee_option.fee_type === FLAT_FEE
          ? parseFloat(fee_option.fee.replace(/,/g, "")) * 100
          : null,
      note: fee_option.fee_note,
      id: fee_option.id,
      fee_type: fee_option.fee_type,
      currency: fee_option.currency,
    }));
    const fee_updates = {
      id: agency.preferredLink.id,
      fee_options: new_fee_options,
      fee_type: values.feeType,
      legacy_invitation_id: agency.invitation && agency.invitation.id,
      employer_absorption_rate: values.employerAbsorptionRate,
    };

    updateAgencyFees(fee_updates)
      .then(() => {
        setSubmitting(false);
        clearModal();
        fee_updates.fee_options.length > 1
          ? props.snackbar.showMessage("Fees successfully updated")
          : props.snackbar.showMessage("Fee successfully updated");
      })
      .catch(() => {
        setSubmitting(false);
      });
  },
  mapPropsToValues: (props) => {
    const { preferredLink } = props.agency;
    const feeOptions = map(
      preferredLink.attributes.feeOptions,
      (feeOption) => ({
        fee:
          feeOption.feeType === PERCENTAGE_OF_COMPENSATION
            ? round(feeOption.feePercentage * 100, 2).toString()
            : (feeOption.feeCents / 100).toString(),
        fee_note: feeOption.note,
        id: feeOption.id,
        fee_type: feeOption.feeType,
        currency: feeOption.currency,
      })
    );

    if (feeOptions.length === 0) {
      feeOptions.push({
        fee: "",
        fee_note: "",
        fee_type: PERCENTAGE_OF_COMPENSATION,
        currency: null,
      });
    }

    const determineFeeType = () => {
      if (preferredLink.attributes.optimizedFee) {
        return "optimized";
      }

      if (props.currentOrganization.hasStandardizedPreferredFees) {
        return "standardized";
      }

      return "custom";
    };

    const feeType = determineFeeType();

    return {
      fee_options: feeOptions,
      feeType,
      multiple_fees: feeType === "custom" && feeOptions.length > 1,
      employerAbsorptionRate: parseFloat(
        preferredLink.attributes.employerAbsorptionRate
      ),
    };
  },
  validationSchema: object().shape({
    fee_options: array().when("feeType", {
      is: "custom",
      then: array().when("multiple_fees", {
        is: true,
        then: array().of(
          object().shape({
            fee_type: string(),
            fee: string().when("fee_type", {
              is: PERCENTAGE_OF_COMPENSATION,
              then: string()
                .validFeePercentage()
                .test(
                  "minValPercent",
                  "Must be greater than 1%",
                  (val) => parseFloat(val) > 1
                )
                .test(
                  "maxValPercent",
                  "Must be less than 100",
                  (val) => parseFloat(val) < 100
                )
                .required("Cannot be blank"),
              otherwise: string().test(
                "minValPercent",
                "Must be greater than 1%",
                (val) => parseFloat(val) > 1
              ),
            }),
            fee_note: string().required("Cannot be blank"),
            currency: string().when("fee_type", {
              is: FLAT_FEE,
              then: string().required("Cannot be blank").nullable(),
              otherwise: string().nullable(),
            }),
          })
        ),
        otherwise: array().of(
          object().shape({
            fee: string().when("fee_type", {
              is: PERCENTAGE_OF_COMPENSATION,
              then: string()
                .validFeePercentage()
                .test(
                  "minValPercent",
                  "Must be greater than 1%",
                  (val) => parseFloat(val) > 1
                )
                .test(
                  "maxValPercent",
                  "Must be less than 100",
                  (val) => parseFloat(val) < 100
                )
                .required("Cannot be blank"),
              otherwise: string()
                .test(
                  "minValPercent",
                  "Must be greater than 1%",
                  (val) => parseFloat(val) > 1
                )
                .required("Cannot be blank"),
            }),
            currency: string().when("fee_type", {
              is: FLAT_FEE,
              then: string().required("Cannot be blank").nullable(),
              otherwise: string().nullable(),
            }),
          })
        ),
      }),
    }),
  }),
})(ChangeFeeModal);

const makeMapStateToProps = () => {
  const getAgencyOrganization = makeGetAgencyOrganization();

  return (state, { organizationId }) => ({
    agency: getAgencyOrganization(state, organizationId),
    currentOrganization: getCurrentOrganization(state),
  });
};

const mapDispatchToProps = (dispatch) => ({
  clearModal: () => dispatch(clearModal()),
  updateAgencyFees: (fees) => dispatch(updateAgencyFees(fees)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withSnackbar()(ChangeFeeModalForm));
