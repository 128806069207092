import moment from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import queryString from "query-string";
import React from "react";
import { withCookies } from "react-cookie";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import IntlTelInput from "react-intl-tel-input";

import { calculateResponsiveState } from "redux-responsive";

import { processFeatureFlags } from "./actions/shared/featureFlagActions";
import { processIndividual } from "./actions/shared/individualActions";
import GlobalSnackbarProvider from "./components/General/GlobalSnackbarProvider";
import Main from "./components/Main";
import UserActionCableProvider from "./components/shared/ActionCable/UserActionCableProvider";

// helpers
import configureStore from "./reducers/store";
import { GET, PATCH } from "./util/apiHelpers";
import { storageAvailable } from "./util/storageHelpers";
import theme from "./util/theme";

class App extends React.Component {
  state = {
    store: configureStore(),
    loading: true,
  };

  componentDidMount() {
    const doneLoading = () => ({ loading: false });
    window.addEventListener("resize", () => this.state.store.dispatch(calculateResponsiveState(window)));

    GET("/api/v3/authentication/feature_flags").then((res) => {
      processFeatureFlags(res.data, this.state.store.dispatch);
    });

    if (
      storageAvailable() &&
      this.props.allCookies &&
      this.props.allCookies.authToken &&
      global.localStorage.authToken !== this.props.allCookies.authToken
    ) {
      global.localStorage.setItem("authToken", this.props.allCookies.authToken);
      document.cookie =
        "authToken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }

    if (
      storageAvailable() &&
      window.location &&
      window.location.search &&
      queryString.parse(window.location.search).userToken
    ) {
      const query_params = queryString.parse(window.location.search);

      global.localStorage.setItem("authToken", query_params.userToken);
    }

    if (
      storageAvailable() &&
      window.location &&
      window.location.search &&
      queryString.parse(window.location.search).error
    ) {
      const query_params = queryString.parse(window.location.search);

      global.localStorage.setItem("error", query_params.error);
    }

    if (
      storageAvailable() &&
      global.localStorage.authToken &&
      window.location &&
      window.location.search &&
      queryString.parse(window.location.search).talk_id
    ) {
      PATCH("/api/v3/authentication/logout");
      global.localStorage.removeItem("authToken");
      global.localStorage.removeItem("ghosting");
    }

    if (storageAvailable() && global.localStorage.authToken) {
      GET("/api/v3/authentication/me")
        .then((res) => processIndividual(res.data, this.state.store.dispatch))
        .then(() => this.setState(doneLoading))
        .catch(({ response }) => {
          if (response?.status === 401) {
            global.localStorage.removeItem("authToken");
            global.localStorage.removeItem("ghosting");
            if (
              !window.location.pathname.match(/login/) &&
              !window.location.pathname.match(/public/)
            ) {
              window.location = "/app/login";
            }
          }
          this.setState(doneLoading);
        });
    } else if (
      !window.location.pathname.match(/login/) &&
      !window.location.pathname.match(/public/)
    ) {
      window.location = "/app/login";
      this.setState(doneLoading);
    } else {
      this.setState(doneLoading);
    }
  }

  render() {
    return (
      <Provider store={this.state.store}>
        <GlobalSnackbarProvider>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={moment}>
              <BrowserRouter basename="app">
                <UserActionCableProvider>
                  <Main
                    loading={this.state.loading}
                  />
                </UserActionCableProvider>
              </BrowserRouter>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </GlobalSnackbarProvider>
      </Provider>
    );
  }
}

export default withCookies(App);
