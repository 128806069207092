import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentIndividual } from '../../../../../selectors/individualSelectors';
import { AGENCY, BOTH_PARTIES, EMPLOYER } from '../fieldHelpers';

function NoActionNeeded({ checklistItem, children }) {
  const individual = useSelector(getCurrentIndividual);

  if (checklistItem.responsibility !== BOTH_PARTIES) {
    let responsibility = '';
    if (checklistItem.responsibility === AGENCY && individual.employer) {
      responsibility = "Staffing Firm";
    }
    if (checklistItem.responsibility === EMPLOYER && individual.recruiter) {
      responsibility = "Company";
    }
    if (responsibility) {
      return (
        <span style={{ whiteSpace: "pre-wrap" }}>
          {`No action needed.\nResponsibility of ${responsibility}`}
        </span>
      );
    }
  }

  return (children);
}

export default NoActionNeeded;
