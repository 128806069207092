import { connect } from "formik";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { fetchJobCasts } from "../../../../../../actions/employers/jobcastActions";
import {
  getJobCasts,
  selectAttributes,
} from "../../../../../../selectors/jobcastSelectors";
import FormikAccordionInput from "../../../../../forms/formik/FormikAccordionInput";
import JobCastLocations from "../../../../../General/JobCastLocations";

function JobcastAssignment({ formik: { values, errors } }) {
  const dispatch = useDispatch();

  const jobcastsLoaded = useSelector((state) => state.jobcasts.loaded);
  const jobcasts = selectAttributes(
    useSelector((state) => getJobCasts(state, "OPEN"), shallowEqual),
    ["id", "title", "locations"]
  );

  useEffect(() => {
    if (!jobcastsLoaded) {
      dispatch(fetchJobCasts());
    }
  }, [jobcastsLoaded, dispatch]);

  const jobcastOptions =
    jobcastsLoaded &&
    map(jobcasts, (jobcast) => ({
      label: (
        <JobCastLocations
          locations={jobcast.locations}
          preLocationText={`${jobcast.title} - `}
        />
      ),
      value: jobcast.id,
    }));

  return (
    <div>
      {jobcastsLoaded && !isEmpty(jobcasts) && (
        <FormikAccordionInput
          id="jobcast_assignments"
          label="JobCast Assignments"
          title="Open Jobcasts"
          fieldArrayName="jobcast_assignments"
          formProps={{
            values,
            errors,
          }}
          options={jobcastOptions}
        />
      )}
    </div>
  );
}

export default connect(JobcastAssignment);
