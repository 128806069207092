import { connect } from "formik";
import React, { useEffect } from "react";

import FormikFeeOptions from "../../../../../forms/formik/FormikFeeOptions/FormikFeeOptions";
import { FeeOptionsList } from "../../../../JobCastSettings/AgencyFees/sections/StandardizedFees";

function AgencyEarns({ formik: { values, errors, setFieldValue } }) {
  const multipleItems = values.fee_options.length > 1;

  useEffect(() => {
    setFieldValue(
      "multiple_fees",
      Boolean(values.feeType === "custom" && multipleItems)
    );
  }, [values.feeType, multipleItems, setFieldValue]);

  switch (values.feeType) {
    case "custom":
      return (
        <>
          <div style={{ paddingBottom: 12 }}>
            Adding a Custom Fee will alert this agency of their fee and all
            previously and currently matched JobCasts will be updated. Hired
            candidates will not be affected.
          </div>
          <FormikFeeOptions
            fieldArrayName="fee_options"
            formProps={{ values, errors }}
          />
        </>
      );
    case "standardized":
      return (
        <div style={{ paddingLeft: 24 }}>
          <FeeOptionsList />
        </div>
      );
    case "optimized":
    default:
      return <></>;
  }
}

export default connect(AgencyEarns);
