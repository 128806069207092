import { Formik } from "formik";
import map from "lodash/map";
import omit from "lodash/omit";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createContract } from "../../../../../../../../actions/employers/contractActions";
import { getTempPrerequisites } from "../../../../../../../../actions/employers/tempPrerequisiteActions";
import { setProfileDrawerVisible } from "../../../../../../../../actions/shared/uiActions";
import { getJobCast } from "../../../../../../../../selectors/jobcastSelectors";
import {
  buildFullName,
  mapKeysToSnakeCase,
} from "../../../../../../../../util/formatHelpers";
import { string, object, array } from "../../../../../../../../util/yup";
import useSnackbar from "../../../../../../../General/customHooks/useSnackbar";

function defaultPreRequisiteState({ attributes: prerequisite }, jobcastDefaultPrerequisites) {
  const isDefault = jobcastDefaultPrerequisites.some(
    (defaultPrerequisite) => defaultPrerequisite.id === prerequisite.id
  );

  return {
    ...prerequisite,
    checked: isDefault,
  };
}

function FormInitializer({ candidate, children }) {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const tempPrerequisites = useSelector(getTempPrerequisites);
  const jobCast = useSelector((state) => getJobCast(state, candidate.attributes.jobcastId));
  const jobcastDefaultPrerequisites = jobCast.attributes.tempPrerequisites;

  const costCenters = useSelector((state) => state.costCenters.items);

  const initialValues = useMemo(() => {
    const {
      agencyName,
      agencyLogoUrl,
      recruiterFirstName,
      recruiterLastName,
      hourlyBillRate,
      availableStartDate,
    } = candidate.attributes;

    const costCenterIds = Object.keys(costCenters);

    return {
      // AGENCY INFORMATION (uneditable fields)
      agencyName,
      agencyLogoUrl,
      recruiterName: buildFullName(recruiterFirstName, recruiterLastName),
      hourlyBillRate,
      // WORK INFORMATION
      scheduledStartDate: availableStartDate,
      scheduledEndDate: null,
      primaryWorkState: null,
      primaryWorkZipCode: null,
      statementOfWorkDescription: null,
      temp_prerequisites: map(tempPrerequisites, (prerequisite) => defaultPreRequisiteState(prerequisite, jobcastDefaultPrerequisites)),
      // BILLING INFORMATION
      costCenterId: costCenterIds.length === 1 ? costCenterIds[0] : null,
      invoiceMemo: null,
      timesheetApproverIds: jobCast.attributes.timesheetApproverIds || [],
    };
  }, [candidate, costCenters, tempPrerequisites, jobcastDefaultPrerequisites, jobCast]);

  const validationSchema = useMemo(
    () =>
      object().shape({
        scheduledStartDate: string().nullable().required("Cannot be blank"),
        scheduledEndDate: string().nullable().required("Cannot be blank"), // MEDONOW: add tooltip
        primaryWorkState: string().nullable().required("Cannot be blank"),
        primaryWorkZipCode: string()
          .nullable()
          .required("Cannot be blank")
          .matches(/^\d*$/, "Digits only")
          .min(5, "Must be 5 characters")
          .max(5, "Must be 5 characters"),
        statementOfWorkDescription: string()
          .nullable()
          .max(1500, "Cannot be longer than 1500 characters"),
        costCenterId: string().nullable().required("Cannot be blank"),
        invoiceMemo: string().nullable(),
        timesheetApproverIds: array().of(string()).min(1, "Cannot be blank"),
      }),
    []
  );

  const onSubmit = useCallback(
    (values, { setSubmitting }) => {
      const submitValues = {
        ...mapKeysToSnakeCase(
          omit(values, [
            "agencyName",
            "agencyLogoUrl",
            "recruiterName",
            "hourlyBillRate",
          ])
        ),
        temp_prerequisites: values.temp_prerequisites.filter(prerequisite => prerequisite.checked),
        recruiter_submission_id: candidate.id,
      };

      return dispatch(createContract(submitValues))
        .then(() => {
          setSubmitting(false);
          dispatch(setProfileDrawerVisible(false));
          snackbar.showMessage(
            "Candidate successfully advanced",
            null,
            () => {},
            { duration: 3000 }
          );
          // open rate modal
        })
        .catch(() => {
          setSubmitting(false);
        });
    },
    [dispatch, candidate.id, snackbar]
  );

  return (
    <Formik {...{ initialValues, validationSchema, onSubmit }}>
      {children}
    </Formik>
  );
}

export default FormInitializer;
