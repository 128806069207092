import Chip from "@material-ui/core/Chip";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";
import Select, { components } from "react-select";

export const style = (theme) => ({
  input: {
    display: "flex",
    padding: 0,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflowY: "auto",
  },
  singleValue: {
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 999,
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
    height: 24,
  },
  menuList: {
    maxHeight: 210,
    overflowY: "auto",
    paddingBottom: 4,
    paddingTop: 4,
    position: "relative",
    boxSizing: "border-box",
  },
  cancelIcon: {
    height: 16,
    width: 16,
    color: "#90A4AE",
  },
  menuItem: {
    color: "#37474F",
    padding: "4px 8px",
  },
  disabledMenuItem: {
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  openUpwards: {
    boxShadow: "0px -2px 1px -1px rgba(0,0,0,0.2), 0px -1px 1px 0px rgba(0,0,0,0.14), 0px -1px 3px 0px rgba(0,0,0,0.12)",
    bottom: 35,
  }
});

export const defaultSelectStyles = {
  clearIndicator: (provided, _state) => ({
    ...provided,
    color: "#90A4AE",
    "&:hover": {
      color: "black",
    },
  }),
  dropdownIndicator: (provided, _state) => ({
    ...provided,
    color: "#90A4AE",
  }),
  multiValue: (provided, _state) => ({
    ...provided,
    color: "#90A4AE",
  }),
};

export function Control(props) {
  return (
    <TextField
      fullWidth
      onFocus={props.selectProps.handleShrink}
      onBlur={props.selectProps.handleShrink}
      error={props.selectProps.error}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          style: { height: "100%" },
          "data-cy": `${props.selectProps.id}-dropdown-select`,
          ...props.innerProps,
        },
      }}
      style={{ marginBottom: 0 }}
      {...props.selectProps.textFieldProps}
    />
  );
}

export const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

export function Menu(props) {
  const { classes, openUpwards } = props.selectProps;

  return (
    <Paper
      square
      className={classnames(
        classes.paper,
        openUpwards && classes.openUpwards
      )}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

export function MenuList({ selectProps, children }) {
  const { classes, menuListProps } = selectProps;

  return (
    <div className={classes.menuList} {...menuListProps}>
      {children}
    </div>
  );
}

export function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

export function MultiValue(props) {
  const deletable = !(props.isDisabled || props.data.isFixed);

  return (
    <Chip
      className={props.selectProps.classes.chip}
      tabIndex={-1}
      label={props.children}
      onDelete={deletable ? props.removeProps.onClick : undefined}
      deleteIcon={
        deletable &&
        !props.data.isFixed && (
          <CancelIcon
            className={classnames(
              props.selectProps.classes.cancelIcon,
              props.data.isFixed && props.selectProps.classes.isFixed
            )}
            {...props.removeProps}
          />
        )
      }
    />
  );
}

export function GroupHeading(props) {
  return (
    <div className="field-label" style={{ paddingBottom: 0, paddingLeft: 8 }}>
      {props.children}
    </div>
  );
}

export function Option(props) {
  const { classes } = props.selectProps;

  return (
    <MenuItem
      className={classnames("small", classes.menuItem, {
        [classes.disabledMenuItem]: props.isDisabled,
      })}
      disabled={props.isDisabled}
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      {...{ "data-cy": `${props.value}-dropdown-select-option` }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

export class ValueContainer extends React.Component {
  constructor(props) {
    super(props);
    this.valuesContainerRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      Array.isArray(prevProps.selectProps.value) &&
      prevProps.selectProps.value.length < this.props.selectProps.value.length
    ) {
      const containerEl = this.valuesContainerRef.current;
      containerEl.scrollTop =
        containerEl.scrollHeight - containerEl.clientHeight;
    }
  }

  render() {
    return (
      <div
        ref={this.valuesContainerRef}
        className={this.props.selectProps.classes.valueContainer}
      >
        {this.props.children}
      </div>
    );
  }
}

export function Placeholder(props) {
  return null;
}

function Input(props) {
  return (
    <components.Input
      {...props}
      {...{ "data-cy": `${props.selectProps.id}-dropdown-select-input` }}
    />
  );
}

const customComponents = {
  Control,
  Menu,
  MenuList,
  SingleValue,
  MultiValue,
  Option,
  GroupHeading,
  ValueContainer,
  Placeholder,
  Input,
};

export const evalBool = (input) => {
  if (typeof input === "object") {
    return isEmpty(input);
  }
  return Boolean(input);
};

class DropdownSelect extends React.PureComponent {
  state = { shrink: false };

  handleShrink = () => {
    this.setState({ shrink: !this.state.shrink });
  };

  render() {
    const {
      options,
      value,
      onChange,
      onBlur = () => {},
      label,
      isMulti,
      classes,
      isClearable,
      id,
      disabled,
      style = {},
      error = false,
      helperText = "",
      menuListProps = {},
      helperTextOverrideStyle = {},
      inputProps = {},
      openUpwards = false,
    } = this.props;

    const selectStyles = {
      ...defaultSelectStyles,
      container: (provided) => ({ ...provided, marginBottom: 20, ...style }),
    };

    return (
      <>
        <Select
          isClearable={isClearable}
          components={customComponents}
          styles={selectStyles}
          classes={classes}
          options={options}
          value={value || ""}
          onChange={onChange}
          isMulti={isMulti}
          handleShrink={this.handleShrink}
          closeMenuOnSelect={!isMulti}
          isDisabled={disabled}
          onBlur={onBlur}
          textFieldProps={{
            label,
            InputLabelProps: { shrink: !evalBool(value) || this.state.shrink },
          }}
          inputId={`${id}-dropdown`}
          error={error}
          isOptionDisabled={(option) => Boolean(option.isDisabled)}
          menuListProps={menuListProps}
          id={`${id}`}
          openUpwards={openUpwards}
          // menuIsOpen={true}
        />
        {error && (
          <FormHelperText
            style={({ color: "#FF6D6D", ...helperTextOverrideStyle })}
            data-cy={`${label}-dropdown-select-helper-text`}
          >
            {helperText}
          </FormHelperText>
        )}
      </>
    );
  }
}

DropdownSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  isMutli: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isClearable: PropTypes.bool.isRequired,
  style: PropTypes.object,
};

export default withStyles(style)(DropdownSelect);
