import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import classnames from "classnames";
import moment from "moment";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import { getAllTeammates } from "../../../../selectors/teammateSelectors";

import styles from "./chatStyles";

function ChatMessage({
  message, classes, isEmployer, lastReadAt
}) {
  const currentIndividual = useSelector(getCurrentIndividual);
  const teammates = useSelector(getAllTeammates);

  const fromSelf = useMemo(() => {
    const { senderId } = message.attributes;

    return (
      currentIndividual.id === senderId ||
      Boolean(teammates.find(({ id }) => id === senderId))
    );
  }, [teammates, currentIndividual, message.attributes.senderId]);

  const bubbleClass = fromSelf
    ? classes.selfChatBubble
    : classes.otherParticipantChatBubble;

  const triangleClass = fromSelf
    ? classes.selfTriangle
    : classes.otherParticipantTriangle;

  const captionClass = fromSelf
    ? classes.selfCaption
    : classes.otherParticipantCaption;

  const sentBy = (
    <span>
      Sent by
      {' '}
      {isEmployer
        ? message.attributes.senderName
        : message.attributes.senderFirstName}
    </span>
  );

  return (

    <React.Fragment key={`message-bubble-${message.attributes.id}`}>
      <Tooltip
        placement={fromSelf ? "left-start" : "right-start"}
        title={sentBy}
      >
        <li className={classnames(classes.chatBubble, bubbleClass, "small")}>
          <div className={triangleClass} />
          {message.attributes.fileName && message.attributes.attachment ? (
            <Chip
              avatar={
                <AttachFileIcon className={classes.uploadedAttachedFileIcon} />
            }
              label={message.attributes.fileName}
              className={classes.uploadedFileChip}
              classes={{ deleteIcon: classes.uploadedAttachedFileIcon }}
              onClick={() => window.open(message.attributes.attachment)}
            />
          ) : (
            message.attributes.content
          )}
        </li>
      </Tooltip>
      <div className={classnames(captionClass, "caption")}>
        {lastReadAt && (
          <>
            <span style={{ fontWeight: 700 }}>Read</span>
            <span style={{ fontWeight: 400 }}>
              {` ${moment(lastReadAt).format('MMM D, YYYY')}`}
            </span>
            {' '}
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(ChatMessage);
