import Archive from "@material-ui/icons/Archive";
import map from "lodash/map";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchRequestByJobcast } from "../../../../actions/recruiters/jobcastActions";
import { openModal } from "../../../../actions/shared/uiActions";
import { makeGetFilteredJobcastRequests } from "../../../../selectors/recruiter/jobcastSelectors";
import Button from "../../../forms/custom/Button";
import Content from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Content";
import Subheader from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Subheader";

import RateModal from "../../Jobcasts/IndexPage/Item/JobcastCard/Actions/Modals/RateModal";

import withNotificationMarkedRead from "./withNotificationMarkedRead";

function JobCastClosed({
  notification: {
    attributes: { messageBody, createdAt },
  },
  jobcast,
  jobcast: {
    attributes: {
      individualAvatar,
      individualFirstName,
      individualLastInitial,
      organizationName,
      id,
    },
  },
}) {
  const dispatch = useDispatch();

  const request = useSelector((state) => {
    const getFilteredJobcastRequest = makeGetFilteredJobcastRequests();

    return map(
      getFilteredJobcastRequest(state, {
        jobcastId: id,
      }),
      (jobcastRequest) => jobcastRequest.request
    )[0];
  });

  useEffect(() => {
    !request && dispatch(fetchRequestByJobcast(id));
  }, [request, dispatch, id]);

  const handleClick = () => dispatch(
    openModal(
      <RateModal jobcast={jobcast.attributes} requestId={request.id} />
    )
  );

  return (
    <div>
      <Subheader
        leftChip={{
          chipHeader: "Update from",
          individualFirstName,
          individualLastInitial,
          individualAvatar,
          chipSubText: organizationName,
        }}
      />
      <Content
        iconImage={Archive}
        iconCaption="Closed"
        label="Close Reason"
        value={messageBody}
        helperText={moment(createdAt).format("MMMM Do, YYYY")}
      />
      {!request.attributes.rated &&
        request.attributes.recruiterSubmissionsCount > 0 && (
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 24 }}
            onClick={handleClick}
          >
            <Button color="blue" variant="primary">
              Rate Jobcast
            </Button>
          </div>
      )}
    </div>
  );
}

export default withNotificationMarkedRead(JobCastClosed);
