import React from "react";

import GlobalAvatar from "../../General/GlobalAvatar";
import PopoverLink from "../../General/PopoverLink";
import { formatter } from "../../recruiter/Candidates/actions/modals/utils";
import { RecruiterSubmissionName } from "../Contracts/tableUtils";

export const nonStandardHours = ({ nonStandardHours, doubleTimeHours }) => {
  const totalHours =
    parseFloat(nonStandardHours || 0.0) +
    parseFloat(doubleTimeHours || 0.0);
  const renderLink = (handleClick) => (
    <span
      style={totalHours > 0 ? { color: "#FF6D6D" } : {}}
      onMouseEnter={handleClick}
      onMouseLeave={handleClick}
      className="bold"
    >
      {parseFloat(nonStandardHours || 0.0) + parseFloat(doubleTimeHours || 0.0)}
    </span>
  );

  return (
    <PopoverLink
      renderLink={renderLink}
      renderPopoverContent={() => (
        <table
          className="rounded"
          style={{ margin: 4, textAlign: "center" }}
        >
          <tr>
            <th>Hours</th>
            <th>Rate</th>
          </tr>
          <tr>
            <td>{parseFloat(nonStandardHours || 0.0)}</td>
            <td>1.5x</td>
          </tr>
          <tr>
            <td>{parseFloat(doubleTimeHours || 0.0)}</td>
            <td>2x</td>
          </tr>
        </table>
      )}
    />
  );
};

export const additionalBilling = ({ additionalBilling, additionalBillingReason }) => {
  const renderLink = (handleClick) => (
    <span
      style={parseFloat(additionalBilling) > 0 ? { color: "#FF6D6D" } : {}}
      onMouseEnter={handleClick}
      onMouseLeave={handleClick}
      className="bold"
    >
      {formatter.format(additionalBilling)}
    </span>
  );
  const renderPopoverContent = () => (
    <table
      className="rounded"
      style={{ margin: 8, textAlign: "center" }}
    >
      <tr style={{ whiteSpace: "pre" }}>{additionalBillingReason}</tr>
    </table>
  );

  return (
    <PopoverLink
      renderLink={renderLink}
      renderPopoverContent={renderPopoverContent}
    />
  );
};

const tableColumns = [
  {
    colKey: "AVATAR",
    label: "",
    renderData: ({ avatarIcon, firstName, lastName }) => (
      <GlobalAvatar
        individual={{
          avatarIcon,
          firstName,
          lastName,
        }}
      />
    ),
    style: { width: "6%" },
  },
  {
    colKey: "NAME",
    label: <span className="overline">Worker</span>,
    renderData: ({ recruiterSubmissionId, employer, recruiter }) => (
      <RecruiterSubmissionName {...{ recruiterSubmissionId, employer, recruiter }} />
    ),
    style: { width: "16%" },
    orderFunc: ({ lastName }) => lastName.toLowerCase(),
  },
  {
    colKey: "TITLE",
    label: <span className="overline">Job</span>,
    renderData: ({ jobTitle }) => <span>{jobTitle}</span>,
    style: { width: "20%" },
    orderFunc: ({ jobTitle }) => jobTitle.toLowerCase(),
  },
  {
    colKey: "STANDARD_HOURS",
    label: <span className="overline">Std. Hours</span>,
    renderData: ({ standardHours }) => (
      <span className="bold">{parseFloat(standardHours || 0)}</span>
    ),
    style: { width: "8%" },
    orderFunc: ({ standardHours }) => parseFloat(standardHours || 0),
  },
  {
    colKey: "NON_STANDARD_HOURS",
    label: <span className="overline">Non-Std. Hours</span>,
    renderData: nonStandardHours,
    style: { width: "12%" },
    // eslint-disable-next-line max-len
    orderFunc: ({ nonStandardHours, doubleTimeHours }) => parseFloat(nonStandardHours || 0.0) + parseFloat(doubleTimeHours || 0.0),
  },
  {
    colKey: "ADDITIONAL_BILLING",
    label: <span className="overline">Additions</span>,
    renderData: additionalBilling,
    style: { width: "10%" },
    orderFunc: ({ additionalBilling }) => additionalBilling,
  },
  {
    colKey: "WEEKLY_BILL",
    label: <span className="overline">Bill Amount</span>,
    renderData: ({ totalAmount }) => <span>{totalAmount}</span>,
    style: { width: "10%" },
    orderFunc: ({ totalAmount }) => totalAmount,
  }
];

export default tableColumns;
