import { withStyles } from "@material-ui/core/styles";
import GetAppIcon from '@material-ui/icons/GetApp';
import SkipBackward from "mdi-material-ui/SkipBackward";
import TransitTransfer from "mdi-material-ui/TransitTransfer";
import React from "react";
import { connect } from "react-redux";

import { openModal } from "../../../../../../actions/shared/uiActions";
import { ReactComponent as FileDownload } from '../../../../../../assets/images/file-download.svg';
import { getCurrentIndividual } from "../../../../../../selectors/individualSelectors";
import ActionMenu, {
  DisabledActionMenu,
} from "../../../../../General/ActionMenu";

import EditHireDetailsModal from "../../../../Billing/EditHireDetailsModal/EditHireDetailsModal";

import BackModal from "./modals/BackModal";
import ShareModal from "./modals/ShareModal";
import TransferModal from "./modals/TransferModal";

const SHARE = "share";
const TRANSFER = "transfer_between_jobs";
const DOWNLOAD_CANDIDATE_PROFILE = "download_candidate_profile";
const BACK = "move_backward";
const EDIT_HIRE_DETAILS = "edit_hire_details";

const actions = [
  {
    name: SHARE,
    icon: "share-variant",
  },
  {
    name: TRANSFER,
    icon: <TransitTransfer />,
  },
  {
    name: DOWNLOAD_CANDIDATE_PROFILE,
    icon: <FileDownload />,
  },
];

export function DisabledCandidateActionMenu() {
  return (
    <DisabledActionMenu
      TooltipProps={{
        id: "tooltip-icon",
        title: (
          <span style={{ fontSize: "12px" }}>
            Restore this candidate to take additional actions
          </span>
        ),
        placement: "left",
      }}
    />
  );
}

const CandidateActionMenu = withStyles({ menuPaper: { width: 250 } })(
  ({
    candidate,
    currentIndividual,
    candidate: {
      attributes: { status, jobcastId, placementId },
    },
    openModal,
    classes,
  }) => {
    const handleActionSelection = (actionName) => {
      switch (actionName) {
        case SHARE:
          openModal(
            <ShareModal
              selectedCandidateId={candidate.id}
              jobcastId={jobcastId}
            />
          );
          break;
        case TRANSFER:
          openModal(<TransferModal candidate={candidate} />);
          break;
        case DOWNLOAD_CANDIDATE_PROFILE:
          fetch(candidate.attributes.resume)
            .then(resp => resp.blob())
            .then(blob => {
              const url = window.URL.createObjectURL(blob);
              const resumeLink = document.createElement('a');
              resumeLink.href = url;
              resumeLink.download = `${candidate.attributes.firstName}_${candidate.attributes.lastName}_resume.pdf`;
              resumeLink.click();
            });

          if (candidate.attributes.candidateInfoPdf) {
            fetch(candidate.attributes.candidateInfoPdf)
              .then(resp => resp.blob())
              .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const infoLink = document.createElement('a');
                infoLink.href = url;
                infoLink.download = `${candidate.attributes.firstName}_${candidate.attributes.lastName}_info.pdf`;
                infoLink.click();
              });
          }
          break;
        case BACK:
          openModal(<BackModal candidate={candidate} />);
          break;
        case EDIT_HIRE_DETAILS:
          openModal(<EditHireDetailsModal {...{ placementId }} />);
          break;
        default:
      }
    };

    const determineActions = (currentIndividual) => {
      if (currentIndividual.recruiter) {
        return [
          {
            name: DOWNLOAD_CANDIDATE_PROFILE,
            icon: <FileDownload />,
          },
        ];
      }
      if (status === "interviewing" || status === "offer") {
        return actions.concat({ name: BACK, icon: <SkipBackward /> });
      } if (status === "hired") {
        return [
          {
            name: EDIT_HIRE_DETAILS,
            icon: "edit",
          },
        ];
      } if (status === "rejected") {
        return [
          {
            name: TRANSFER,
            icon: <TransitTransfer />,
          },
        ];
      }
      return actions;
    };

    return (
      <ActionMenu
        actions={determineActions(currentIndividual)}
        dataCy={`candidate-action-menu-${candidate.id}`}
        handleActionSelection={(actionName) => handleActionSelection(actionName)}
        overrideMenuStyles={{ marginLeft: 0, marginTop: 0 }}
        menuClasses={{ paper: classes.menuPaper }}
      />
    );
  }
);

const mapStateToProps = (state) => ({
  currentIndividual: getCurrentIndividual(state),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modalContent) => dispatch(openModal(modalContent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateActionMenu);
