import {
  EXPECTED,
  SCHEDULED,
  PROCESSING,
  PROCESSED,
  FAILED,
} from "../../../../../../selectors/recruiter/financeSelectors";

export {
  EXPECTED, SCHEDULED, PROCESSED, PROCESSING, FAILED
};

export const PAYOUT_COL = "PAYOUT_COL";
export const STATUS_COL = "STATUS_COL";
export const EARNINGS_COL = "EARNINGS_COL";
export const CANDIDATE_COL = "CANDIDATE_COL";
export const EMPLOYER_COL = "EMPLOYER_COL";
export const JOBCAST_COL = "JOBCAST_COL";
export const INVOICE_COL = "INVOICE_COL";
export const MENU_COL = "MENU_COL";

export const STATUS_KEYS = {
  [EXPECTED]: { text: "Expected", color: "gray", attribute: "scheduledDate" },
  [SCHEDULED]: { text: "Scheduled", color: "green", attribute: "scheduledDate" },
  [PROCESSING]: { text: "Processing", color: "gray", attribute: "payoutInitiatedAt" },
  [PROCESSED]: { text: "Paid", color: "green", attribute: "payoutCompletedAt" },
  [FAILED]: { text: "Guarantee Failure", color: "gray-light", attribute: "failedAt" }
};
