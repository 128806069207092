import { combineReducers } from "redux";

import earningsSummary from "./earningsSummaryReducer";
import paymentMethod from "./paymentMethodReducer";
import paymentNotifications from "./paymentNotificationReducer";
import placements from "./placementReducer";
import statements from "./statementReducer";
import transactions from "./transactionReducer";

export default combineReducers({
  statements,
  transactions,
  placements,
  paymentNotifications,
  paymentMethod,
  earningsSummary,
});
