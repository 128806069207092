import { withStyles } from "@material-ui/core/styles";
import EmptyStar from "@material-ui/icons/StarBorder";
import classnames from "classnames";
import merge from "lodash/merge";
import round from "lodash/round";
import PropTypes from "prop-types";
import React, { memo } from "react";

import GlobalAvatar from "../../../../General/GlobalAvatar";
import PopoverLink from "../../../../General/PopoverLink";

import RatingsPopoverContent from "./RatingsPopoverContent";

export const styles = () => ({
  container: {
    marginTop: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  expandedContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 14,
  },
  textContainer: {
    display: "flex",
    color: "#008DAE",
    "&:hover": {
      cursor: "pointer",
      color: "#b0bec5",
    },
    "&$selected": {
      color: "#008DAE",
    },
    "&$highlightedLink": {
      color: "#008DAE",
    },
  },
  textSeparator: {
    margin: "0px 5px",
  },
  ratingIcon: {
    fontSize: 18,
    marginRight: 2,
  },
  selected: {},
  highlightedLink: {},
});

function IndividualDetails({
  jobcast: {
    attributes: {
      id,
      individualAvatar,
      individualFirstName,
      individualLastInitial,
      individualRatings
    },
  },
  classes,
  isHighlightedLink,
  overrideStyle,
  expandedContainer = true,
}) {
  if (!individualRatings) return null;
  return (
    <PopoverLink
      renderLink={(handleClick, isSelected) => (
        <div
          style={overrideStyle.container}
          className={classnames(
            "small",
            expandedContainer ? classes.expandedContainer : classes.container
          )}
        >
          <GlobalAvatar
            onClick={handleClick}
            style={{ cursor: "pointer" }}
            individual={{
              avatarIcon: individualAvatar,
              firstName: individualFirstName,
              lastName: individualLastInitial,
            }}
            overrideStyle={merge(
              {
                height: 18,
                width: 18,
                fontSize: 10,
                marginRight: 8,
              },
              overrideStyle.avatar || {}
            )}
          />
          <span
            role="button"
            className={classnames(classes.textContainer, {
              [classes.selected]: Boolean(isSelected),
              [classes.highlightedLink]: isHighlightedLink,
            })}
            style={
              overrideStyle.button || {}
            }
            onClick={handleClick}
            onKeyDown={handleClick}
            tabIndex={0}
          >
            {individualFirstName}
            {' '}
            {individualLastInitial}
            <span className={classes.textSeparator}>-</span>
            <EmptyStar className={classes.ratingIcon} />
            {individualRatings.count >= 3
              ? round(individualRatings.average, 1)
              : "N/A"}
          </span>
        </div>
      )}
      renderPopoverContent={() => <RatingsPopoverContent jobcastId={id} />}
    />
  );
}

IndividualDetails.defaultProps = {
  isHighlightedLink: false,
  overrideStyle: {},
};

IndividualDetails.propTypes = {
  jobcast: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isHighlightedLink: PropTypes.bool,
  overrideStyle: PropTypes.object,
};

export default memo(withStyles(styles)(IndividualDetails));
