import normalize from "json-api-normalizer";
import omit from "lodash/omit";

import values from "lodash/values";

import { GET, PATCH, POST } from "../../util/apiHelpers";
import { storageAvailable } from "../../util/storageHelpers";

import { receiveCostCenters } from "../employers/costCenterActions";
import { fetchOrganizationJobCastDefaults } from "../employers/organizationActions";
import { receiveOrgJobCategories } from "../employers/orgJobCategoryActions";
import { receiveRequiredTempAgencyAgreements } from "../employers/requiredTempAgencyAgreementActions";
import { receiveTempPrerequisites } from "../employers/tempPrerequisiteActions";
import { receiveInsuranceRequirementAgreements } from "../recruiters/insuranceRequirementAgreementActions";

import { processFeatureFlags } from "./featureFlagActions";
import { receiveInsuranceRequirements } from "./insuranceRequirementActions";
import {
  clearCurrentOrganization,
  receiveCurrentOrganization,
} from "./organizationActions";

// callback action types//
export const RECEIVE_INDIVIDUALS = "RECEIVE_INDIVIDUALS";
export const FAILED_LOGIN = "FAILED_LOGIN";
export const RECEIVE_CURRENT_INDIVIDUAL = "RECEIVE_CURRENT_INDIVIDUAL";
export const UPDATE_CURRENT_INDIVIDUAL = "UPDATE_CURRENT_INDIVIDUAL";
export const SET_LOADING = "SET_LOADING";
export const SUCCESSFUL_LOGOUT = "SUCCESSFUL_LOGOUT";
export const RECEIVE_REDIRECT_URL = "RECEIVE_REDIRECT_URL";
export const RECEIVE_EMAIL_SETTINGS = "RECEIVE_EMAIL_SETTINGS";
export const RECEIVE_VIEWING_AS = "RECEIVE_VIEWING_AS";

// api actions//
export const agreeToTerms = () => (dispatch) => {
  PATCH("/api/v3/authentication/agree-to-stripe", {})
    .then((res) => dispatch(receiveCurrentIndividual(res.data)))
    .catch((error) => console.log(error));
};

export const login = (credentials) => (dispatch) => {
  dispatch(setLoading(true));
  return POST("/api/v3/authentication/login", credentials)
    .then(
      (res) => processIndividual(res.data, dispatch),
      (res) => {
        dispatch(failedLogin());
        return Promise.reject(res);
      }
    )
    .then(() => GET("/api/v3/authentication/feature_flags")
      .then((res) => {
        processFeatureFlags(res.data, dispatch);
      }));
};

export const logout = () => (dispatch) => PATCH("/api/v3/authentication/logout")
  .then((res) => {
    storageAvailable() && localStorage.removeItem("authToken");
    // for gordon...
    storageAvailable() && localStorage.removeItem("authentication");
    dispatch(clearCurrentOrganization());
    dispatch(successfulLogout());
    dispatch(clearViewingAs());
  })
  .catch((error) => Promise.reject(error));

export const createPasswordReset = (data) => POST("/api/v3/password_reset", data);

export const updatePassword = (data) => PATCH("/api/v3/password_reset", data);

export const fetchRedirectURL = () => (dispatch) => GET("/api/v3/public/redirects")
  .then((res) => {
    dispatch(receiveRedirectURL(res.data.redirectURL));
  })
  .catch((error) => Promise.reject(error));

export const updateProfile = (formData) => (dispatch) => PATCH("/api/v3/individual", formData)
  .then((res) => {
    const normalized = normalize(res.data);
    dispatch(receiveIndividuals(normalized.individual));
  })
  .catch((error) => Promise.reject(error.response.data));

export const deactivateCurrentAccount = () => (dispatch) => POST("/api/v3/individual/deactivate").then(() => {
  storageAvailable() && localStorage.removeItem("authToken");
  dispatch(clearCurrentOrganization());
  dispatch(successfulLogout());
  dispatch(clearViewingAs());
});

// callback actions//
export const failedLogin = () => ({ type: FAILED_LOGIN });
export const successfulLogout = () => ({ type: SUCCESSFUL_LOGOUT });

const processFullEmployer = (userData, dispatch) => {
  dispatch(receiveCurrentIndividual(userData.individual));
  dispatch(receiveCurrentOrganization(userData.organization));
  dispatch(receiveCostCenters(userData.costCenter));
  dispatch(receiveRequiredTempAgencyAgreements(userData.requiredTempAgencyAgreement));
  dispatch(receiveTempPrerequisites(userData.tempPrerequisite));
  dispatch(receiveOrgJobCategories(userData.orgJobCategories));
  dispatch(receiveInsuranceRequirements(userData.insuranceRequirements));
  return dispatch(fetchOrganizationJobCastDefaults());
};

const processFullRecruiter = (userData, dispatch) => {
  dispatch(receiveCurrentIndividual(userData.individual));
  dispatch(receiveCurrentOrganization(userData.organization));
  dispatch(receiveInsuranceRequirementAgreements(userData.insuranceRequirementAgreements));
  dispatch(receiveInsuranceRequirements(userData.insuranceRequirements));
  return userData.individual;
};

export const processIndividual = async (data, dispatch) => {
  const normalizedData = normalize(data);
  const insReq = {};
  Object.values(normalizedData.organization)[0].attributes.activeInsuranceRequirements.data.forEach((a) => { insReq[a.id] = a.attributes; });

  const individualData = {
    individual: normalizedData.individual,
    organization: normalizedData.organization,
    costCenter: normalizedData.costCenter,
    requiredTempAgencyAgreement: normalizedData.requiredTempAgencyAgreement,
    tempPrerequisite: normalizedData.tempPrerequisite,
    insuranceRequirements: insReq,
    insuranceRequirementAgreements: normalizedData.insuranceRequirementAgreement,
    orgJobCategories: normalizedData.orgJobCategory,
  };

  const individual = values(individualData.individual)[0].attributes;

  localStorage.setItem("authToken", individual.token);

  if (individual.accountManager) {
    return processFullRecruiter(individualData, dispatch);
  } if (individual.employer) {
    return processFullEmployer(individualData, dispatch);
  } if (individual.recruiter) {
    return processFullRecruiter(individualData, dispatch);
  }
};

export const receiveCurrentIndividual = (individual) => ({
  type: RECEIVE_CURRENT_INDIVIDUAL,
  payload: omit(individual, ["organization", "password"]),
});

export const updateCurrentIndividual = (updateAttributes) => ({
  type: UPDATE_CURRENT_INDIVIDUAL,
  payload: updateAttributes,
});

export const setLoading = (bool) => ({
  type: SET_LOADING,
  payload: bool,
});

export const receiveRedirectURL = (url) => ({
  type: RECEIVE_REDIRECT_URL,
  payload: url,
});

export const receiveIndividuals = (payload) => ({
  type: RECEIVE_INDIVIDUALS,
  payload,
});

export const receiveEmailSettings = (settings) => ({
  type: RECEIVE_EMAIL_SETTINGS,
  payload: settings,
});

export const receiveViewingAs = (payload) => ({
  type: RECEIVE_VIEWING_AS,
  payload,
});

export const clearViewingAs = () => (dispatch) => {
  if (storageAvailable()) {
    localStorage.removeItem("viewingAs");
    localStorage.removeItem("oldViewingAs");
  }

  return dispatch(receiveViewingAs(null));
};
