import normalize from "json-api-normalizer";
import { batch } from "react-redux";

import { GET, POST, PATCH } from "../../util/apiHelpers";
import {
  receiveCandidate,
  receiveCandidates,
} from "../shared/candidateActions";
import {
  fetchContractSuccess,
  fetchContractsSuccess,
  fetchContractsError,
} from "../shared/contractActions";
import { receiveJobcasts } from "../shared/jobcastActions";
import {
  fetchPayPeriodSuccess,
  fetchPayPeriodsSuccess,
} from "../shared/payPeriodActions";

export function fetchContracts(tempPositionId = null) {
  let url = "/api/v3/recruiter/contracts";

  if (tempPositionId) {
    url += `?temp_position_id=${tempPositionId}`;
  }

  return (dispatch) => {
    GET(url)
      .then((response) => {
        const normalized = normalize(response.data);

        batch(() => {
          dispatch(fetchContractsSuccess(normalized.contract));
          dispatch(receiveCandidates(normalized.candidate));
          dispatch(receiveJobcasts(normalized.jobcast));
          dispatch(fetchPayPeriodsSuccess(normalized.payPeriod));
        });
      })
      .catch((error) => {
        dispatch(fetchContractsError(error));
      });
  };
}

export const createContract = (data) => (dispatch) =>
  POST("/api/v3/employer/contracts", data)
    .then((res) => {
      const normalized = normalize(res.data);

      batch(() => {
        dispatch(fetchContractSuccess(normalized.contract));
        dispatch(receiveCandidates(normalized.candidate));
      });
    })
    .catch((errors) => Promise.reject(errors.response.data));

export const fetchContract = (candidateId) => (dispatch) =>
  GET(`/api/v3/recruiter/candidates/${candidateId}/contract`).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(fetchContractSuccess(normalized.contract));
      dispatch(receiveCandidates(normalized.candidate));
      dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
    });
  });

export const completePreassignment = (data) => (dispatch) => {
  return PATCH(
    `/api/v3/recruiter/contracts/${data.contract_id}/complete_preassignment`,
    data
  ).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(fetchContractSuccess(normalized.contract));
      dispatch(receiveCandidates(normalized.candidate));
      dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
    });
  });
};

export const closeContract = (candidateId, data) => (dispatch) =>
  PATCH(`/api/v3/recruiter/candidates/${candidateId}/contract/close`, data)
    .then((res) => {
      const normalized = normalize(res.data);

      batch(() => {
        dispatch(fetchContractSuccess(normalized.contract));
        dispatch(receiveCandidate(normalized.candidate[candidateId]));
        dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
      });
    })
    .catch((errors) => Promise.reject(errors));
