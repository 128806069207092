import classnames from "classnames";
import merge from "lodash/merge";
import sum from "lodash/sum";
import moment from "moment";

import { disabledProps } from "../../../../../util/formHelpers";

import { object, number, string } from "../../../../../util/yup";

export const validationSchema = object().shape({
  standardHours: number()
    .nullable(true)
    .max(168)
    .min(0)
    .label("Standard hours"),
  nonStandardHours: number()
    .nullable(true)
    .max(168)
    .min(0)
    .label("Overtime hours"),
  doubleTimeHours: number()
    .nullable(true)
    .max(168)
    .min(0)
    .label("Doubletime hours"),
  additionalBilling: number()
    .nullable(true)
    .when("additionalBillingReason", {
      is: (val) => val,
      then: number().required().min(0).max(5000)
        .label("Additional Billing")
    }),
  additionalBillingReason: string()
    .nullable(true)
    .when("additionalBilling", {
      is: (val) => val,
      then: string().required().max(1000)
        .label("Additional Billing Explanation")
    }),
  totalHours: number()
    .when("zeroHours", {
      is: true,
      then: number().min(0).label("Total Hours"),
      otherwise: number().min(1).label("Total Hours")
    }),
}, ['additionalBilling', 'additionalBillingReason']);

export function formatDate(date) {
  return moment(date).format("ddd. MMM D, YYYY");
}

export function buildInitialValues(payPeriod) {
  const { attributes } = payPeriod;
  const standardHours = attributes?.standardHours && parseFloat(attributes.standardHours);
  const nonStandardHours = attributes?.nonStandardHours && parseFloat(attributes.nonStandardHours);
  const doubleTimeHours = attributes?.doubleTimeHours && parseFloat(attributes.doubleTimeHours);

  return {
    zeroHours: attributes.submittedAt && (!standardHours && !nonStandardHours && !doubleTimeHours),
    standardHours,
    nonStandardHours,
    doubleTimeHours,
    additionalBilling: attributes?.additionalBilling && parseFloat(attributes.additionalBilling),
    additionalBillingReason: attributes?.additionalBillingReason,
  };
}

export const APPROVED_MESSAGE = "Approved by manager";
export const SUBMITTED_MESSAGE = "Awaiting manager approval";
export const SUBMITTED = "Submitted";
export const NOT_SUBMITTED_MESSAGE = "Not submitted";

export function payPeriodStatusMessage(payPeriod) {
  const {
    approvedAt, submittedAt, standardHours, nonStandardHours, doubleTimeHours
  } = payPeriod.attributes;

  if (approvedAt) {
    if (!standardHours && !nonStandardHours && !doubleTimeHours) {
      return SUBMITTED;
    }

    return APPROVED_MESSAGE;
  }
  if (submittedAt) { return SUBMITTED_MESSAGE; }

  return NOT_SUBMITTED_MESSAGE;
}

const RECRUTIFI_TAKE = 0.03;

export function calculateTotalBill(values, candidate) {
  const rateHours = sum([
    values?.standardHours || 0,
    (values?.nonStandardHours || 0) * 1.5,
    (values?.doubleTimeHours || 0) * 2,
  ]);

  return (rateHours * candidate.attributes.hourlyBillRate) + (values?.additionalBilling ?? 0);
}

export function calculateEarnings(values, candidate) {
  const totalBill = calculateTotalBill(values, candidate);

  return totalBill * (1.0 - RECRUTIFI_TAKE);
}

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const customSubTextFieldProps = merge({}, disabledProps(true), {
  FormHelperTextProps: {
    classes: {
      root: classnames("caption", "grey", "noMargin"),
    },
  },
  style: { marginBottom: 0 },
});
