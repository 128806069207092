import { GET, POST } from "../../util/apiHelpers";

export const RECEIVE_PUBLIC_RECRUITER_SUBMISSION =
  "RECEIVE_RECRUITER_SUBMISSION";
export const UPDATE_PUBLIC_RECRUITER_SUBMISSION =
  "UPDATE_PUBLIC_RECRUITER_SUBMISSION";
export const RECEIVE_RECRUITER_SUBMISSION_ERRORS =
  "RECEIVE_RECRUITER_SUBMISSION_ERRORS";

export const receivePublicRecruiterSubmission = recruiterSubmission => ({
  type: RECEIVE_PUBLIC_RECRUITER_SUBMISSION,
  payload: recruiterSubmission
});

export const updatePublicRecruiterSubmission = attributes => ({
  type: UPDATE_PUBLIC_RECRUITER_SUBMISSION,
  payload: attributes
});

const receiveRecruiterSubmissionErrors = errors => ({
  type: RECEIVE_RECRUITER_SUBMISSION_ERRORS,
  payload: errors
});

export const declineCandidateInvite = token => dispatch => POST(
  `/api/v3/public/recruiter_submissions/decline?token=${token}`
).then(() => dispatch(
  updatePublicRecruiterSubmission({ candidate_response: "declined" })
));

export const declineCandidateInviteFeedback = feedback => dispatch => POST(`/api/v3/public/recruiter_submissions/decline/feedback`, feedback)
  .then(res => Promise.resolve(res.data))
  .catch(res => Promise.reject(res));

export const fetchPublicRecruiterSubmission = token => dispatch => GET(`/api/v3/public/recruiter_submissions/${token}`)
  .then(({ data }) => (
    dispatch(receivePublicRecruiterSubmission(data.data.attributes))
  ))
  .catch(({ response }) => (
    dispatch(receiveRecruiterSubmissionErrors(response.data.errors))
  ));

export const postCandidateApplication = (token, formData) => dispatch => POST(`/api/v3/public/recruiter_submissions/${token}/apply`, formData)
  .then((res) => dispatch(
    updatePublicRecruiterSubmission({ candidate_response: "accepted", event: res.data.data.attributes.event })
  ))
  .catch(({ response }) => dispatch(receiveRecruiterSubmissionErrors(response.data.errors)));
