import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import DeleteForever from "@material-ui/icons/DeleteForever";
import get from "lodash/get";
import React from "react";
import { Row, Col } from "react-grid-system";

import constants from "../../../../util/constants";
import { currencyOptions } from "../../../../util/constantsToOptions";
import DropdownInput from "../../custom/DropdownInput.react";
import { DebouncedNumberFormat } from "../withDebouncedErrors";

import {
  PERCENTAGE_OF_COMPENSATION,
  FLAT_FEE,
  feeTypeOptions,
} from "./fieldHelpers";

function FormikFeeOptionRow({
  idx = 0,
  arrayHelpers,
  fieldArrayName,
  typeLabel = "Fee Type",
  formProps: { errors: formPropErrors, values: formPropValues },
  field: {
    field: { value: fieldValue, onChange, onBlur },
    form: { touched: fieldFormTouched, setFieldValue, values: fieldFormValues },
  },
}) {
  const error = get(formPropErrors, `${fieldArrayName}.${idx}`);
  const touched = get(fieldFormTouched, `${fieldArrayName}.${idx}`);
  const multipleItems = !Boolean(
    get(formPropValues, fieldArrayName).length === 1
  );
  const isPercentageFee = fieldValue.fee_type === PERCENTAGE_OF_COMPENSATION;
  const isFlatFee = fieldValue.fee_type === FLAT_FEE;
  const currencyNotation =
    fieldValue.currency &&
    constants.currency_options[fieldValue.currency].notation;

  const handleFeeTypeChange = (feeTypeChange) => {
    if (isPercentageFee && feeTypeChange === FLAT_FEE) {
      arrayHelpers.replace(idx, {
        fee: "",
        fee_note: "",
        fee_type: FLAT_FEE,
        currency: null,
        id: fieldValue.id,
      });
    } else if (isFlatFee && feeTypeChange === PERCENTAGE_OF_COMPENSATION) {
      arrayHelpers.replace(idx, {
        fee: "",
        fee_note: "",
        fee_type: PERCENTAGE_OF_COMPENSATION,
        currency: null,
        id: fieldValue.id,
      });
    }
  };

  const getFieldErrorMessage = (fieldName) =>
    Boolean(get(touched, fieldName)) && get(error, fieldName);

  return (
    <Row style={{ paddingBottom: 8 }}>
      <Col sm={isPercentageFee ? 4 : 2} xs={isPercentageFee ? 4 : 2}>
        <DropdownInput
          id={`${fieldArrayName}.${idx}.fee_type`}
          label={typeLabel}
          options={feeTypeOptions}
          value={fieldValue.fee_type}
          error={Boolean(getFieldErrorMessage("fee_type"))}
          helperText={getFieldErrorMessage("fee_type")}
          onChange={(e) => {
            setFieldValue(`${fieldArrayName}.${idx}.fee_type`, e.target.value);
            handleFeeTypeChange(e.target.value);
          }}
          onBlur={onBlur}
          noShrink
          inputProps={{
            "data-cy": `fee-type-${idx}-dropdown-input`,
          }}
        />
      </Col>
      {isFlatFee && (
        <Col sm={2} xs={3}>
          <DropdownInput
            id={`${fieldArrayName}.${idx}.currency`}
            label="Currency"
            options={currencyOptions}
            value={fieldValue.currency}
            error={Boolean(getFieldErrorMessage("currency"))}
            helperText={getFieldErrorMessage("currency")}
            onChange={onChange}
            onBlur={onBlur}
            noShrink
            inputProps={{
              "data-cy": `currency-${idx}-dropdown-input`,
            }}
          />
        </Col>
      )}
      <Col sm={2} xs={3}>
        <DebouncedNumberFormat
          thousandSeparator={isFlatFee}
          id={`${fieldArrayName}.${idx}.fee`}
          value={fieldValue.fee}
          onChange={onChange}
          onBlur={onBlur}
          label="Agency Fee"
          error={get(error, "fee")}
          touched={get(touched, "fee")}
          delayTime={1000}
          customInput={TextField}
          InputProps={{
            startAdornment: isFlatFee && (
              <InputAdornment position="start">
                {currencyNotation}
              </InputAdornment>
            ),
            endAdornment: isPercentageFee && (
              <InputAdornment position="end">%</InputAdornment>
            ),
            inputProps: {
              "data-cy": `fee-number-format-input-${idx}`,
            },
          }}
        />
      </Col>
      <Col sm={3} xs={3}>
        {multipleItems && (
          <TextField
            id={`${fieldArrayName}.${idx}.fee_note`}
            multiline
            error={Boolean(getFieldErrorMessage("fee_note"))}
            helperText={getFieldErrorMessage("fee_note")}
            label={`Fee ${idx + 1} Note`}
            onChange={onChange}
            value={fieldValue.fee_note}
            onBlur={onBlur}
            inputProps={{ "data-cy": `fee-note-${idx}` }}
          />
        )}
      </Col>
      <Col
        xs={1}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          paddingBottom: 16,
          paddingLeft: 0,
        }}
      >
        {multipleItems && (
          <DeleteForever
            data-cy="delete-fee-option-button"
            onClick={() => {
              arrayHelpers.remove(idx);
              if (get(fieldFormValues, fieldArrayName).length === 2) {
                setFieldValue("multiple_fees", false);
              }
            }}
            style={{
              color: "#FF6D6D",
              cursor: "pointer",
              fontSize: "28px",
              height: "28px",
              width: "28px",
            }}
          />
        )}
      </Col>
    </Row>
  );
}

export default FormikFeeOptionRow;
