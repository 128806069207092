import { TextField, Grid } from "@material-ui/core";
import { Formik } from "formik";
import moment from "moment";
import React, { useMemo } from "react";

import { useSelector, useDispatch } from "react-redux";

import { object, string, array } from 'yup';

import { editContract } from "../../../actions/employers/contractActions";
import { fetchJobCast } from "../../../actions/employers/jobcastActions";
import { clearModal } from "../../../actions/shared/uiActions";
import { makeGetFilteredContracts } from "../../../selectors/contractSelector";
import { disabledProps } from "../../../util/formHelpers";
import FieldLabel from "../../employer/JobCasts/view/Description/sections/FieldLabel";
import TimesheetApproversField from
  "../../employer/Shared/Modals/sections/BillingInformation/TimesheetApproversField";
import FormContextDatePicker from
  "../../forms/formik/FormContextInputs/FormContextDatePicker";
import ActionModalContainer from "../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../General/ActionModal/ActionModalHeader";
import useSnackbar from "../../General/useSnackbar";

const validationSchema = object().shape({
  scheduledEndDate: string().nullable().required("Cannot be blank"),
  timesheetApproverIds: array().of(string()).min(1, "Cannot be blank"),
});

function EditContractModal({ candidate }) {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const getContracts = useMemo(makeGetFilteredContracts, []);
  const contract = useSelector(
    (state) => getContracts(state, { candidateId: candidate.id })
  )[0];

  const candidateName = `${candidate.attributes.firstName} ${candidate.attributes.lastName}`;

  const timesheetApproverIds = contract.attributes.primaryApprovers.map(
    (approver) => approver.id
  );

  const onSubmit = (values) => {
    dispatch(editContract(contract.attributes.recruiterSubmissionId, contract.id, values))
      .then(() => {
        dispatch(clearModal());
        dispatch(fetchJobCast(candidate.attributes.jobcastId));
        snackbar.showMessage("Contract Updated");
      });
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        scheduledEndDate:
          moment(contract.attributes.scheduledEndDate).utc().format("YYYY-MM-DD"),
        timesheetApproverIds,
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, ...props }) => (
        <ActionModalContainer color="blue">
          <ActionModalHeader
            title="Edit Contract"
            subtitle={candidateName}
          />
          <ActionModalContent>
            <p style={{ margin: "0 0 24px 0" }}>
              Use this form to edit fields on an open contract.
              Only identifying information and fields that can be edited will appear here.
            </p>
            <div style={{ width: "100%", overflow: 'hidden' }}>
              <Grid item xs={3}>
                <TextField
                  {...disabledProps(true)}
                  label={<FieldLabel name="Worker Name" />}
                  value={candidateName}
                />
              </Grid>
              <Grid container spacing={10}>
                <Grid item xs={4}>
                  <FormContextDatePicker
                    id="scheduledEndDate"
                    label="Tentative End Date"
                    inputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimesheetApproversField />
                </Grid>
              </Grid>
            </div>
          </ActionModalContent>
          <ActionModalFooter
            actionText="Save"
            handleAction={() => {
              handleSubmit();
            }}
            isSubmitting={props.isSubmitting}
          />
        </ActionModalContainer>
      )}
    </Formik>
  );
}

export default EditContractModal;
