import { TableCell, TableRow } from "@material-ui/core";
import React from "react";

import { useSelector } from "react-redux";

import { getCurrentIndividual } from "../../../../../selectors/individualSelectors";

function CellContent({ checklistItem }) {
  const currentIndividual = useSelector(getCurrentIndividual);

  const contract = useSelector(
    (state) => state.contracts.items[checklistItem.contractId]
  );

  const {
    statementOfServicesDocumentUrl,
    statementOfServicesSigners,
    sowEnvelopeId,
    candidateName
  } = contract.attributes;

  if (!sowEnvelopeId) {
    return (
      <div>
        There was an issue generating your Statement of Services document.
        Please reach out to our support team for assistance.
      </div>
    );
  }

  const alreadySigned =
    (currentIndividual.employer && checklistItem.employerConfirmedAt) ||
    (currentIndividual.recruiter && checklistItem.agencyConfirmedAt);

  if (alreadySigned && statementOfServicesDocumentUrl) {
    return (
      <a href={statementOfServicesDocumentUrl} target="_blank" rel="noreferrer">
        {candidateName.replace(" ", "_")}_Statement_of_Services.pdf
      </a>
    );
  }

  const signerRole = currentIndividual.employer ? "employer" : "agency";

  const designatedSigner = statementOfServicesSigners.find(
    (signer) => signer.role === signerRole
  );

  const labelText =
    designatedSigner.email === currentIndividual.email
      ? "Emailed for signature to:"
      : "Reassigned for signature to:";

  return (
    <div>
      <div>
        {labelText}
      </div>
      <div>
        {designatedSigner.email}
      </div>
    </div>
  );
}

function SOWConfirmationCellContent({ checklistItem }) {
  const renderStatus = (label, isConfirmed, containerStyle = {}) => {
    return (
      <div
        style={({
          ...containerStyle
        })}
      >
        <span className="bold">
          {label}
          :
        </span>
        <br />
        {isConfirmed ? (
          <span className="subtitle-2" style={{ color: "#008DAE" }}>
            Signed
          </span>
        ) : (
          <span style={{ color: "#E85454" }}>
            Not Completed
          </span>
        )}
      </div>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {renderStatus("Company", checklistItem.employerConfirmedAt, {
        marginBottom: 8,
      })}
      {renderStatus("Staffing Firm", checklistItem.agencyConfirmedAt)}
    </div>
  );
}

function SOWFieldRow({ checklistItem }) {
  return (
    <TableRow data-cy="checklist-item-sow-row">
      <TableCell>
        <div className="bold">
          {checklistItem.name}
        </div>
      </TableCell>
      <TableCell align="center">
        <CellContent {...{ checklistItem }} />
      </TableCell>
      <TableCell>
        <SOWConfirmationCellContent {...{ checklistItem }} />
      </TableCell>
    </TableRow>
  );
}

export default SOWFieldRow;
