import { Formik } from "formik";
import { clone, isEqual } from "lodash";
import find from "lodash/find";
import map from "lodash/map";
import omit from "lodash/omit";
import orderBy from "lodash/orderBy";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchPayPeriods,
  updatePayPeriod,
} from "../../../../../actions/recruiters/payPeriodActions";
import { clearModal } from "../../../../../actions/shared/uiActions";
import { makeGetFilteredContracts } from "../../../../../selectors/contractSelector";
import { makeGetFilteredPayPeriods } from "../../../../../selectors/payPeriodSelectors";
import { makeGetCandidate } from "../../../../../selectors/recruiter/candidateSelectors";
import { mapKeysToSnakeCase } from "../../../../../util/formatHelpers";
import SectionHeader from "../../../../employer/JobCasts/view/Candidates/actions/modals/Hire/SectionHeader";
import DropdownSelect from "../../../../forms/custom/DropdownSelect";

import ActionModalContainer from "../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../General/ActionModal/ActionModalHeader";
import useSnackbar from "../../../../General/useSnackbar";

import PayPeriodForm from "./SubmitHoursModal/PayPeriodForm";
import { buildInitialValues, formatDate, validationSchema } from "./utils";

export default function SubmitHoursModal({ candidateId, setSubmitted }) {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const [selectedPayPeriod, setSelectedPayPeriod] = useState();
  const [payPeriodOptions, setPayPeriodOptions] = useState();

  const getCandidate = useMemo(makeGetCandidate, []);
  const candidate = useSelector(
    (state) => getCandidate(state, candidateId),
    (previousValue, currentValue) => previousValue.id === currentValue.id
  );

  const getContracts = useMemo(makeGetFilteredContracts, []);
  const contract = useSelector(
    (state) => getContracts(state, { candidateId })[0],
    isEqual
  );

  const getPayPeriods = useMemo(makeGetFilteredPayPeriods, []);
  const payPeriods = useSelector(
    (state) => orderBy(
      getPayPeriods(state, { contractId: contract?.id }),
      (payPeriod) => payPeriod.attributes.startDate
    )
      .slice(-4)
      .reverse(),
    isEqual
  );

  const payPeriod = useSelector(
    (state) => find(getPayPeriods(state), { id: selectedPayPeriod?.value }),
    isEqual
  );

  const initialValues = useMemo(() => {
    if (payPeriod) {
      return buildInitialValues(payPeriod);
    }
    return null;
  }, [payPeriod]);

  useEffect(() => {
    dispatch(fetchPayPeriods({ candidateId }));
  }, [dispatch, candidateId]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const options = map(payPeriods, (payPeriod) => ({
      value: payPeriod.id,
      label: `${formatDate(payPeriod.attributes.startDate)} - ${formatDate(
        payPeriod.attributes.endDate
      )}`,
    }));
    setPayPeriodOptions(options);

    setSelectedPayPeriod(options[0]);
  }, [contract?.id]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const transformValues = (values) => {
    const newValues = clone(values);

    if (values.standardHours == 0) newValues.standardHours = null;
    if (values.nonStandardHours == 0) newValues.nonStandardHours = null;
    if (values.doubleTimeHours == 0) newValues.doubleTimeHours = null;
    if (values.additionalBilling == 0) newValues.additionalBilling = null;

    return newValues;
  };

  const submit = (values) => dispatch(
    updatePayPeriod(
      selectedPayPeriod.value,
      mapKeysToSnakeCase(omit(transformValues(values), ["totalHours", "zeroHours"]))
    )
  )
    .then(() => {
      snackbar.showMessage(`Hours submitted for ${selectedPayPeriod.label}.`);
      dispatch(clearModal());
      setSubmitted(true);
    })
    .catch(() => snackbar.showMessage("An error occurred, try again or contact support."));

  return payPeriod && initialValues ? (
    <Formik
      id={selectedPayPeriod?.value}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
      enableReinitialize
    >
      {({
        submitCount, handleSubmit, isSubmitting, errors
      }) => (
        <ActionModalContainer color="blue">
          <ActionModalHeader
            title="Add Hours Worked"
            subtitle={`${candidate?.attributes?.firstName} ${candidate?.attributes?.lastName}`}
          />
          <ActionModalContent>
            <p>
              Use this form to submit hours worked. These hours will be
              presented to the timecard approver. Once approved, an invoice will
              be sent to the client.
            </p>
            <SectionHeader title="Hours Worked Submission" />
            <DropdownSelect
              id="pay_periods"
              value={selectedPayPeriod}
              options={payPeriodOptions}
              onChange={(option) => setSelectedPayPeriod(option)}
              label="Select Work Week"
            />
            <PayPeriodForm payPeriodId={selectedPayPeriod?.value} />
          </ActionModalContent>
          <ActionModalFooter
            actionText="Submit"
            submissionError={submitCount > 0 && errors.totalHours}
            customErrorMessage={errors.totalHours}
            handleAction={handleSubmit}
            isSubmitting={isSubmitting}
            disabled={payPeriod.attributes.submittedAt !== null}
            overrideErrorStyles={{ width: 400 }}
          />
        </ActionModalContainer>
      )}
    </Formik>
  ) : null;
}
