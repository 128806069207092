import React from "react";

import AgencyEarns from "./FormComponents/AgencyEarns";
import FeeType from "./FormComponents/FeeType";
import JobcastAssignment from "./FormComponents/JobcastAssignment";

function AddAsLegacyModalContent() {
  return (
    <>
      <div style={{ paddingBottom: 8 }}>
        As a preferred agency you will be able to control which JobCasts this
        agency receives. After they are invited their team will show up in
        Agency Selection when creating a JobCast.
      </div>
      <FeeType />
      <AgencyEarns />
      <JobcastAssignment />
    </>
  );
}

export default AddAsLegacyModalContent;
