import concat from "lodash/concat";
import map from "lodash/map";
import round from "lodash/round";

import { string, object, array } from "../../../../../util/yup";
import {
  PERCENTAGE_OF_COMPENSATION,
  FLAT_FEE,
} from "../../../../forms/formik/FormikFeeOptions/fieldHelpers";

export const buildInitialValues = (agencyRecruiter) => {
  const { email, firstName, lastName } = agencyRecruiter;

  return {
    type: "Legacy",
    feeType: "optimized",
    fee_options: [
      {
        fee: "",
        fee_note: "",
        fee_type: PERCENTAGE_OF_COMPENSATION,
        currency: null,
      },
    ],
    jobcast_assignments: [],
    multiple_fees: false,
    email,
    first_name: firstName,
    last_name: lastName,
  };
};

export const validationSchema = object().when("feeType", {
  is: (feeType) => feeType === "custom",
  then: object().shape({
    fee_options: array().when("multiple_fees", {
      is: true,
      then: array().of(
        object().shape({
          fee: string().when("fee_type", {
            is: PERCENTAGE_OF_COMPENSATION,
            then: string()
              .validFeePercentage()
              .greaterThan(0)
              .lessThan(100)
              .required("Cannot be blank"),
            otherwise: string().required("Cannot be blank"),
          }),
          fee_note: string().required("Cannot be blank"),
          currency: string().when("fee_type", {
            is: FLAT_FEE,
            then: string().required("Cannot be blank").nullable(),
            otherwise: string().nullable(),
          }),
        })
      ),
      otherwise: array().of(
        object().shape({
          fee: string().when("fee_type", {
            is: PERCENTAGE_OF_COMPENSATION,
            then: string()
              .validFeePercentage()
              .greaterThan(0)
              .lessThan(100)
              .required("Cannot be blank"),
            otherwise: string().required("Cannot be blank"),
          }),
          currency: string().when("fee_type", {
            is: FLAT_FEE,
            then: string().required("Cannot be blank").nullable(),
            otherwise: string().nullable(),
          }),
        })
      ),
    }),
  }),
});

export const handleSubmit =
  (
    createLegacyInvitations,
    clearModal,
    snackbar,
    agencyOrgName,
    setExcludedOrgIds,
    agencyOrgId
  ) => (values, { setSubmitting, resetForm }) => {
    const formData = () => {
      const {
        email,
        first_name,
        last_name,
        fee_options,
        jobcast_assignments,
        multiple_fees,
        type,
        feeType,
      } = values;

      const new_fee_options = map(fee_options, (fee_option) => ({
        fee_percentage:
            fee_option.fee_type === PERCENTAGE_OF_COMPENSATION
              ? round(parseFloat(fee_option.fee) / 100, 4)
              : null,
        fee_cents:
            fee_option.fee_type === FLAT_FEE
              ? parseFloat(fee_option.fee.replace(/,/g, "")) * 100
              : null,
        note: fee_option.fee_note,
        id: fee_option.id,
        fee_type: fee_option.fee_type,
        currency: fee_option.currency,
      }));

      const data = {
        type,
        email,
        first_name,
        last_name,
        fee_options: new_fee_options,
        jobcast_assignments,
        multiple_fees,
        feeType,
      };

      return data;
    };

    createLegacyInvitations(formData())
      .then(() => {
        setExcludedOrgIds((prevExcludedOrgIds) => concat(prevExcludedOrgIds, agencyOrgId));
      })
      .then(() => {
        setSubmitting(false);
        clearModal();
      })
      .then(() => {
        snackbar.showMessage(
          `${agencyOrgName} has been invited as a preferred agency`
        );
        resetForm();
      })
      .catch(() => {
        setSubmitting(false);
        snackbar.showMessage("There was an issue processing your request");
      });
  };
