import { TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import get from "lodash/get";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";

import { disabledProps } from "../../../../util/formHelpers";

function FormContextNumberInput(props) {
  const {
    values, touched, errors, handleBlur, setFieldValue
  } =
    useFormikContext();

  const { id } = props;

  const errorMessage = (get(touched, id) && get(errors, id)) || "";

  const shallowProps = omit(props, ["InputProps", "FormHelperTextProps"]);

  const value = get(values, id);

  const renderedValue = props.renderValue ? props.renderValue(value) : value;

  return (
    <NumberFormat
      {...disabledProps(props.disabled)}
      onValueChange={(values) => {
        setFieldValue(id, values.floatValue ?? null);
      }}
      value={renderedValue}
      onBlur={handleBlur}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      FormHelperTextProps={{
        style: { marginBottom: "-18px" },
        ...props.FormHelperTextProps,
      }}
      customInput={TextField}
      InputProps={{
        ...props.InputProps,
        inputProps: { "data-cy": `${id}-number-format-input`, ...props.InputProps.inputProps },
      }}
      {...shallowProps}
    />
  );
}

FormContextNumberInput.defaultProps = {
  InputProps: {},
  FormHelperTextProps: {},
};

FormContextNumberInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  InputProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
};

export default FormContextNumberInput;
