import normalize from "json-api-normalizer";

import { getViewingAsOtherId } from "../../selectors/individualSelectors";
import { PATCH } from "../../util/apiHelpers";

export const RECEIVE_NOTIFICATIONS = "RECEIVE_NOTIFICATIONS";
export const SET_NOTIFICATIONS_LOADED = "SET_NOTIFICATIONS_LOADED";

export const receiveNotifications = notifications => ({
  type: RECEIVE_NOTIFICATIONS,
  payload: notifications
});

export const setNotificationsLoaded = newLoadStatus => (dispatch, getState) => {
  if (getState().notifications.loaded !== newLoadStatus) {
    return dispatch({
      type: SET_NOTIFICATIONS_LOADED,
      payload: newLoadStatus
    });
  }
};

export const markNotificationsRead = ids => (dispatch, getState) => {
  // can't mark someone else's notifications as read
  if (getViewingAsOtherId(getState())) {
    return Promise.resolve(true);
  }
  return PATCH(`/api/v3/notifications/mark_all_read`, { ids })
    .then(res => {
      const normalized = normalize(res.data);
      return dispatch(receiveNotifications(normalized.notification));
    })
    .catch(errors => Promise.reject(errors));
};
