import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Domain from "@material-ui/icons/Domain";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import Star from "@material-ui/icons/Star";
import includes from "lodash/includes";
import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

import { fetchRecruiterLists } from "../../../../../actions/employers/recruiterListActions";
import {
  openModal,
  openProfileDrawer,
} from "../../../../../actions/shared/uiActions";
import { getAllRecruiterLists } from "../../../../../selectors/employer/recruiterListSelectors";
import { getCurrentIndividual } from "../../../../../selectors/individualSelectors";
import { getCurrentOrganization } from "../../../../../selectors/organizationSelectors";
import { alpha2ToCountry } from "../../../../../util/constantsToOptions";
import ActionMenu from "../../../../General/ActionMenu";

import GlobalAvatar from "../../../../General/GlobalAvatar";

import AddAsLegacyModal from "../../modals/AddAsLegacyModal/AddAsLegacyModal";
import AddToListModal from "../../modals/AddToListModal";
import ChangeFeeContractModal from "../../modals/ChangeFeeContractModal/ChangeFeeContractModal";
import ChangeFeeModal from "../../modals/ChangeFeeModal";
import DeleteAgencyModal from "../../modals/DeleteAgencyModal";
import RemoveFromCommunityModal from "../../modals/RemoveFromCommunityModal/RemoveFromCommunityModal";

import {
  buildLegacyAgencyActions,
  buildCommunityAgencyActions,
  LEGACY,
} from "./constants";

const styles = {
  root: {
    borderBottom: 0,
    paddingTop: 20,
  },
  action: {
    paddingTop: 4,
    marginRight: 0,
    marginLeft: "auto",
  },
  avatar: {
    marginLeft: "-4px",
  },
  content: {
    maxWidth: 470,
  },
  icon: {
    color: "#90A4AE",
    marginRight: 4,
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
  },
  menuPaper: {
    width: 230,
  },
};

class AgencyDrawerHeader extends Component {
  componentDidMount() {
    this.props.fetchRecruiterLists();
  }

  determineActionMenuOptions() {
    const {
      isAgencyManager,
      agency,
      excludedCommunityAgencies,
      recruiterLists,
    } = this.props;
    const { relationshipType } = agency.individual.attributes;

    if (relationshipType === LEGACY) {
      return buildLegacyAgencyActions(
        isAgencyManager,
        Object.keys(recruiterLists).length
      );
    }
    return buildCommunityAgencyActions(includes(excludedCommunityAgencies));
  }

  handleAction(actionName) {
    const { agency, setExcludedOrgIds, currentOrganization } = this.props;

    switch (actionName) {
      case "delete_agency":
        this.props.openDeleteModal(agency);
        break;
      case "edit_fee":
        if (currentOrganization.tempAccess && currentOrganization.agreedTempTerms) {
          this.props.openFeeContractModal(agency);
        } else {
          this.props.openFeeModal(agency);
        }
        break;
      case "add_to_jobs":
        this.props.handleTabChange(null, 0);
        break;
      case "add_to_preferred_agencies":
        this.props.openAddAsLegacyModal(agency, setExcludedOrgIds);
        break;
      case "remove_from_community":
        this.props.openRemoveFromCommunityModal(agency, setExcludedOrgIds);
        break;
      case "edit_list":
        this.props.openAddToListModal(agency);
        break;
      default:
        break;
    }
  }

  renderSubHeader() {
    const { agency, classes } = this.props;
    const { name } = agency.organization.attributes;
    const { email, phone, phonePrefix } = agency.individual.attributes;

    return (
      <div>
        <div
          className="subtitle-1"
          style={{ marginBottom: 4, display: "flex" }}
        >
          <Domain className={classes.icon} />
          {name}
        </div>
        <div className="subtitle-1" style={{ display: "flex" }}>
          <div className={classes.infoContainer} style={{ marginRight: 8 }}>
            <Email className={classes.icon} />
            {email}
          </div>
          &bull;
          <div className={classes.infoContainer} style={{ marginLeft: 8 }}>
            <Phone className={classes.icon} />
            <NumberFormat
              value={phone}
              displayType="text"
              format={`+${alpha2ToCountry(phonePrefix)?.number ?? ""} (###) ###-####`}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { agency, classes, displayActionMenu = true } = this.props;
    const {
      name, firstName, lastName, avatarUrl, rating, relationshipType
    } =
      agency.individual.attributes;

    return (
      <CardHeader
        data-cy="agency-drawer-header"
        classes={{
          title: classes.title,
          avatar: classes.avatar,
          action: classes.action,
          root: classes.root,
          content: classes.content,
        }}
        title={
          name.length > 26 ? (
            <Tooltip title={`${name}`}>
              <h3 className="truncate">{`${name}`}</h3>
            </Tooltip>
          ) : (
            <h3 className="truncate">{`${name}`}</h3>
          )
        }
        subheader={this.renderSubHeader()}
        avatar={(
          <GlobalAvatar
            size="large"
            individual={{
              avatarIcon: avatarUrl,
              firstName,
              lastName,
            }}
          />
        )}
        action={(
          <>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h5>{rating || "N/A"}</h5>
                <Star
                  style={{
                    color: "#f8c11c",
                    fontSize: 20,
                    marginLeft: 4,
                    paddingBottom: 3,
                  }}
                />
              </div>
              {displayActionMenu ? (
                <ActionMenu
                  menuClasses={
                    relationshipType !== LEGACY && {
                      paper: classes.menuPaper,
                    }
                  }
                  actions={this.determineActionMenuOptions()}
                  dataCy="agency-drawer-action-menu"
                  handleActionSelection={(actionName) => this.handleAction(actionName)}
                  overrideMenuStyles={{
                    marginLeft: "0px",
                  }}
                />
              ) : (
                <div style={{ paddingBottom: 24, marginBottom: 24 }} />
              )}
            </div>
            <div
              className="caption"
              style={{ color: "#B0BEC5", marginTop: "-10px" }}
            >
              Overall Rating
            </div>
          </>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isAgencyManager: getCurrentIndividual(state).agencyManager,
  recruiterLists: getAllRecruiterLists(state),
  currentOrganization: getCurrentOrganization(state),
  excludedCommunityAgencies:
    getCurrentOrganization(state).excludedCommunityAgencies,
});

const mapDispatchToProps = (dispatch, _ownProps) => ({
  openDeleteModal: (agency) => dispatch(
    openModal(<DeleteAgencyModal organizationId={agency.organization.id} />)
  ),
  openFeeModal: (agency) => dispatch(
    openModal(<ChangeFeeModal organizationId={agency.organization.id} />)
  ),
  openFeeContractModal: (agency) => dispatch(
    openModal(<ChangeFeeContractModal organizationId={agency.organization.id} />)
  ),
  openAddAsLegacyModal: (agency, setExcludedOrgIds) => dispatch(
    openModal(
      <AddAsLegacyModal
        recruiterId={agency.individual.id}
        setExcludedOrgIds={setExcludedOrgIds}
      />
    )
  ),
  openRemoveFromCommunityModal: (agency, setExcludedOrgIds) => dispatch(
    openModal(
      <RemoveFromCommunityModal
        organizationId={agency.organization.id}
        setExcludedOrgIds={setExcludedOrgIds}
      />
    )
  ),
  openProfileDrawer: (content) => dispatch(openProfileDrawer(content)),
  openAddToListModal: (agency) => dispatch(openModal(<AddToListModal agency={agency} />)),
  fetchRecruiterLists: () => dispatch(fetchRecruiterLists()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AgencyDrawerHeader));
