import {
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { makeGetFilteredPayPeriods } from "../../../../../../selectors/payPeriodSelectors";
import { formatter } from "../../../../../recruiter/Candidates/actions/modals/utils";
import { additionalBilling, nonStandardHours } from "../../../../PayPeriods/tableColumns";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
  },
  header: {
    backgroundColor: "#e3e9e9",
    "& th": {
      fontWeight: "bold",
      color: "#37474F",
    },
  },
  body: {
    "& tr:nth-child(even)": {
      backgroundColor: "#f4f4f4",
    },
  },
  standardCell: {
    color: "#37474F",
  },
  alertCell: {
    color: "#F44336",
  },
  boldedCell: {
    fontWeight: "bold",
    color: "#37474F",
  },
  paginationSpacer: {
    flex: "inherit",
  },
}));

const payoutStatus = (
  isEmployer,
  { approvedAt, collectionAt, payoutStatus }
) => {
  if (isEmployer) {
    // Employer and collected at
    if (collectionAt !== null) return "Paid";
  } else if (payoutStatus === "processed") return "Paid";

  return approvedAt ? "Approved" : "Submitted";
};

const ROWS_PER_PAGE = 5;

function PayPeriodsTable({ contract, isEmployer }) {
  const [tablePage, setTablePage] = useState(0);
  const getPayPeriods = useMemo(makeGetFilteredPayPeriods, []);

  const payPeriods = useSelector((state) => getPayPeriods(
    state,
    { contractId: contract.id, submitted: true }
  ));

  const orderedPayPeriods = useMemo(
    () => orderBy(payPeriods, [({ attributes }) => attributes.startDate], ["desc"]),
    [payPeriods]
  );

  const windowedPayPeriods = useMemo(() => {
    const startIdx = tablePage * ROWS_PER_PAGE;
    const endIdx = startIdx + ROWS_PER_PAGE;

    return orderedPayPeriods.slice(startIdx, endIdx);
  }, [tablePage, orderedPayPeriods]);

  const classes = useStyles();

  return (
    <div data-cy="pay-periods-table">
      <TableContainer component={Paper} classes={{ root: classes.container }}>
        <Table>
          <TableHead classes={{ root: classes.header }}>
            <TableCell>Week Start</TableCell>
            <TableCell>Week End</TableCell>
            <TableCell align="center">Std. Hrs.</TableCell>
            <TableCell align="center">Non-Std. Hrs.</TableCell>
            <TableCell align="center">Add.</TableCell>
            <TableCell align="center">
              {isEmployer ? "Billed" : "Earned"}
            </TableCell>
            <TableCell>Status</TableCell>
          </TableHead>
          <TableBody classes={{ root: classes.body }}>
            {map(windowedPayPeriods, ({ attributes }) => (
              <TableRow key={attributes.id}>
                <TableCell classes={{ root: classes.standardCell }}>
                  {moment(attributes.startDate).format("MMM Do, YYYY")}
                </TableCell>
                <TableCell classes={{ root: classes.standardCell }}>
                  {moment(attributes.endDate).format("MMM Do, YYYY")}
                </TableCell>
                <TableCell
                  align="center"
                  classes={{ root: classes.standardCell }}
                >
                  {parseFloat(attributes.standardHours || 0.0)}
                </TableCell>
                <TableCell
                  align="center"
                  classes={{ root: classes.standardCell }}
                >
                  {nonStandardHours(attributes)}
                </TableCell>
                <TableCell align="center">
                  {additionalBilling(attributes)}
                </TableCell>
                <TableCell align="center">
                  {formatter.format(
                    isEmployer
                      ? attributes.employerCollectionAmount
                      : attributes.recruiterPayoutAmount
                  )}
                </TableCell>
                <TableCell classes={{ root: classes.standardCell }}>
                  {payoutStatus(isEmployer, attributes)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          classes={{
            spacer: classes.paginationSpacer,
            root: classes.boldedCell,
          }}
          component="div"
          count={payPeriods.length}
          rowsPerPage={ROWS_PER_PAGE}
          rowsPerPageOptions={[ROWS_PER_PAGE]}
          page={tablePage}
          onChangePage={(_event, newPage) => setTablePage(newPage)}
        />
      </TableContainer>
    </div>
  );
}

export default PayPeriodsTable;
