import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import moment from "moment";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";

import Button from "../forms/custom/Button";

const styles = theme => ({
  banner: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: 90,
    background: "#FFFFFF",
    borderTop: "1px solid #37474F",
    zIndex: 9999,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 20,
    [theme.breakpoints.down("600")]: {
      padding: "0 10px",
      height: "auto"
    }
  },
  text: {
    width: 740,
    [theme.breakpoints.down("600")]: {
      height: "auto"
    }
  },
  button: {
    marginLeft: 16,
    marginRight: 82,
    [theme.breakpoints.down("600")]: {
      marginRight: 32
    }
  }
});

function GDPRBanner({ classes }) {
  const [cookies, setCookie] = useCookies(["recruitifi_gdpr_hash"]);
  const { hostname } = document.location;

  useEffect(() => {
    if (!cookies.recruitifi_gdpr_hash) {
    } else if (
      moment(cookies.recruitifi_gdpr_hash).diff(moment(), "years") < 0
    ) {
      setCookie("recruitifi_gdpr_hash", "", {
        path: "/",
        domain: hostname.match(/recruitifi.com/) ? "recruitifi.com" : hostname
      });
    } else {
      addAnalyticScriptsToDOM();
    }
  }, [cookies.recruitifi_gdpr_hash, setCookie, hostname]);

  const addAnalyticScriptsToDOM = () => {
    const hsScript = document.createElement("script");
    const gaScript = document.createElement("script");

    hsScript.text = `(function(d,s,i,r) {
        if (d.getElementById(i)){return;}
        var n=d.createElement(s),e=d.getElementsByTagName(s)[0];
        n.id=i;n.src='//js.hs-analytics.net/analytics/'+(Math.ceil(new Date()/r)*r)+'/368586.js';
        e.parentNode.insertBefore(n, e);
        })(document,"script","hs-analytics",300000);`;
    hsScript.async = true;

    gaScript.text = `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
    
        ga('create', 'UA-45565397-1', 'www.recruitifi.com');
        ga('require', 'displayfeatures');
        ga('send', 'pageview')`;
    gaScript.async = true;

    document.body.appendChild(hsScript);
    document.body.appendChild(gaScript);
  };

  const handleClick = () => {
    setCookie("recruitifi_gdpr_hash", moment(), {
      path: "/",
      domain: hostname.match(/recruitifi.com/) ? "recruitifi.com" : hostname
    });
  };

  return (
    !cookies.recruitifi_gdpr_hash && (
      <div className={classes.banner}>
        <p className={classnames("caption tall", classes.text)}>
          We use cookies to make your interactions with our website more
          meaningful. They help us better understand how our websites are used,
          so we can tailor content for you. For more information about the
          different cookies we are using, read the
          {" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.recruitifi.com/privacy-policy/"
          >
            Privacy Statement
          </a>
          . By continuing to navigate the site, you agree to the use of cookies
          on our behalf.
        </p>
        <div className={classes.button}>
          <Button
            color="blue"
            variant="secondary"
            onClick={handleClick}
            data-cy="gdpr-button"
          >
            Okay
          </Button>
        </div>
      </div>
    )
  );
}

export default withStyles(styles)(GDPRBanner);
