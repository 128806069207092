import every from "lodash/every";
import filter from "lodash/filter";
import { createSelector } from "reselect";

import createDeepEqualSelector from "./createDeepEqualSelector";

function buildFilterFunctions({
  contractId,
  contractIds,
  submitted,
  approved,
  disputed,
  collected,
  paid,
  collectable,
  payable,
  hideZeroHours,
}) {
  const filterFunctions = [];

  if (contractId) {
    filterFunctions.push(
      ({ attributes }) => attributes.contractId === contractId
    );
  }

  if (contractIds && contractIds.length) {
    filterFunctions.push(
      ({ attributes }) => contractIds.includes(attributes.contractId)
    );
  }

  if (typeof submitted === "boolean") {
    filterFunctions.push(
      ({ attributes }) => Boolean(attributes.submittedAt) === Boolean(submitted)
    );
  }

  if (typeof approved === "boolean") {
    filterFunctions.push(
      ({ attributes }) => Boolean(attributes.approvedAt) === Boolean(approved)
    );
  }

  if (typeof disputed === "boolean") {
    filterFunctions.push(
      ({ attributes }) => Boolean(attributes.disputedAt) === Boolean(disputed)
    );
  }

  if (typeof collected === "boolean") {
    filterFunctions.push(
      ({ attributes }) =>
        Boolean(attributes.collectionAt) === Boolean(collected)
    );
  }

  if (typeof paid === "boolean") {
    filterFunctions.push(
      ({ attributes }) =>
        Boolean(attributes.payoutCompletedAt) === Boolean(paid)
    );
  }

  if (typeof collectable === "boolean") {
    filterFunctions.push(
      ({ attributes }) =>
        (parseFloat(attributes.adjustedEmployerCollectionAmount) > 0) === collectable
    );
  }

  if (typeof payable === "boolean") {
    filterFunctions.push(
      ({ attributes }) =>
        (parseFloat(attributes.adjustedRecruiterPayoutAmount) > 0) === payable
    );
  }

  if (typeof hideZeroHours === "boolean") {
    filterFunctions.push(
      ({ attributes }) =>
        (parseFloat(attributes.totalBilledHours) === 0) !== hideZeroHours
    );
  }

  return filterFunctions;
}

export function getPayPeriods({ payPeriods }) {
  return payPeriods;
}

export function makeGetPayPeriodItems() {
  return createSelector([getPayPeriods], ({ items }) => items);
}

export const makeGetFilteredPayPeriods = () => {
  const getFilters = (_state, filters = {}) => filters;

  const filtersSelector = createDeepEqualSelector(
    [getFilters],
    (filters) => filters
  );

  return createSelector(
    [makeGetPayPeriodItems(), filtersSelector],
    (items, filters) => {
      const filterFunctions = [].concat(buildFilterFunctions(filters));

      return filter(items, (item) =>
        every(filterFunctions, (filterFunction) => filterFunction(item)));
    }
  );
};
