import normalize from "json-api-normalizer";

import { batch } from "react-redux";

import { GET, POST, PATCH, DELETE } from "../../util/apiHelpers";

import {
  receiveLegacyInvitations,
  removeLegacyInvitation,
} from "../legacyInvitationActions";
import {
  receivePreferredLinks,
  receivePreferredLink,
  removePreferredLink,
} from "../preferredLinkActions";
import { receiveIndividuals } from "../shared/individualActions";
import { receiveOrganizations } from "../shared/organizationActions";

import {
  receiveRecruiterLists,
  removeRecruiterLists,
} from "./recruiterListActions";

export const RECEIVE_SUGGESTED_RECRUITERS = "RECEIVE_SUGGESTED_RECRUITERS";

export const receiveSuggestedAgencies = (recruiters) => ({
  type: RECEIVE_SUGGESTED_RECRUITERS,
  payload: recruiters,
});

export const fetchLegacyAgencies =
  ({
    filter,
    locations,
    roles,
    work_remotely,
    recruiter_list_id,
    temp,
    perm,
  }) =>
  (dispatch) =>
    GET(
      `/api/v3/employer/preferred_links?filter=${filter}&locations=${locations}&roles=${roles}&work_remotely=${work_remotely}&recruiter_list_id=${recruiter_list_id}&temp=${temp}&perm=${perm}`
    ).then((res) => {
      const { recruiter, recruiterOrganization } = normalize(
        JSON.parse(res.data.individuals)
      );
      const { preferredLink } = normalize(JSON.parse(res.data.preferred_links));

      dispatch(receiveOrganizations(recruiterOrganization));
      dispatch(receiveIndividuals(recruiter));
      dispatch(receivePreferredLinks(preferredLink));

      return recruiter;
    });

export const fetchAllLegacyAgencies = () => (dispatch) =>
  GET(`/api/v3/employer/preferred_links`).then((res) => {
    const { recruiter, recruiterOrganization } = normalize(
      JSON.parse(res.data.individuals)
    );
    const { preferredLink } = normalize(JSON.parse(res.data.preferred_links));

    dispatch(receiveOrganizations(recruiterOrganization));
    dispatch(receiveIndividuals(recruiter));
    dispatch(receivePreferredLinks(preferredLink));

    return recruiter;
  });

export const fetchCommunityAgencies = () => (dispatch) =>
  GET("/api/v3/employer/community_agencies").then((res) => {
    const { recruiter, recruiterOrganization } = normalize(
      JSON.parse(res.data.individuals)
    );

    return batch(() => {
      dispatch(receiveOrganizations(recruiterOrganization));
      dispatch(receiveIndividuals(recruiter));
    });
  });

export const fetchBlockedCommunityAgencies = () => (dispatch) =>
  GET("/api/v3/employer/community_recruiter_exclusions").then((res) => {
    const { recruiter, recruiterOrganization } = normalize(
      JSON.parse(res.data.individuals)
    );

    return batch(() => {
      dispatch(receiveOrganizations(recruiterOrganization));
      dispatch(receiveIndividuals(recruiter));
    });
  });

export const fetchAgency = (recruiterId) => (dispatch) =>
  GET(`/api/v3/employer/agencies/${recruiterId}`).then((res) => {
    const { recruiter, recruiterOrganization } = normalize(
      JSON.parse(res.data.individual)
    );

    const preferredLink =
      res.data.preferred_link &&
      normalize(JSON.parse(res.data.preferred_link)).preferredLink;

    return batch(() => {
      preferredLink && dispatch(receivePreferredLinks(preferredLink));
      dispatch(receiveOrganizations(recruiterOrganization));
      dispatch(receiveIndividuals(recruiter));
    });
  });

export const fetchAgencyInvites =
  ({ filter, recruiter_list_id, temp, perm }) =>
  (dispatch) =>
    GET(
      `/api/v3/employer/legacy_invitations?filter=${filter}&recruiter_list_id=${recruiter_list_id}&temp=${temp}&perm=${perm}`
    ).then((res) => {
      const normalized = normalize(res.data);

      const { legacyInvitation, recruiter, recruiterOrganization } = normalized;

      return batch(() => {
        dispatch(receiveLegacyInvitations(legacyInvitation));
        dispatch(receiveOrganizations(recruiterOrganization));
        recruiter && dispatch(receiveIndividuals(recruiter));
      });
    });

export const fetchAgenciesAndInvites =
  (matchData = {}) =>
  (dispatch) =>
    Promise.all([
      dispatch(fetchLegacyAgencies(matchData)),
      dispatch(fetchAgencyInvites(matchData)),
    ]);

export const fetchSuggestedAgencies =
  (matchData = {}) =>
  (dispatch) =>
    dispatch(fetchLegacyAgencies(matchData));

export const createLegacyInvitations = (invitations) => (dispatch) =>
  POST("/api/v3/employer/legacy_invitations", { invites: invitations }).then(
    (res) => {
      const normalized = normalize(res.data);
      const {
        preferredLink,
        recruiterOrganization,
        legacyInvitation,
        recruiter,
        recruiterList,
      } = normalized;

      recruiterList && dispatch(receiveRecruiterLists(recruiterList));
      dispatch(receiveIndividuals(recruiter));
      dispatch(receiveOrganizations(recruiterOrganization));
      dispatch(receiveLegacyInvitations(legacyInvitation));
      dispatch(receivePreferredLinks(preferredLink));
    }
  );

export const sendAgencyInviteReminder = (invitationId) => (dispatch) =>
  PATCH(`/api/v3/employer/legacy_invitations/${invitationId}/remind`).then(
    (res) => {
      const normalized = normalize(res.data);
      dispatch(receiveLegacyInvitations(normalized.legacyInvitation));
    }
  );

export const updateAgencyFees = (data) => (dispatch) =>
  PATCH(`/api/v3/employer/preferred_links/${data.id}`, data).then((res) => {
    const normalized = normalize(res.data);
    return dispatch(
      receivePreferredLink(normalized.preferredLink[res.data.data.id])
    );
  });

export const deletePreferredLink =
  (preferredLinkId, notify = true, legacyInvitationId) =>
  (dispatch) =>
    DELETE(
      `/api/v3/employer/preferred_links/${preferredLinkId}?notify=${notify}`
    ).then((res) => {
      dispatch(removeRecruiterLists(res.data));
      dispatch(removePreferredLink(preferredLinkId));
      legacyInvitationId &&
        dispatch(removeLegacyInvitation(legacyInvitationId));
    });

export const removeFromCommunity =
  (agencyOrganizationId, data) => (_dispatch) =>
    POST(
      `/api/v3/employer/agencies/${agencyOrganizationId}/remove_from_community`,
      data
    )
      .then(() => Promise.resolve())
      .catch((error) => Promise.reject(error));

export const restoreToCommunity = (agencyOrganizationId, data) => (_dispatch) =>
  POST(
    `/api/v3/employer/agencies/${agencyOrganizationId}/restore_to_community`,
    data
  )
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
