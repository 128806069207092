import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { fetchCandidate as employerFetchCandidate } from "../../../../actions/employers/candidateActions";
import { fetchJobCast } from "../../../../actions/employers/jobcastActions";
import { fetchCandidate as recruiterFetchCandidate } from "../../../../actions/recruiters/candidateActions";
import { fetchJobcastRequest } from "../../../../actions/recruiters/jobcastActions";
import { getAllCandidates } from "../../../../selectors/candidateSelectors";
import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import { buildFullName } from "../../../../util/formatHelpers";
import SectionHeader from "../../../employer/JobCasts/view/Candidates/actions/modals/Hire/SectionHeader";
import ActionModalContainer from "../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../General/ActionModal/ActionModalContent";
import ActionModalHeader from "../../../General/ActionModal/ActionModalHeader";

import {
  IDLE,
  LOADED,
  LOADING,
  ERROR,
} from "../../../General/customHooks/usePagination";
import LoadingPage from "../../../General/LoadingPage";

import ChecklistTable from "./ChecklistTable/ChecklistTable";
import FormFooter from "./FormFooter";
import FormInitializer from "./FormInitializer";
import { fetchContract as employerFetchContract } from "../../../../actions/employers/contractActions";
import { fetchContract as recruiterFetchContract } from "../../../../actions/recruiters/contractActions";

export function useScopedCandidateFetch(candidateId) {
  const [loadStatus, setLoadStatus] = useState(IDLE);
  const dispatch = useDispatch();
  const currentIndividual = useSelector(getCurrentIndividual);
  const candidate = useSelector(
    (state) => getAllCandidates(state)[candidateId]
  );

  const fetchFullCandidate = useMemo(
    () =>
      currentIndividual.employer
        ? employerFetchCandidate
        : recruiterFetchCandidate,
    [currentIndividual.employer]
  );

  const fetchContract = useMemo(
    () =>
      currentIndividual.employer
        ? employerFetchContract
        : recruiterFetchContract,
    [currentIndividual.employer]
  );

  useEffect(() => {
    setLoadStatus(LOADING);

    Promise.all([
      dispatch(fetchFullCandidate(candidateId)),
      dispatch(fetchContract(candidateId)),
    ])
      .then(() => {
        setLoadStatus(LOADED);
      })
      .catch(() => {
        setLoadStatus(ERROR);
      });
  }, [dispatch, fetchFullCandidate, fetchContract, candidateId]);

  return [candidate, loadStatus];
}

function useScopedCandidateTransitionHandler(candidate) {
  const dispatch = useDispatch();

  const currentIndividual = useSelector(getCurrentIndividual);

  useEffect(() => {
    // candidate transitions from pre-assignment to hire
    if (candidate.attributes.hiredAt) {
      // refresh left nav stage counts
      dispatch(
        currentIndividual.recruiter
          ? fetchJobcastRequest(candidate.attributes.requestId)
          : fetchJobCast(candidate.attributes.jobcastId)
      );
    }
  }, [dispatch, candidate, currentIndividual.recruiter]);
}

function FormContent({ candidate }) {
  return (
    <FormInitializer {...{ candidate }}>
      {({ initialValues }) => (
        <>
          <ActionModalContent>
            <SectionHeader
              title="Pre-Assignment Checklist"
              style={{ marginTop: 0 }}
            />
            <p>
              All of the pre-assignment steps must be completed before the
              candidate can begin working.
            </p>
            <ChecklistTable />
          </ActionModalContent>
          <FormFooter {...{ initialValues }} />
        </>
      )}
    </FormInitializer>
  );
}

function PreAssignmentModal({ candidate }) {
  // need to fetch fully serialized candidate, props candidate might be
  // abbreviated index version
  const [fullCandidate, loadStatus] = useScopedCandidateFetch(candidate.id);
  useScopedCandidateTransitionHandler(fullCandidate);

  const { firstName, lastName } = candidate.attributes;

  return (
    <ActionModalContainer color="blue">
      <ActionModalHeader
        title="Complete Pre-Assignment Checklist"
        subtitle={buildFullName(firstName, lastName)}
      />
      {loadStatus === LOADED ? (
        <FormContent candidate={fullCandidate} />
      ) : (
        <ActionModalContent>
          <LoadingPage />
        </ActionModalContent>
      )}
    </ActionModalContainer>
  );
}

export default PreAssignmentModal;
