import {
  SET_LEFT_DRAWER_VISIBLE,
  SET_LEFT_DRAWER_CONTENT,
  SET_NOTIFICATIONS_LEFT_DRAWER_VISIBLE,
  SET_NOTIFICATIONS_DRAWER_CONTENT,
  CLEAR_LEFT_DRAWER,
} from "../../actions/shared/uiActions";

const _defaultState = {
  visible: false,
  content: "",
};

export const leftDrawerReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case SET_LEFT_DRAWER_VISIBLE:
      return { ...state, visible: action.payload };
    case SET_LEFT_DRAWER_CONTENT:
      return { ...state, content: action.payload };
    case SET_NOTIFICATIONS_LEFT_DRAWER_VISIBLE:
      return { ...state, notificationsVisible: action.payload };
    case SET_NOTIFICATIONS_DRAWER_CONTENT:
      return { ...state, notificationsContent: action.payload };
    case CLEAR_LEFT_DRAWER:
      return _defaultState;
    default:
      return state;
  }
};
