import React from 'react';
import { useDispatch } from "react-redux";

import { openModal } from '../../../../../../actions/shared/uiActions';
import Button from "../../../../../forms/custom/Button";

import CloseContractModal from '../../../../../shared/Contracts/CloseContractModal';

function CloseContractButton({ candidate, overloadedClasses }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openModal(<CloseContractModal candidate={candidate} />));
  };

  return (
    <Button
      color="red"
      variant="primary"
      onClick={handleClick}
      style={{}}
      overloadedClasses={overloadedClasses}
      data-cy="close-contract-button"
    >
      End Contract
    </Button>
  );
}

export default CloseContractButton;
