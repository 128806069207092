import { createMuiTheme } from "@material-ui/core/styles";

// Mui theming: https://material-ui-next.com/customization/themes/
// Please see the CSS API sections for each individual component for details about component overrides
// Default theme: https://material-ui-next.com/customization/default-theme/

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 780,
    },
  },
  palette: {
    primary: {
      contrastText: "#fff",
      dark: "#66bace",
      main: "#008dae",
    },
    secondary: {
      contrastText: "#008dae",
      dark: "#e5f3f6",
      main: "#fff",
    },
    error: {
      main: "#FF6D6D",
    },
    text: {
      primary: "#008dae",
      secondary: "#546e7a",
    },
    talk: {
      contrastText: "#fff",
      main: "#F35B5E",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: "16px",
        fontWeight: "600",
        letterSpacing: "1px",
        padding: "8px 26px",
      },
    },
    MuiCardHeader: {
      root: {
        borderBottom: "2px solid #008dae",
      },
      title: {
        fontFamily: "'proxima-nova', helvetica",
      },
    },
    MuiCheckbox: {
      root: {
        height: "15px",
        fontSize: "19px",
        transform: "translate(0,-1px)",
        width: "15px",
        color: "#90a4ae",
      },
    },
    MuiAccordion: {
      root: {
        borderLeft: "0.5px solid #ccc",
        borderRight: "0.5px solid #ccc",
        borderTop: "0.5px solid #ccc",
        width: "100%",
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
        "&$expanded": {
          backgroundColor: "white !important",
          borderTop: "1px solid #ccc",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        color: "#37474f",
        flexDirection: "column",
        fontSize: "12px",
      },
    },
    MuiAccordionSummary: {
      root: {
        color: "#37474f",
        fontSize: "14px",
        maxHeight: "33px",
        minHeight: "33px",
        "&$expanded": {
          minHeight: "0",
          margin: "12px 0",
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: "20px",
        position: "relative",
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      label: {
        color: "#37474f",
        fontSize: "16px",
        fontWeight: "400",
      },
    },
    MuiFormHelperText: {
      root: {
        color: "#546e7a",
        fontSize: "10px",
        position: "inherit",
        "&$focused": {
          textTransform: "none",
        },
        "&$error": {
          marginBottom: "-20px", // dont push other elements around when error message appears
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "#B0BEC5",
        fontSize: "12px",
        textTransform: "uppercase",
        "&$focused": {
          color: "#008dae",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      },
    },
    MuiInput: {
      input: {
        color: "#37474F",
        fontFamily: "'proxima-nova', helvetica",
        fontSize: "16px",
        fontWeight: "400",
        letterSpacing: "0.5px",
        lineHeight: "22px",
        height: 22,
        "&::placeholder": {
          color: "#B0BEC5",
          fontStyle: "italic",
          opacity: 1,
        },
        "&.disabled": {
          "&::placeholder": {
            color: "#90A4AE",
          },
        },
      },
      inputMultiline: {
        color: "#37474F",
        fontFamily: "'proxima-nova', helvetica",
        fontSize: "16px",
        fontWeight: "400",
        letterSpacing: "0.5px",
        lineHeight: "22px",
        height: "auto",
      },
      root: {
        lineHeight: "22px",
        fontSize: "16px",
        "&$focused": {
          fontSize: "16px",
          color: "#008dae",
          textTransform: "none",
        },
        "&.multiline-box": {
          lineHeight: "22px",
          fontSize: "16px",
          border: "1px solid #90a4ae",
          padding: "5px",
          "&$focused": {
            border: "1px solid #008dae",
            backgroundColor: "#fafafa",
            color: "#37474f",
            fontFamily: "'proxima-nova', helvetica",
            fontSize: "16px",
            fontWeight: "400",
            letterSpacing: "0.5px",
            lineHeight: "22px",
          },
          "&.error": {
            border: "2px solid #FF6D6D",
          },
          "&.disabled": {
            background: "#eceff1",
          },
        },
      },
      underline: {
        "&:after": {
          backgroundColor: "#008dae",
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "#B0BEC5",
        fontFamily: "'proxima-nova', helvetica",
        fontSize: "13px",
        fontWeight: "600",
        letterSpacing: "0.4px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "&.multiline": {
          paddingLeft: "5px",
        },
      },
      shrink: {
        transform: "translate(0, 1.5px) scale(1)",
        "&.multiline": {
          transform: "translate(-5px, 1.5px) scale(1)",
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        "& h4": {
          fontSize: 24,
        },
      },
    },
    MuiMobileStepper: {
      root: {
        width: "250px",
        background: "#FFF",
      },
      dot: {
        backgroundColor: "#B0BEC5",
      },
      dotActive: {
        backgroundColor: "#37474F",
      },
      dots: {
        justifyContent: "space-around",
        padding: "0 20px",
      },
    },
    MuiTab: {
      root: {
        letterSpacing: "-0.1px",
        fontWeight: "normal",
        "&$selected": {
          fontWeight: "bold",
        },
      },
    },
    MuiPaper: {
      root: {
        borderCollapse: "separate",
        color: "#37474F",
      },
    },
    MuiRadio: {
      root: {
        fontSize: "15px",
        height: "15px",
        width: "15px",
        color: "#90a4ae",
      },
    },
    MuiNativeSelect: {
      select: {
        height: 22,
      },
    },
    MuiSnackbarContent: {
      message: {
        fontFamily: "'proxima-nova', helvetica",
        fontSize: "12px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#ffffff",
      },
      action: {
        fontFamily: "'proxima-nova', helvetica",
        fontSize: "12px",
        fontWeight: "bold",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#00c0ee",
      },
    },
    MuiStepIcon: {
      active: {
        color: "#37474F !important",
      },
      completed: {
        color: "#3C9255 !important",
      },
    },
    MuiStepLabel: {
      root: {
        fontSize: "24px",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "inherit",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
    },
  },
  typography: {
    fontFamily: "'proxima-nova', helvetica",
    body1: {
      color: "#546e7a",
    },
  },
});

export default theme;
