import {
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table,
} from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";
import map from "lodash/map";
import React from "react";

import TypedChecklistItemRow from "./TypedChecklistItemRow";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px 0px`,
  },
  header: {
    backgroundColor: "#e3e9e9",
    "& th": {
      fontWeight: "bold",
      color: "#37474F",
    },
    "& th:not(:last-child)": {
      borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
  },
  body: {
    "& td": {
      borderBottom: "none",
      color: "#37474F",
    },
    "& td:not(:last-child)": {
      borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
    "& tr:not(:last-child)": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
  },
}));

function ChecklistTable({ editable = true }) {
  const classes = useStyles();

  const { values } = useFormikContext();

  return (
    <TableContainer component={Paper} classes={{ root: classes.container }}>
      <Table>
        <TableHead classes={{ root: classes.header }}>
          <TableCell align="center" style={{ width: "30%" }}>
            Process
          </TableCell>
          <TableCell align="center" style={{ width: "40%" }}>
            Confirmation
          </TableCell>
          <TableCell align="center" style={{ width: "30%" }}>
            Completed
          </TableCell>
        </TableHead>
        <TableBody classes={{ root: classes.body }}>
          <FieldArray
            name="checklistItems"
            render={() =>
              map(values.checklistItems, (checklistItem, idx) => (
                <TypedChecklistItemRow
                  {...{
                    checklistItem,
                    editable,
                    fieldId: `checklistItems.${idx}`,
                  }}
                />
              ))}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ChecklistTable;
