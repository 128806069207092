import { TableCell, TableRow, makeStyles } from "@material-ui/core";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { useFormikContext } from "formik";
import get from "lodash/get";
import React from "react";

import FormContextFileInput from "../../../../forms/formik/FormContextInputs/FormContextFileInput";

import ConfirmationCellContent from "./ConfirmationCellContent";
import NoActionNeeded from "./NoActionNeeded";
import PreAssignmentDescriptionPopover from "./PreAssignmentDescriptionPopover";

function FileLink({ fieldId }) {
  const { values } = useFormikContext();

  const document = get(values, `${fieldId}.document`);

  if (!document) return null;

  return (
    <a
      href={document.preview}
      target="_blank"
      rel="noopener noreferrer"
      style={{ fontSize: 16 }}
    >
      {document.name}
    </a>
  );
}

const useStyles = makeStyles(() => ({
  fileContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteIcon: {
    color: "#FF6D6D",
    cursor: "pointer",
    fontSize: "23px",
    height: "23px",
    width: "23px",
    marginLeft: 6,
  },
}));

function EditableCellContent({ fieldId }) {
  const { setFieldValue, values } = useFormikContext();
  const classes = useStyles();

  const handleDelete = () => {
    setFieldValue(`${fieldId}.document`, null);
  };

  const document = get(values, `${fieldId}.document`);

  return document ? (
    <div className={classes.fileContainer}>
      <FileLink {...{ fieldId }} />
      <DeleteForever onClick={handleDelete} className={classes.deleteIcon} />
    </div>
  ) : (
    <FormContextFileInput
      id={`${fieldId}.document`}
      overrideStyle={{ width: "75%", marginBottom: 0 }}
    />
  );
}

function DocumentFieldRow({ checklistItem, editable, fieldId }) {
  return (
    <TableRow>
      <TableCell>
        <div className="bold">
          {checklistItem.name}
        </div>
        <PreAssignmentDescriptionPopover {...checklistItem} />
      </TableCell>
      <TableCell align="center">
        <NoActionNeeded {...{ checklistItem }}>
          {editable ? (
            <EditableCellContent {...{ fieldId }} />
          ) : (
            <FileLink {...{ fieldId }} />
          )}
        </NoActionNeeded>
      </TableCell>
      <TableCell>
        <ConfirmationCellContent {...{ checklistItem }} />
      </TableCell>
    </TableRow>
  );
}

export default DocumentFieldRow;
