import { Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { createLegacyInvitations } from "../../../../../actions/employers/agencyActions";
import { clearModal } from "../../../../../actions/shared/uiActions";
import { makeGetAgencyRecruiter } from "../../../../../selectors/employer/agencySelectors";
import ActionModalContainer from "../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../General/ActionModal/ActionModalHeader";
import withSnackbar from "../../../../General/withSnackbar";

import AddAsLegacyModalContent from "./AddAsLegacyModalContent";

import {
  handleSubmit,
  buildInitialValues,
  validationSchema,
} from "./fieldHelpers";

function AddAsLegacyModal({ recruiterId, snackbar, setExcludedOrgIds }) {
  const dispatch = useDispatch();
  const getAgencyRecruiter = makeGetAgencyRecruiter();
  const agencyRecruiter = useSelector((state) => getAgencyRecruiter(state, recruiterId));

  const agencyOrgName = agencyRecruiter.organization.attributes.name;
  const agencyOrgId = agencyRecruiter.organization.attributes.id;

  const dispatchClearModal = () => dispatch(clearModal());
  const dispatchCreateLegacyInvitations = (data) => dispatch(createLegacyInvitations(data));

  return (
    <Formik
      initialValues={buildInitialValues(agencyRecruiter.individual.attributes)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit(
        dispatchCreateLegacyInvitations,
        dispatchClearModal,
        snackbar,
        agencyOrgName,
        setExcludedOrgIds,
        agencyOrgId
      )}
    >
      {(props) => (
        <ActionModalContainer color="blue">
          <ActionModalHeader
            title="Add to Preferred Agencies"
            subtitle={agencyOrgName}
          />
          <ActionModalContent>
            <AddAsLegacyModalContent />
          </ActionModalContent>
          <ActionModalFooter
            actionText="Invite"
            handleAction={props.handleSubmit}
            isSubmitting={props.isSubmitting}
          />
        </ActionModalContainer>
      )}
    </Formik>
  );
}

export default withSnackbar()(AddAsLegacyModal);
