import { TableCell, TableRow } from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";

import Button from "../../../../forms/custom/Button";

import ConfirmationCellContent from "./ConfirmationCellContent";
import NoActionNeeded from "./NoActionNeeded";
import PreAssignmentDescriptionPopover from "./PreAssignmentDescriptionPopover";

function EditableCellContent({ checklistItem, fieldId }) {
  const { setFieldValue } = useFormikContext();

  const handleConfirmationSelection = (isConfirmed) => {
    setFieldValue(`${fieldId}.basicConfirmed`, isConfirmed);
  };

  return checklistItem.basicConfirmed ? (
    <Button
      color="red"
      variant="secondary"
      onClick={() => handleConfirmationSelection(false)}
    >
      Unconfirm
    </Button>
  ) : (
    <Button
      color="blue"
      variant="secondary"
      onClick={() => handleConfirmationSelection(true)}
    >
      Confirm
    </Button>
  );
}

function StaticCellContent({ checklistItem }) {
  return checklistItem.basicConfirmed ? "Confirmed" : "Unconfirmed";
}

function BasicConfirmationFieldRow({ checklistItem, editable, fieldId }) {
  return (
    <TableRow data-cy="checklist-item-confirmation-row">
      <TableCell>
        <div className="bold">
          {checklistItem.name}
        </div>
        <PreAssignmentDescriptionPopover {...checklistItem} />
      </TableCell>
      <TableCell align="center">
        <NoActionNeeded {...{ checklistItem }}>
          {editable ? (
            <EditableCellContent {...{ checklistItem, fieldId }} />
          ) : (
            <StaticCellContent {...{ checklistItem }} />
          )}
        </NoActionNeeded>
      </TableCell>
      <TableCell>
        <ConfirmationCellContent {...{ checklistItem }} />
      </TableCell>
    </TableRow>
  );
}

export default BasicConfirmationFieldRow;
