import each from "lodash/each";
import every from "lodash/every";
import filter from "lodash/filter";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import { createSelector } from "reselect";

import createDeepEqualSelector from "../createDeepEqualSelector";
import { PERM } from "../jobcastSelectors";

import { makeGetNotificationItems } from "../notificationsSelectors";

// index scopes
export const REQUEST_ACTIVE = "REQUEST_ACTIVE";
export const REQUEST_ARCHIVED = "REQUEST_ARCHIVED";

// candidate index scopes
export const ACTIVE = "ACTIVE";
export const PRE_ASSIGNMENT = "PRE_ASSIGNMENT";
export const REJECTED = "REJECTED";
export const HIRED = "HIRED";
export const WITH_ALERTS = "WITH_ALERTS";

export const getIndexLoadStatus = (state) => state.candidates.indexTypesLoaded;

const buildFilterFunctions = ({
  requestId,
  status,
  positionStatus,
  isTemp = false,
  candidateId,
}) => {
  const filterFunctions = [];

  if (!requestId) {
    filterFunctions.push((candidate) => {
      const requiredType = isTemp
        ? "TempRecruiterSubmission"
        : "PermRecruiterSubmission";

      return candidate.attributes.type === requiredType;
    });
  }

  if (requestId) {
    filterFunctions.push(
      (candidate) => candidate.attributes.requestId === requestId
    );
  }

  if (status) {
    filterFunctions.push((candidate) =>
      isArray(status)
        ? includes(status, candidate.attributes.status)
        : status === candidate.attributes.status);
  }

  if (positionStatus) {
    filterFunctions.push(
      (candidate) => positionStatus === candidate.attributes.positionStatus
    );
  }

  if (candidateId) {
    if (isArray(candidateId)) {
      const includedIds = new Set(candidateId);

      filterFunctions.push(({ id }) => includedIds.has(id));
    } else {
      filterFunctions.push(({ id }) => id === candidateId);
    }
  }

  return filterFunctions;
};

export const makeGetIndexLoadStatus = () => {
  const getFilters = (_state, filters) => filters;
  return createSelector(
    [getIndexLoadStatus, getFilters],
    (indexLoadStatus, { requestId, status, positionType = PERM }) =>
      Boolean(
        indexLoadStatus[requestId] ||
          (positionType && indexLoadStatus[positionType][status]) ||
          (status && indexLoadStatus[`${requestId}-${status}`])
      )
  );
};

export const getAllCandidates = (state) => state.candidates.items;

export const makeGetCandidatesByRequestId = () => {
  const getRequestId = (_state, options) => options.requestId;

  return createSelector(
    [getAllCandidates, getRequestId],
    (candidates, requestId) =>
      filter(
        candidates,
        (candidate) => candidate.attributes.requestId === requestId
      )
  );
};

export const makeGetFilteredCandidates = () => {
  const getCandidateFilters = (_state, filters) => filters;

  const getFiltersSelector = createDeepEqualSelector(
    [getCandidateFilters],
    (filters) => filters
  );

  return createSelector(
    [getAllCandidates, getFiltersSelector],
    (candidates, candidateFilters) => {
      const filterFunctions = buildFilterFunctions(candidateFilters);

      return filter(candidates, (candidate) =>
        every(filterFunctions, (filterFunction) => filterFunction(candidate)));
    }
  );
};

export const makeGetCandidatesWithAlerts = () =>
  createSelector(
    [makeGetNotificationItems(), makeGetFilteredCandidates()],
    (notifications, candidates) => {
      const hasUnreadAlerts = {};
      each(notifications, ({ attributes }) => {
        if (!attributes.markedReadAt && attributes.recruiterSubmissionId) {
          hasUnreadAlerts[attributes.recruiterSubmissionId] = true;
        }
      });

      return filter(candidates, ({ id }) => hasUnreadAlerts[id]);
    }
  );

export const makeGetCandidate = () => {
  const getCandidateId = (_state, candidateId) => candidateId;

  return createSelector(
    [getAllCandidates, getCandidateId],
    (candidates, id) => candidates[id]
  );
};
