import accounting from "accounting";
import camelCase from "lodash/camelCase";
import capitalize from "lodash/capitalize";
import each from "lodash/each";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import isPlainObject from "lodash/isPlainObject";
import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import omit from "lodash/omit";
import reduce from "lodash/reduce";
import round from "lodash/round";
import snakeCase from "lodash/snakeCase";
import startCase from "lodash/startCase";
import React from "react";

import {
  PERCENTAGE_OF_COMPENSATION,
  FLAT_FEE,
} from "../components/forms/formik/FormikFeeOptions/fieldHelpers";
import PopoverLink from "../components/General/PopoverLink";
import OptimizedRatesIcon from "../components/shared/Icons/OptimizedRatesIcon";

import constants from "./constants";
import { currencySymbol } from "./constantsToOptions";

export const normalizePhoneNumber = (phoneNumber, defaultRes = "n/a") => {
  if (phoneNumber) {
    const normalized = phoneNumber
      .split("")
      .filter((elem) => elem.match(/\d/))
      .reverse()
      .slice(0, 10)
      .reverse()
      .join("");

    return `${normalized.slice(0, 3)}-${normalized.slice(
      3,
      6
    )}-${normalized.slice(6, 10)}`;
  }
  return defaultRes;
};

export const buildFullName = (firstName, lastName) => {
  const applyCaps = (name) => name.replace(/\w+/g, (wrd) => capitalize(wrd));

  return applyCaps(`${firstName} ${lastName}`);
};

export const possessifyName = (name) =>
  `${name}${name.slice(-1) === "s" ? "'" : "'s"}`;

export const formatCurrency = (amount_cents, currency_code, options = {}) => {
  const { currency_options } = constants;
  const noSubUnit = currency_options[currency_code]?.decimal === 0;
  const salaryCurrencyOption =
    currency_options[options?.salaryCurrency]?.decimal === 0;

  const amount_dollars =
    options.noConversion ||
    (noSubUnit && !options.salaryConversion) ||
    salaryCurrencyOption
      ? amount_cents
      : amount_cents / 100.0;

  const newOptions = noSubUnit ? merge({}, { precision: 0 }, options) : options;

  return accounting.formatMoney(amount_dollars, {
    symbol: currencySymbol(currency_code),
    ...omit(newOptions, ["salaryCurrency", "salaryConversion"]),
  });
};

export const formatFeeOptionString = (feeOption, options = {}) => {
  const { withNote } = options;

  // we should move this case outside of the function call
  if (!feeOption) {
    return (
      <PopoverLink
        renderLink={(handleClick, _isSelected) => (
          <span onClick={handleClick} onMouseEnter={handleClick}>
            <OptimizedRatesIcon style={{ fontSize: 28 }} />
          </span>
        )}
        renderPopoverContent={(handleClick) => (
          <div
            data-cy="fee-tooltip-content"
            style={{ padding: "6px 8px", fontSize: 16 }}
            onMouseLeave={handleClick}
          >
            <a
              href="https://help.recruitifi.com/en/articles/5485229-what-is-the-recruitifi-optimized-fee-and-how-does-it-work"
              target="_blank"
              rel="noopener noreferrer"
            >
              RecruitiFi Optimized Fee
            </a>
          </div>
        )}
      />
    );
  }

  let feeOptionString;

  if (feeOption.feeType === PERCENTAGE_OF_COMPENSATION) {
    feeOptionString = `${round(feeOption.feePercentage * 100, 2)}%`;
  } else if (feeOption.feeType === FLAT_FEE) {
    feeOptionString = formatCurrency(feeOption.feeCents, feeOption.currency, {
      precision: 2,
    }).replace(/\.?0+$/, "");
  }

  if (withNote && feeOption.note) feeOptionString += ` - ${feeOption.note}`;

  return feeOptionString;
};

// CODE REVIEW: this is a method I think I'll use frequently when
// parsing strings with Regex's. not sure if it belongs in this file though?
export const collectRegexpMatches = (str, regexp) => {
  const matches = [];
  const matchData = regexp.exec(str);
  if (!matchData) {
    return [];
  }
  let idx = 0;
  while (typeof matchData[++idx] === "string") {
    matches.push(matchData[idx]);
  }
  return matches;
};

export const trimTrailingNewlines = (str) => {
  if (typeof str !== "string") {
    return "";
  }
  return str.replace(/\n+$/, "");
};

export const replaceHTMLTags = (htmlStr, replacementVal = "") =>
  htmlStr.replace(/<[^>]+\/?>/g, replacementVal);

export const prependHTTPProtocol = (urlString) => {
  if (typeof urlString === "string" && urlString.match(/^(http|https):\/\//)) {
    return urlString;
  }
  return `http://${urlString}`;
};

export const truncateText = (str, maxLength, trailingChars = "...") => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength - trailingChars.length) + trailingChars;
  }
  return str;
};

export const mapKeysToCamelCase = (obj) => {
  const newObj = isArray(obj) ? [] : {};

  each(obj, (val, key) => {
    if (isPlainObject(val) || isArray(val)) {
      val = mapKeysToCamelCase(val);
    }
    newObj[camelCase(key)] = val;
  });

  return newObj;
};

export const mapKeysToSnakeCase = (obj) => {
  const newObj = isArray(obj) ? [] : {};

  each(obj, (val, key) => {
    if (isPlainObject(val) || isArray(val)) {
      val = mapKeysToSnakeCase(val);
    }
    newObj[snakeCase(key)] = val;
  });

  return newObj;
};

// https://github.com/lodash/lodash/issues/2240#issuecomment-418820848
export const flattenKeys = (obj, path = []) =>
  !isObject(obj)
    ? { [path.join(".")]: obj }
    : reduce(
        obj,
        (cum, next, key) => merge(cum, flattenKeys(next, [...path, key])),
        {}
      );

export const mapToInclusionHash = (array) => {
  const res = {};

  array.forEach((key) => (res[key] = true));

  return res;
};

// this helps with desired merge behavior in redux when receiving array data
// since the keys (idxs) are not proper identifiers in that context
export const mergeWithArrayOverwrite = (...objects) =>
  mergeWith(...objects, (prevVal, newVal) => {
    if (isArray(prevVal)) {
      return newVal || prevVal;
    }
  });

export const capitalCase = (string) => startCase(camelCase(string));
