import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import classnames from "classnames";
import { withFormik } from "formik";
import includes from "lodash/includes";
import map from "lodash/map";
import size from "lodash/size";
import sortBy from "lodash/sortBy";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

import {
  openModal,
  clearProfileDrawer,
  openProfileDrawer,
} from "../../../../actions/shared/uiActions";
import { getCandidateConversation } from "../../../../selectors/conversationSelector";
import { getCurrentIndividual } from "../../../../selectors/individualSelectors";

import {
  makeGetFilteredNotifications,
  UNREAD,
} from "../../../../selectors/notificationsSelectors";
import { normalizePhoneNumber } from "../../../../util/formatHelpers";
import { string, object } from "../../../../util/yup";

import AgencyRecruiterDrawer from "../../../employer/Agencies/view/AgencyRecruiterDrawer";
import { OFFER } from "../../../employer/JobCasts/view/Candidates/constants";
import Button from "../../../forms/custom/Button";
import MultiLineTextField from "../../../forms/custom/MultiLineTextField";
import GlobalAvatar from "../../../General/GlobalAvatar";
import { CONTRACT_ACTIVE } from "../../../recruiter/Candidates/constants";
import MessagesConsumer from "../../ActionCable/subscriptions/MessagesConsumer";
import RotatingEmptyState from "../../EmptyState/RotatingEmptyState";
import { ARCHIVED, HIRED } from "../constants";

import AttachFileModal from "./AttachFileModal";
import ChatMessage from "./ChatMessage";
import style from "./chatStyles";
import withNotificationsReader from "./withNotificationsReader";

class ChatTab extends React.PureComponent {
  constructor(props) {
    super(props);

    this.messageList = React.createRef();
  }

  componentDidMount() {
    this.props.fetchConversation().then(this.scrollToMessageBottom);
  }

  componentDidUpdate(prevProps) {
    const prevConversation = prevProps.conversation;
    const { conversation } = this.props;

    const hasConversation = () => conversation &&
      conversation.attributes &&
      prevConversation &&
      prevConversation.attributes;

    const hasNewMessages = () => size(conversation.attributes.messages) !==
      size(prevConversation.attributes.messages);

    if (hasConversation() && hasNewMessages()) {
      this.scrollToMessageBottom();
    }
  }

  handleAttachFileClick = () => {
    this.props.openModal(<AttachFileModal
      candidate={this.props.candidate}
      conversation={this.props.conversation}
      employer={this.props.currentIndividual.employer}
    />);
  };

  scrollToMessageBottom = () => {
    if (this.messageList.current) {
      this.messageList.current.scrollTop =
        this.messageList.current.scrollHeight;
    }
  };

  viewAgencyRecruiter() {
    const {
      clearProfileDrawer,
      openProfileDrawer,
      conversation: {
        attributes: { otherParticipantId },
      },
    } = this.props;

    clearProfileDrawer();

    return openProfileDrawer(
      <AgencyRecruiterDrawer recruiterId={otherParticipantId} />
    );
  }

  renderIntroSection() {
    const {
      conversation,
      classes,
      currentIndividual: { employer },
    } = this.props;

    if (conversation && conversation.attributes) {
      const {
        otherParticipantFirstName,
        otherParticipantLastName,
        otherParticipantOrganization,
        otherParticipantEmail,
        otherParticipantPhone,
        otherParticipantPhoneExt,
        otherParticipantPhonePrefix,
        otherParticipantAvatar,
      } = conversation.attributes;
      if (employer) {
        return (
          <div className={classes.introSection}>
            <div style={{ display: "flex" }}>
              <GlobalAvatar
                individual={{
                  firstName: otherParticipantFirstName,
                  lastName: otherParticipantLastName,
                  avatarIcon: otherParticipantAvatar,
                }}
                overrideStyle={{
                  marginLeft: 12,
                  marginRight: 12,
                }}
              />
              <div style={{ alignItems: "center" }}>
                <h5
                  className="pseudo-link"
                  style={{ color: "#008DAE" }}
                  onClick={() => this.viewAgencyRecruiter()}
                >
                  {`${otherParticipantFirstName} ${otherParticipantLastName}`}
                </h5>
                <span className="small">
                  {(this.props.organizationProfile &&
                    this.props.organizationProfile.attributes.name) ||
                    otherParticipantOrganization}
                </span>
              </div>
            </div>

            <div className={classes.introInfo}>
              <span className="small">{otherParticipantEmail}</span>
              <span className="small">
                {otherParticipantPhone
                  ? `+${otherParticipantPhonePrefix} ${normalizePhoneNumber(
                    otherParticipantPhone
                  )} ${otherParticipantPhoneExt || ""}`
                  : ""}
              </span>
            </div>
          </div>
        );
      }
      return (
        <div className={classes.introSection}>
          <div style={{ display: "flex" }}>
            <GlobalAvatar
              individual={{
                firstName: otherParticipantFirstName,
                lastName: otherParticipantLastName,
                avatarIcon: otherParticipantAvatar,
              }}
              overrideStyle={{
                marginLeft: 12,
                marginRight: 12,
              }}
            />
            <div style={{ alignItems: "center" }}>
              <h5>
                {`${otherParticipantFirstName}`}
                {" "}
                {`${otherParticipantLastName[0]}.`}
              </h5>
              <span className="small">
                {(this.props.jobcast &&
                  this.props.jobcast.attributes.organizationName) ||
                  otherParticipantOrganization}
              </span>
            </div>
          </div>
        </div>
      );
    }
  }

  renderChat() {
    const {
      conversation,
      classes,
      currentIndividual: { employer },
    } = this.props;

    if (conversation && conversation.attributes) {
      const messages = sortBy(conversation.attributes.messages, (message) => moment(message.attributes.timestamp));

      const lastReadMsg = messages.find(
        ({ attributes: { id } }) => id === conversation.attributes?.lastReadMsgNotification?.notificationSubjectId
      );

      const groupedMessages = messages.reduce((acc, message) => {
        const date = moment(message.attributes.timestamp).startOf('day').format();

        if (!acc[date]) acc[date] = [];

        acc[date].push(message);

        return acc;
      }, {});

      return (
        <div className={classes.messageContainer} ref={this.messageList}>
          <ul className={classes.messages}>
            {Object.entries(groupedMessages).map(([date, messages]) => (
              <>
                <span className={classes.dateLine}>
                  {moment(date).format('MMM D, YYYY')}
                </span>
                {messages.map((message) => (
                  <ChatMessage
                    message={message}
                    lastReadAt={message === lastReadMsg ? conversation.attributes?.lastReadMsgNotification?.markedReadAt : null}
                    key={message.id}
                    isEmployer={employer}
                  />
                ))}
                {' '}
              </>
            ))}
          </ul>
        </div>
      );
    }
  }

  renderChatButtons() {
    const {
      classes, buttonStyle, handleSubmit, isSubmitting
    } = this.props;

    if (this.isDisabled) {
      return (
        <div className={classes.buttonContainer} style={buttonStyle}>
          <Tooltip
            placement="top"
            title="Chat is not available on closed JobCasts."
          >
            <div style={{ width: "100%" }}>
              <Button
                color="blue"
                variant="primary"
                onClick={() => {}}
                disabled
                style={{ marginTop: 12, width: "100%" }}
              >
                Send Chat
              </Button>
            </div>
          </Tooltip>
          <Tooltip title="Chat is not available on closed JobCasts.">
            <Chip
              avatar={<AttachFileIcon />}
              label="Attach Document"
              className={classnames(
                classes.disabledAttachFileChip,
                "subtitle-2"
              )}
              classes={{ avatar: classes.disabledAttachFileIcon }}
            />
          </Tooltip>
        </div>
      );
    }
    return (
      <div className={classes.buttonContainer} style={buttonStyle}>
        <Button
          color="blue"
          variant="primary"
          onClick={handleSubmit}
          disabled={isSubmitting}
          style={{ marginTop: 12 }}
        >
          Send Chat
        </Button>
        {isSubmitting && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        <Chip
          avatar={<AttachFileIcon />}
          label="Attach Document"
          className={classnames(classes.attachFileChip, "subtitle-2")}
          classes={{ avatar: classes.attachFileIcon }}
          onClick={this.handleAttachFileClick}
        />
      </div>
    );
  }

  get isDisabled() {
    const {
      jobcastStatus,
      candidate: {
        attributes: { status, type },
      },
    } = this.props;

    if (jobcastStatus !== ARCHIVED) return false;

    if (type === "PermRecruiterSubmission") return status !== HIRED;

    if (type === "TempRecruiterSubmission") {
      return !includes([OFFER, CONTRACT_ACTIVE], status);
    }

    return false;
  }

  renderChatInput() {
    const {
      classes,
      values,
      errors,
      touched,
      handleChange,
      currentIndividual: { employer },
    } = this.props;

    const errorMessage = (touched.message && errors.message) || "";
    const archivedPlaceholder = employer
      ? "Chat is not available on closed JobCasts. Re-open the JobCast to access chat."
      : "Chat is not available on closed JobCasts.";
    const messagePlaceholder = employer
      ? "Message Agency..."
      : "Message Employer...";

    return (
      <div className={classes.inputContainer}>
        <MultiLineTextField
          style={{
            marginBottom: 0,
            ...(this.props.multiTextStyle || {}),
          }}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          id="message"
          onChange={handleChange}
          value={values.message}
          rows={3}
          placeholder={
            this.isDisabled ? archivedPlaceholder : messagePlaceholder
          }
          disabled={this.isDisabled}
          InputProps={{ style: { minHeight: 66 } }}
        />
        {this.renderChatButtons()}
      </div>
    );
  }

  renderChatArea() {
    const { showIntroSection, conversation } = this.props;

    return (
      <>
        {showIntroSection && this.renderIntroSection()}
        {this.renderChat()}
        {this.renderChatInput()}
        {conversation && <MessagesConsumer conversationId={conversation.id} />}
      </>
    );
  }

  render() {
    const {
      classes,
      candidate: {
        attributes: { offPlatform, temp },
      },
    } = this.props;

    return (
      <div className={classes.chatContainer}>
        {offPlatform && !temp ? <RotatingEmptyState /> : this.renderChatArea()}
      </div>
    );
  }
}

const ChatTabWithFormik = withFormik({
  handleSubmit: (
    values,
    {
      props, setSubmitting, setFieldError, resetForm
    }
  ) => {
    props
      .createMessage({ content: values.message })
      .then(() => {
        setSubmitting(false);
        resetForm();
      })
      .catch(() => {
        setSubmitting(false);
        setFieldError("message", "Internal server error");
      });
  },
  mapPropsToValues: () => ({
    message: "",
  }),
  validationSchema: object().shape({
    message: string().required("Cannot be blank"),
  }),
})(ChatTab);

const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();

  const mapStateToProps = (state, ownProps) => {
    const conversation = getCandidateConversation(state, ownProps.candidate.id);
    const currentIndividual = getCurrentIndividual(state);
    const messageIds = conversation
      ? map(conversation.attributes.messages, ({ id }) => id)
      : [];

    const unreadNotifications = getFilteredNotifications(state, {
      [UNREAD]: true,
      subjectType: "Message",
      subjectId: messageIds,
    });

    return {
      conversation,
      currentIndividual,
      unreadNotifications,
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  openModal: (content) => dispatch(openModal(content)),
  clearProfileDrawer: () => dispatch(clearProfileDrawer()),
  openProfileDrawer: (content) => dispatch(openProfileDrawer(content)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withNotificationsReader(withStyles(style)(ChatTabWithFormik)));
