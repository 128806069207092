import { withFormik } from "formik";

import map from "lodash/map";
import round from "lodash/round";
import React from "react";
import { connect } from "react-redux";

import { updateAgencyFees } from "../../../../../actions/employers/agencyActions";
import { clearModal } from "../../../../../actions/shared/uiActions";
import { makeGetAgencyOrganization } from "../../../../../selectors/employer/agencySelectors";
import { getCurrentOrganization } from "../../../../../selectors/organizationSelectors";
import { object, array } from "../../../../../util/yup";
import {
  FLAT_FEE,
  PERCENTAGE_OF_COMPENSATION,
} from "../../../../forms/formik/FormikFeeOptions/fieldHelpers";
import FormikFeeOptions from "../../../../forms/formik/FormikFeeOptions/FormikFeeOptions";
import ActionModalContainer from "../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../General/ActionModal/ActionModalHeader";
import PageCardRowHeader from "../../../../General/PageCardRowHeader";
import withSnackbar from "../../../../General/withSnackbar";

import { FeeOptionsList } from "../../../JobCastSettings/AgencyFees/sections/StandardizedFees";
import AdvancedOptionsSection from "../../TempInviteAgency/AdvancedOptions";
import PlacementTypeSelection from "../../TempInviteAgency/PlacementTypeSelection";
import EmployerAbsorptionRateField from "../../TempInviteAgency/shared/EmployerAbsorptionRateField";
import FeeType from "../AddAsLegacyModal/FormComponents/FeeType";

import {
  buildMultipleFeesValidation,
  buildMultipleTempFeesValidation
} from "./fieldHelpers";

class ChangeFeeModal extends React.PureComponent {
  state = { loading: false };

  componentDidUpdate(prevProps) {
    const prevValues = prevProps.values;
    const currValues = this.props.values;

    if (
      currValues.feeType !== prevValues.feeType ||
      currValues.fee_options.length !== prevValues.fee_options.length
    ) {
      this.props.setFieldValue(
        "multiple_fees",
        currValues.feeType === "custom" && currValues.fee_options.length > 1
      );
    }

    if (
      currValues.tempFeeType !== prevValues.tempFeeType ||
      currValues.temp_fee_options.length !== prevValues.temp_fee_options.length
    ) {
      this.props.setFieldValue(
        "temp_multiple_fees",
        currValues.tempFeeType === "custom" &&
          currValues.temp_fee_options.length > 1
      );
    }
  }

  renderTempFeeOptionsByType(type = "perm") {
    const { values, errors } = this.props;
    const temp = type === "temp";

    switch (temp ? values.tempFeeType : values.feeType) {
      case "custom":
        return (
          <div style={{ paddingBottom: 10, paddingTop: 10 }}>
            <FormikFeeOptions
              fieldArrayName={temp ? "temp_fee_options" : "fee_options"}
              formProps={{ values, errors }}
              typeLabel={temp ? "Conversion to perm rate" : "Fee type"}
              temp={temp}
              maxOptions={temp && 10}
            />
          </div>
        );
      case "standardized":
        return (
          <div style={{ paddingBottom: 10, paddingLeft: 24, paddingTop: 10 }}>
            <FeeOptionsList />
          </div>
        );
      case "optimized":
      default:
        return <></>;
    }
  }

  renderContent() {
    const { values } = this.props;

    const displayAdvancedOptions =
      values.placementTypes.temp || values.placementTypes.perm;

    return (
      <>
        <div style={{ textAlign: "left" }}>
          If you update, add, or remove a Preferred Agency fee the agency will
          be notified via email.
          <br />
          New fee(s) will be applied to all future candidate submissions. This
          will not affect any hires that have been marked previously. For more
          on processing fees
          {" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://help.recruitifi.com/en/articles/5014829-does-recruitifi-take-a-fee-from-agencies-on-hires"
          >
            click here.
          </a>
        </div>
        <div style={{ paddingTop: 20 }}>
          <div style={{ paddingLeft: 20 }}>
            <PlacementTypeSelection />
          </div>
          {this.props.values.placementTypes.perm && (
            <>
              <PageCardRowHeader title="Permanent Placement" />
              <div style={{ padding: "0px 24px" }}>
                <FeeType />
                {this.renderTempFeeOptionsByType()}
              </div>
            </>
          )}
          {this.props.values.placementTypes.temp && (
            <>
              <PageCardRowHeader title="Contract Workforce" />
              <div style={{ padding: "0px 24px" }}>
                <p>
                  Select conversion fees for contract workers who are made full
                  time. You will select the relevant fee at the time of
                  conversion.
                </p>
                <FeeType temp />
                {this.renderTempFeeOptionsByType("temp")}
              </div>
            </>
          )}
          {displayAdvancedOptions && (
            <AdvancedOptionsSection>
              <EmployerAbsorptionRateField fieldId="employerAbsorptionRate" />
            </AdvancedOptionsSection>
          )}
        </div>
      </>
    );
  }

  render() {
    const { handleSubmit, isSubmitting, agency } = this.props;
    const { organization } = agency;

    return (
      <ActionModalContainer color="blue">
        <ActionModalHeader
          title="Preferred Agency Fee Change"
          subtitle={organization.attributes.name}
        />
        <ActionModalContent>
          {this.renderContent()}
        </ActionModalContent>
        <ActionModalFooter
          actionText="Update Fee"
          handleAction={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </ActionModalContainer>
    );
  }
}

const ChangeFeeContractModalForm = withFormik({
  handleSubmit: (values, { props, setSubmitting }) => {
    const { clearModal, agency, updateAgencyFees } = props;

    const mapFeeOptionsValues = (feeOptions) =>
      map(feeOptions, (feeOption) => ({
        fee_percentage:
          feeOption.fee_type === PERCENTAGE_OF_COMPENSATION
            ? round(parseFloat(feeOption.fee) / 100, 4)
            : null,
        fee_cents:
          feeOption.fee_type === FLAT_FEE
            ? parseFloat(feeOption.fee.replace(/,/g, "")) * 100
            : null,
        note: feeOption.fee_note,
        id: feeOption.id,
        fee_type: feeOption.fee_type,
        currency: feeOption.currency,
      }));

    const fee_updates = {
      id: agency.preferredLink.id,
      fee_options: mapFeeOptionsValues(values.fee_options),
      fee_type: values.placementTypes.perm ? values.feeType : null,
      temp_fee_options: mapFeeOptionsValues(values.temp_fee_options),
      temp_fee_type: values.placementTypes.temp ? values.tempFeeType : null,
      legacy_invitation_id: agency.invitation && agency.invitation.id,
      employer_absorption_rate: values.employerAbsorptionRate,
    };

    updateAgencyFees(fee_updates)
      .then(() => {
        setSubmitting(false);
        clearModal();
        fee_updates.fee_options.length > 1
          ? props.snackbar.showMessage("Fees successfully updated")
          : props.snackbar.showMessage("Fee successfully updated");
      })
      .catch(() => {
        setSubmitting(false);
      });
  },
  mapPropsToValues: (props) => {
    const mapFeeOptions = (feeOptions) =>
      map(feeOptions, (feeOption) => ({
        fee:
          feeOption.feeType === PERCENTAGE_OF_COMPENSATION
            ? round(feeOption.feePercentage * 100, 2).toString()
            : (feeOption.feeCents / 100).toString(),
        fee_note: feeOption.note,
        id: feeOption.id,
        fee_type: feeOption.feeType,
        currency: feeOption.currency,
      }));

    const determineFeeType = (scope) => {
      if (
        scope === "perm" &&
        props.agency.preferredLink.attributes.optimizedFee
      ) {
        return "optimized";
      }
      if (
        scope === "temp" &&
        (props.agency.preferredLink.attributes.tempOptimizedFee ||
          !props.agency.preferredLink.attributes.tempEnabled)
      ) {
        return "optimized";
      }
      if (props.currentOrganization.hasStandardizedPreferredFees) {
        return "standardized";
      }

      return "custom";
    };

    const feeType = determineFeeType("perm");
    const tempFeeType = determineFeeType("temp");
    const feeOptions = mapFeeOptions(
      props.agency.preferredLink.attributes.feeOptions
    );
    const tempFeeOptions = mapFeeOptions(
      props.agency.preferredLink.attributes.tempFeeOptions
    );

    const blankFeeOption = {
      fee: "",
      fee_note: "",
      fee_type: PERCENTAGE_OF_COMPENSATION,
      currency: null,
    };

    if (feeOptions.length === 0) feeOptions.push(blankFeeOption);
    if (tempFeeOptions.length === 0) tempFeeOptions.push(blankFeeOption);

    const createBlankPlacementScope = () => ({
      feeType: "optimized",
      feeOptions: [],
      multipleFees: false,
      positionAssignments: [],
    });

    const { employerAbsorptionRate } = props.agency.preferredLink.attributes;

    return {
      placementTypes: {
        perm: !!props.currentOrganization.contractActivated,
        temp: !!props.agency.preferredLink.attributes.tempEnabled,
      },
      perm: createBlankPlacementScope(),
      temp: createBlankPlacementScope(),
      fee_options: feeOptions,
      feeType,
      multiple_fees: feeType === "custom" && feeOptions.length > 1,
      temp_fee_options: tempFeeOptions,
      tempFeeType,
      temp_multiple_fees: tempFeeType === "custom" && tempFeeOptions.length > 1,
      employerAbsorptionRate: parseFloat(employerAbsorptionRate),
    };
  },
  validationSchema: object().shape({
    fee_options: array().when("feeType", {
      is: "custom",
      then: array().when("multiple_fees", buildMultipleFeesValidation()),
    }),
    temp_fee_options: array()
      .when("tempFeeType", {
        is: "custom",
        then: array().when("temp_multiple_fees", buildMultipleTempFeesValidation()),
      })
      .nullable(),
  }),
})(ChangeFeeModal);

const makeMapStateToProps = () => {
  const getAgencyOrganization = makeGetAgencyOrganization();

  return (state, { organizationId }) => ({
    agency: getAgencyOrganization(state, organizationId),
    currentOrganization: getCurrentOrganization(state),
  });
};

const mapDispatchToProps = (dispatch) => ({
  clearModal: () => dispatch(clearModal()),
  updateAgencyFees: (fees) => dispatch(updateAgencyFees(fees)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withSnackbar()(ChangeFeeContractModalForm));
