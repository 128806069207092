import axios from "axios";

const generateHeader = () => {
  const token = global.localStorage.authToken;
  const header = {
    Authentication: token ? `Bearer ${token}` : undefined,
  };

  if (token && global.localStorage.viewingAs) {
    header.ViewingAs = JSON.parse(global.localStorage.viewingAs).id;
  }

  if (token && global.localStorage.ghosting) {
    header.ghosting = global.localStorage.ghosting;
  }

  return header;
};

export const GET = (url) => axios({
  method: "GET",
  headers: generateHeader(),
  url,
});

export const POST = (url, data) => axios({
  method: "POST",
  headers: generateHeader(),
  url,
  data,
});

export const PATCH = (url, data) => axios({
  method: "PATCH",
  headers: generateHeader(),
  url,
  data,
});

export const DELETE = (url) => axios({
  method: "DELETE",
  headers: generateHeader(),
  url,
});
