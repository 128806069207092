import merge from "lodash/merge";

import {
  RECEIVE_PUBLIC_RECRUITER_SUBMISSION,
  UPDATE_PUBLIC_RECRUITER_SUBMISSION,
  RECEIVE_RECRUITER_SUBMISSION_ERRORS
} from "../actions/public/recruiterSubmissionActions";

const _defaultState = { items: {}, loaded: false };

export const recruiterSubmissionReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_PUBLIC_RECRUITER_SUBMISSION:
      return { loaded: true, publicSubmission: action.payload };
    case UPDATE_PUBLIC_RECRUITER_SUBMISSION:
      return merge({}, state, { publicSubmission: action.payload });
    case RECEIVE_RECRUITER_SUBMISSION_ERRORS:
      return { ...state, loaded: true, errors: action.payload };
    default:
      return state;
  }
};
