import { Grid, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';

import { closeContract as employerCloseContract } from '../../../actions/employers/contractActions';
import { fetchJobCast } from '../../../actions/employers/jobcastActions';
import { closeContract as recruiterCloseContract } from '../../../actions/recruiters/contractActions';
import { clearModal } from '../../../actions/shared/uiActions';
import { getCurrentIndividual } from '../../../selectors/individualSelectors';
import { disabledProps } from '../../../util/formHelpers';
import FieldLabel from '../../employer/JobCasts/view/Description/sections/FieldLabel';
import FormContextDatePicker from '../../forms/formik/FormContextInputs/FormContextDatePicker';
import FormContextTextField from '../../forms/formik/FormContextInputs/FormContextTextField';
import ActionModalContainer from '../../General/ActionModal/ActionModalContainer';
import ActionModalContent from '../../General/ActionModal/ActionModalContent';
import ActionModalFooter from '../../General/ActionModal/ActionModalFooter';
import ActionModalHeader from '../../General/ActionModal/ActionModalHeader';
import useSnackbar from '../../General/useSnackbar';

const validationSchema = object().shape({
  last_day: string().nullable().required("Cannot be blank"),
  reason: string().required("Cannot be blank"),
});

function CloseContractModal({ candidate }) {
  const currentIndividual = useSelector((state) => getCurrentIndividual(state));

  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const closeContract = currentIndividual.type === "employer" ? employerCloseContract : recruiterCloseContract;

  const onSubmit = (values) => {
    dispatch(closeContract(candidate.id, values))
      .then(() => {
        dispatch(clearModal());
        dispatch(fetchJobCast(candidate.attributes.jobcastId));
        snackbar.showMessage("Contract Ended");
      });
  };

  return (
    <Formik
      initialValues={{ last_day: null, reason: "" }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, ...props }) => (
        <ActionModalContainer color="red">
          <ActionModalHeader
            title="Close Contract"
            subtitle={`${candidate.attributes.firstName} ${candidate.attributes.lastName}`}
          />
          <ActionModalContent>
            <p style={{ margin: "0 0 24px 0" }}>
              Complete this form to end this open contract. This action will alert the agency,
              but not the worker. Worker hours, up to and including the provided last day,
              will be editable until the end of this week's pay period.
            </p>
            <div style={{ width: "100%", overflow: 'hidden' }}>
              <Grid container spacing={6}>
                <Grid item xs={3}>
                  <TextField
                    {...disabledProps(true)}
                    label={<FieldLabel name="Worker Name" />}
                    value={`${candidate.attributes.firstName} ${candidate.attributes.lastName}`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormContextDatePicker
                    id="last_day"
                    label="Last Day of Work"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormContextTextField
                    id="reason"
                    label="Reason For Contract End"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </div>
          </ActionModalContent>
          <ActionModalFooter
            actionText="End Contract"
            handleAction={() => {
              props.setFieldTouched("reason", true);
              props.setFieldTouched("last_day", true);
              handleSubmit();
            }}
            isSubmitting={props.isSubmitting}
          />
        </ActionModalContainer>
      )}
    </Formik>
  );
}

export default CloseContractModal;
