import { TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import React from "react";

import FormContextDatePicker from "../../../../forms/formik/FormContextInputs/FormContextDatePicker";

import ConfirmationCellContent from "./ConfirmationCellContent";
import NoActionNeeded from "./NoActionNeeded";

function DateFieldRow({ checklistItem, editable, fieldId }) {
  return (
    <TableRow data-cy="checklist-item-date-row">
      <TableCell>
        <div>
          <div className="bold">
            {checklistItem.name}
          </div>
          <div>
            {moment(checklistItem.requestedDate).format("MMM Do, YYYY")}
          </div>
        </div>
      </TableCell>
      <TableCell align="center">
        <NoActionNeeded {...{ checklistItem }}>
          {editable ? (
            <FormContextDatePicker
              id={`${fieldId}.submittedDate`}
              style={{ marginBottom: 0, width: "75%" }}
            />
          ) : (
            checklistItem.submittedDate &&
          moment(checklistItem.submittedDate).format("MMM Do, YYYY")
          )}
        </NoActionNeeded>
      </TableCell>
      <TableCell>
        <ConfirmationCellContent {...{ checklistItem }} />
      </TableCell>
    </TableRow>
  );
}

export default DateFieldRow;
