import {
  object, string, array
} from "../../../../../util/yup";
import {
  FLAT_FEE,
  PERCENTAGE_OF_COMPENSATION,
} from "../../../../forms/formik/FormikFeeOptions/fieldHelpers";

export const buildMultipleTempFeesValidation = () => ({
  is: true,
  then: array().of(
    object().shape({
      fee_type: string(),
      fee: string().when("fee_type", {
        is: PERCENTAGE_OF_COMPENSATION,
        then: string()
          .validFeePercentage()
          .required("Cannot be blank")
          .test(
            "minValPercent",
            (val) => parseFloat(val) >= 0
          )
          .test(
            "maxValPercent",
            "Must be less than 100",
            (val) => parseFloat(val) < 100
          ),
        otherwise: string()
          .required("Cannot be blank")
          .test(
            "minValPercent",
            "Must be 0 or greater",
            (val) => parseFloat(val) >= 0
          ),
      }),
      fee_note: string().required("Cannot be blank"),
      currency: string().when("fee_type", {
        is: FLAT_FEE,
        then: string().required("Cannot be blank").nullable(),
        otherwise: string().nullable(),
      }),
    })
  ),
  otherwise: array().of(
    object().shape({
      fee: string().when("fee_type", {
        is: PERCENTAGE_OF_COMPENSATION,
        then: string()
          .validFeePercentage()
          .required("Cannot be blank")
          .test(
            "minValPercent",
            (val) => parseFloat(val) >= 0
          )
          .test(
            "maxValPercent",
            "Must be less than 100",
            (val) => parseFloat(val) < 100
          ),
        otherwise: string()
          .required("Cannot be blank")
          .test(
            "minValPercent",
            "Must be 0 or greater",
            (val) => parseFloat(val) >= 0
          )
      }),
      currency: string().when("fee_type", {
        is: FLAT_FEE,
        then: string().required("Cannot be blank").nullable(),
        otherwise: string().nullable(),
      }),
    })
  ).nullable(),
});

export const buildMultipleFeesValidation = () => ({
  is: true,
  then: array().of(
    object().shape({
      fee_type: string(),
      fee: string().when("fee_type", {
        is: PERCENTAGE_OF_COMPENSATION,
        then: string()
          .validFeePercentage()
          .test(
            "minValPercent",
            "Must be greater than 1%",
            (val) => parseFloat(val) > 1
          )
          .test(
            "maxValPercent",
            "Must be less than 100",
            (val) => parseFloat(val) < 100
          )
          .required("Cannot be blank"),
        otherwise: string().test(
          "minValPercent",
          "Must be greater than 1%",
          (val) => parseFloat(val) > 1
        ),
      }),
      fee_note: string().required("Cannot be blank"),
      currency: string().when("fee_type", {
        is: FLAT_FEE,
        then: string().required("Cannot be blank").nullable(),
        otherwise: string().nullable(),
      }),
    })
  ),
  otherwise: array().of(
    object().shape({
      fee: string().when("fee_type", {
        is: PERCENTAGE_OF_COMPENSATION,
        then: string()
          .validFeePercentage()
          .test(
            "minValPercent",
            "Must be greater than 1%",
            (val) => parseFloat(val) > 1
          )
          .test(
            "maxValPercent",
            "Must be less than 100",
            (val) => parseFloat(val) < 100
          )
          .required("Cannot be blank"),
        otherwise: string()
          .test(
            "minValPercent",
            "Must be greater than 1%",
            (val) => parseFloat(val) > 1
          )
          .required("Cannot be blank"),
      }),
      currency: string().when("fee_type", {
        is: FLAT_FEE,
        then: string().required("Cannot be blank").nullable(),
        otherwise: string().nullable(),
      }),
    })
  ).nullable(),
});
