export default {
  benefits: [
    { db_name: "Dental Ins.", label: "Dental Insurance" },
    { db_name: "Medical Ins.", label: "Medical Insurance" },
    { db_name: "Vision Ins.", label: "Vision Insurance" },
    { db_name: "Life Ins.", label: "Life Insurance" },
    { db_name: "Retirement", label: "Retirement" },
    { db_name: "Equity", label: "Equity" },
    { db_name: "Work From Home", label: "Work From Home" },
    { db_name: "Paid Time Off", label: "Paid Time Off" },
    { db_name: "Other", label: "Other" },
  ],
  travelRequiredOptions: [
    "Travel not required",
    "Up to 20% of the time",
    "Up to 40% of the time",
    "Up to 60% of the time",
    "Up to 80% of the time",
    "Up to 100% of the time",
  ],
  remoteWorkOptions: [
    "Remote work not available",
    "Up to 20% of the time",
    "Up to 40% of the time",
    "Up to 60% of the time",
    "Up to 80% of the time",
    "Up to 100% of the time",
  ],
  visaSupportOptions: [
    "No visa sponsorships supported",
    "All visa sponsorships supported",
    "Some visa sponsorships supported",
  ],
  levelOptions: ["Junior", "Mid-Level", "Senior", "Executive"],
  vacantSinceOptions: [
    "This is a newly opened position",
    "Less than 30 days",
    "More than 30 days",
    "This position is always open",
  ],
  vacanciesOptions: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  rolesOptions: [
    "Civic & Education",
    "Consulting",
    "Finance",
    "Health Care",
    "Legal",
    "Marketing & Creative",
    "Manufacturing & Labor",
    "Operations & HR",
    "Sales",
    "Science & Engineering",
    "Service & Hospitality",
    "Technology & Software",
  ],
  signingBonusOptions: ["Yes", "No", "Negotiable"],
  relocationOptions: ["None", "Partial", "Full"],
  contractToHireOptions: ["No", "Undecided", "Yes"],
  employer_email_settings: [
    {
      value: "individual_new_chat_message_updates",
      label: "Chat",
      immediate: { available: true, fixed: false },
      daily: { available: true, fixed: false },
    },
    {
      value: "employer_new_candidate_updates",
      label: "Candidates Awaiting Sort",
      immediate: { available: true, fixed: false },
      daily: { available: true, fixed: true },
    },
    {
      value: "employer_jobcast_closing_soon_updates",
      label: "JobCast Closing Soon",
      immediate: { available: false, fixed: true },
      daily: { available: true, fixed: false },
    },
    {
      value: "employer_asked_question_updates",
      label: "Recruiter Question Asked",
      immediate: { available: true, fixed: false },
      daily: { available: true, fixed: true },
    },
  ],
  minor_industries: {
    1: "Accounting",
    2: "Airlines/Aviation",
    3: "Alternative Dispute Resolution",
    4: "Alternative Medicine",
    5: "Animation",
    6: "Apparel/Fashion",
    7: "Architecture/Planning",
    8: "Arts/Crafts",
    9: "Automotive",
    10: "Aviation/Aerospace",
    11: "Banking",
    12: "Biotechnology",
    13: "Broadcast Media",
    14: "Building Materials",
    15: "Business Supplies/Equipment",
    16: "Capital Markets",
    17: "Chemicals",
    18: "Civic/Social Organization",
    19: "Civil Engineering",
    20: "Commercial Real Estate",
    21: "Computer Games",
    22: "Computer Hardware",
    23: "Computer Networking",
    24: "Computer Software",
    25: "Computer/Network Security",
    26: "Construction",
    27: "Consumer Electronics",
    28: "Consumer Goods",
    29: "Consumer Services",
    30: "Cosmetics",
    31: "Dairy",
    32: "Defense/Space",
    33: "Design",
    34: "E-Learning",
    35: "Education Management",
    36: "Electrical/Electronic Manufacturing",
    37: "Entertainment",
    38: "Environmental Services",
    39: "Events Services",
    40: "Executive Office",
    41: "Facilities Services",
    42: "Farming",
    43: "Financial Services",
    44: "Fine Art",
    45: "Fishery",
    46: "Food Production",
    47: "Food/Beverages",
    48: "Fund-Raising",
    49: "Furniture",
    50: "Gambling/Casinos",
    51: "Glass/Ceramics/Concrete",
    52: "Government Administration",
    53: "Government Relations",
    54: "Graphic Design",
    55: "Health/Wellness/Fitness",
    56: "Higher Education",
    57: "Hospital/Health Care",
    58: "Hospitality",
    59: "Human Resources",
    60: "Import/Export",
    61: "Individual/Family Services",
    62: "Industrial Automation",
    63: "Information Services",
    64: "Information Technology/Services",
    65: "Insurance",
    66: "International Affairs",
    67: "International Trade/Development",
    68: "Internet",
    69: "Investment Banking",
    70: "Investment Management",
    71: "Judiciary",
    72: "Law Enforcement",
    73: "Law Practice",
    74: "Legal Services",
    75: "Legislative Office",
    76: "Leisure/Travel/Tourism",
    77: "Libraries",
    78: "Logistics/Supply Chain",
    79: "Luxury Goods/Jewelry",
    80: "Machinery",
    81: "Management Consulting",
    82: "Maritime",
    83: "Market Research",
    84: "Marketing/Advertising",
    85: "Mechanical/Industrial Engineering",
    86: "Media Production",
    87: "Medical Devices",
    88: "Medical Practice",
    89: "Mental Health Care",
    90: "Military",
    91: "Mining/Metals",
    92: "Motion Pictures/Film",
    93: "Museums/Institutions",
    94: "Music",
    95: "Nanotechnology",
    96: "Newspapers",
    97: "Non-Profit Organization Management",
    98: "Oil/Energy",
    99: "Online Media",
    100: "Outsourcing/Offshoring",
    101: "Package/Freight Delivery",
    102: "Packaging/Containers",
    103: "Paper/Forest Products",
    104: "Performing Arts",
    105: "Pharmaceuticals",
    106: "Philanthropy",
    107: "Photography",
    108: "Plastics",
    109: "Political Organization",
    110: "Primary/Secondary Education",
    111: "Printing",
    112: "Professional Training/Coaching",
    113: "Program Development",
    114: "Public Policy",
    115: "Public Relations/Communications",
    116: "Public Safety",
    117: "Publishing",
    118: "Railroad Manufacture",
    119: "Ranching",
    120: "Real Estate",
    121: "Recreational Facilities/Services",
    122: "Religious Institutions",
    123: "Renewables/Environment",
    124: "Research",
    125: "Restaurants",
    126: "Retail",
    127: "Security/Investigations",
    128: "Semiconductors",
    129: "Shipbuilding",
    130: "Sporting Goods",
    131: "Sports",
    132: "Staffing/Recruiting",
    133: "Supermarkets",
    134: "Telecommunications",
    135: "Textiles",
    136: "Think Tanks",
    137: "Tobacco",
    138: "Translation/Localization",
    139: "Transportation/Trucking/Railroad",
    140: "Utilities",
    141: "Venture Capital/Private Equity",
    142: "Veterinary",
    143: "Warehousing",
    144: "Wholesale",
    145: "Wine/Spirits",
    146: "Wireless",
    147: "Writing/Editing",
  },
  certificationNameOptions: [
    "Small Business",
    "Small Disadvantaged Business",
    "Self-Certified Small Disadvantaged Business",
    "Historically Underutilized Business Zone Business",
    "Veteran-Owned Business Enterprise",
    "Disabled Veteran-Owned Business Enterprise",
    "Service-Disabled Veteran-Owned Business Enterprise",
    "Veteran-Owned Small Business",
    "Disabled Veteran-Owned Small Business",
    "Service-Disabled Veteran-Owned Small Business",
    "Women Business Enterprise",
    "Women-Owned Small Business",
    "Economically Disadvantaged Women-Owned Small Business",
    "Minority Business Enterprise",
    "Disability Owned Business",
    "LGBT Business Enterprise",
    "Disadvantaged Business",
  ],
  countries: {
    ABW: { name: "Aruba", alpha2: "AW", number: "297" },
    AFG: { name: "Afghanistan", alpha2: "AF", number: "93" },
    AGO: { name: "Angola", alpha2: "AO", number: "244" },
    AIA: { name: "Anguilla", alpha2: "AI", number: "1" },
    ALA: { name: "Åland Islands", alpha2: "AX", number: "358" },
    ALB: { name: "Albania", alpha2: "AL", number: "355" },
    AND: { name: "Andorra", alpha2: "AD", number: "376" },
    ANT: { name: "Netherlands Antilles", alpha2: "AN", number: "599" },
    ARE: { name: "United Arab Emirates", alpha2: "AE", number: "971" },
    ARG: { name: "Argentina", alpha2: "AR", number: "54" },
    ARM: { name: "Armenia", alpha2: "AM", number: "374" },
    ASM: { name: "American Samoa", alpha2: "AS", number: "1" },
    ATA: { name: "Antarctica", alpha2: "AQ", number: "672" },
    ATF: { name: "French Southern Territories", alpha2: "TF", number: "262" },
    ATG: { name: "Antigua and Barbuda", alpha2: "AG", number: "1" },
    AUS: { name: "Australia", alpha2: "AU", number: "61" },
    AUT: { name: "Austria", alpha2: "AT", number: "43" },
    AZE: { name: "Azerbaijan", alpha2: "AZ", number: "994" },
    BDI: { name: "Burundi", alpha2: "BI", number: "257" },
    BEL: { name: "Belgium", alpha2: "BE", number: "32" },
    BEN: { name: "Benin", alpha2: "BJ", number: "229" },
    BES: {
      name: "Bonaire, Sint Eustatius and Saba",
      alpha2: "BQ",
      number: "599",
    },
    BFA: { name: "Burkina Faso", alpha2: "BF", number: "226" },
    BGD: { name: "Bangladesh", alpha2: "BD", number: "880" },
    BGR: { name: "Bulgaria", alpha2: "BG", number: "359" },
    BHR: { name: "Bahrain", alpha2: "BH", number: "973" },
    BHS: { name: "Bahamas", alpha2: "BS", number: "1" },
    BIH: { name: "Bosnia and Herzegovina", alpha2: "BA", number: "387" },
    BLM: { name: "Saint Barthélemy", alpha2: "BL", number: "590" },
    BLR: { name: "Belarus", alpha2: "BY", number: "375" },
    BLZ: { name: "Belize", alpha2: "BZ", number: "501" },
    BMU: { name: "Bermuda", alpha2: "BM", number: "1" },
    BOL: {
      name: "Bolivia (Plurinational State of)",
      alpha2: "BO",
      number: "591",
    },
    BRA: { name: "Brazil", alpha2: "BR", number: "55" },
    BRB: { name: "Barbados", alpha2: "BB", number: "1" },
    BRN: { name: "Brunei Darussalam", alpha2: "BN", number: "673" },
    BTN: { name: "Bhutan", alpha2: "BT", number: "975" },
    BVT: { name: "Bouvet Island", alpha2: "BV", number: "47" },
    BWA: { name: "Botswana", alpha2: "BW", number: "267" },
    CAF: { name: "Central African Republic", alpha2: "CF", number: "236" },
    CAN: { name: "Canada", alpha2: "CA", number: "1" },
    CCK: { name: "Cocos (Keeling) Islands", alpha2: "CC", number: "61" },
    CHE: { name: "Switzerland", alpha2: "CH", number: "41" },
    CHL: { name: "Chile", alpha2: "CL", number: "56" },
    CHN: { name: "China", alpha2: "CN", number: "86" },
    CIV: { name: "Côte d'Ivoire", alpha2: "CI", number: "225" },
    CMR: { name: "Cameroon", alpha2: "CM", number: "237" },
    COD: { name: "Congo (Dem. Rep.)", alpha2: "CD", number: "243" },
    COG: { name: "Congo", alpha2: "CG", number: "242" },
    COK: { name: "Cook Islands", alpha2: "CK", number: "682" },
    COL: { name: "Colombia", alpha2: "CO", number: "57" },
    COM: { name: "Comoros", alpha2: "KM", number: "269" },
    CPV: { name: "Cabo Verde", alpha2: "CV", number: "238" },
    CRI: { name: "Costa Rica", alpha2: "CR", number: "506" },
    CUB: { name: "Cuba", alpha2: "CU", number: "53" },
    CUW: { name: "Curaçao", alpha2: "CW", number: "599" },
    CXR: { name: "Christmas Island", alpha2: "CX", number: "61" },
    CYM: { name: "Cayman Islands", alpha2: "KY", number: "1" },
    CYP: { name: "Cyprus", alpha2: "CY", number: "357" },
    CZE: { name: "Czech Republic", alpha2: "CZ", number: "420" },
    DEU: { name: "Germany", alpha2: "DE", number: "49" },
    DJI: { name: "Djibouti", alpha2: "DJ", number: "253" },
    DMA: { name: "Dominica", alpha2: "DM", number: "1" },
    DNK: { name: "Denmark", alpha2: "DK", number: "45" },
    DOM: { name: "Dominican Republic", alpha2: "DO", number: "1" },
    DZA: { name: "Algeria", alpha2: "DZ", number: "213" },
    ECU: { name: "Ecuador", alpha2: "EC", number: "593" },
    EGY: { name: "Egypt", alpha2: "EG", number: "20" },
    ERI: { name: "Eritrea", alpha2: "ER", number: "291" },
    ESH: { name: "Western Sahara", alpha2: "EH", number: "212" },
    ESP: { name: "Spain", alpha2: "ES", number: "34" },
    EST: { name: "Estonia", alpha2: "EE", number: "372" },
    ETH: { name: "Ethiopia", alpha2: "ET", number: "251" },
    FIN: { name: "Finland", alpha2: "FI", number: "358" },
    FJI: { name: "Fiji", alpha2: "FJ", number: "679" },
    FLK: { name: "Falkland Islands", alpha2: "FK", number: "500" },
    FRA: { name: "France", alpha2: "FR", number: "33" },
    FRO: { name: "Faroe Islands", alpha2: "FO", number: "298" },
    FSM: {
      name: "Micronesia (Federated States of)",
      alpha2: "FM",
      number: "691",
    },
    GAB: { name: "Gabon", alpha2: "GA", number: "241" },
    GBR: { name: "United Kingdom", alpha2: "GB", number: "44" },
    GEO: { name: "Georgia", alpha2: "GE", number: "995" },
    GGY: { name: "Guernsey and Alderney", alpha2: "GG", number: "44" },
    GHA: { name: "Ghana", alpha2: "GH", number: "233" },
    GIB: { name: "Gibraltar", alpha2: "GI", number: "350" },
    GIN: { name: "Guinea", alpha2: "GN", number: "224" },
    GLP: { name: "Guadeloupe", alpha2: "GP", number: "590" },
    GMB: { name: "Gambia", alpha2: "GM", number: "220" },
    GNB: { name: "Guinea-Bissau", alpha2: "GW", number: "245" },
    GNQ: { name: "Equatorial Guinea", alpha2: "GQ", number: "240" },
    GRC: { name: "Greece", alpha2: "GR", number: "30" },
    GRD: { name: "Grenada", alpha2: "GD", number: "1" },
    GRL: { name: "Greenland", alpha2: "GL", number: "299" },
    GTM: { name: "Guatemala", alpha2: "GT", number: "502" },
    GUF: { name: "French Guiana", alpha2: "GF", number: "594" },
    GUM: { name: "Guam", alpha2: "GU", number: "1" },
    GUY: { name: "Guyana", alpha2: "GY", number: "592" },
    HKG: { name: "Hong Kong", alpha2: "HK", number: "852" },
    HMD: {
      name: "Heard Island and McDonald Islands",
      alpha2: "HM",
      number: "",
    },
    HND: { name: "Honduras", alpha2: "HN", number: "504" },
    HRV: { name: "Croatia", alpha2: "HR", number: "385" },
    HTI: { name: "Haiti", alpha2: "HT", number: "509" },
    HUN: { name: "Hungary", alpha2: "HU", number: "36" },
    IDN: { name: "Indonesia", alpha2: "ID", number: "62" },
    IMN: { name: "Isle of Man", alpha2: "IM", number: "44" },
    IND: { name: "India", alpha2: "IN", number: "91" },
    IOT: {
      name: "British Indian Ocean Territory",
      alpha2: "IO",
      number: "246",
    },
    IRL: { name: "Ireland", alpha2: "IE", number: "353" },
    IRN: { name: "Iran (Islamic Republic of)", alpha2: "IR", number: "98" },
    IRQ: { name: "Iraq", alpha2: "IQ", number: "964" },
    ISL: { name: "Iceland", alpha2: "IS", number: "354" },
    ISR: { name: "Israel", alpha2: "IL", number: "972" },
    ITA: { name: "Italy", alpha2: "IT", number: "39" },
    JAM: { name: "Jamaica", alpha2: "JM", number: "1" },
    JEY: { name: "Jersey", alpha2: "JE", number: "44" },
    JOR: { name: "Jordan", alpha2: "JO", number: "962" },
    JPN: { name: "Japan", alpha2: "JP", number: "81" },
    KAZ: { name: "Kazakhstan", alpha2: "KZ", number: "7" },
    KEN: { name: "Kenya", alpha2: "KE", number: "254" },
    KGZ: { name: "Kyrgyzstan", alpha2: "KG", number: "996" },
    KHM: { name: "Cambodia", alpha2: "KH", number: "855" },
    KIR: { name: "Kiribati", alpha2: "KI", number: "686" },
    KNA: { name: "Saint Kitts and Nevis", alpha2: "KN", number: "1" },
    KOR: { name: "Korea, Republic of", alpha2: "KR", number: "82" },
    KWT: { name: "Kuwait", alpha2: "KW", number: "965" },
    LAO: {
      name: "Lao People's Democratic Republic",
      alpha2: "LA",
      number: "856",
    },
    LBN: { name: "Lebanon", alpha2: "LB", number: "961" },
    LBR: { name: "Liberia", alpha2: "LR", number: "231" },
    LBY: { name: "Libya", alpha2: "LY", number: "218" },
    LCA: { name: "Saint Lucia", alpha2: "LC", number: "1" },
    LIE: { name: "Liechtenstein", alpha2: "LI", number: "423" },
    LKA: { name: "Sri Lanka", alpha2: "LK", number: "94" },
    LSO: { name: "Lesotho", alpha2: "LS", number: "266" },
    LTU: { name: "Lithuania", alpha2: "LT", number: "370" },
    LUX: { name: "Luxembourg", alpha2: "LU", number: "352" },
    LVA: { name: "Latvia", alpha2: "LV", number: "371" },
    MAC: { name: "Macau", alpha2: "MO", number: "853" },
    MAF: { name: "Saint Martin", alpha2: "MF", number: "590" },
    MAR: { name: "Morocco", alpha2: "MA", number: "212" },
    MCO: { name: "Monaco", alpha2: "MC", number: "377" },
    MDA: { name: "Moldova (Republic of)", alpha2: "MD", number: "373" },
    MDG: { name: "Madagascar", alpha2: "MG", number: "261" },
    MDV: { name: "Maldives", alpha2: "MV", number: "960" },
    MEX: { name: "Mexico", alpha2: "MX", number: "52" },
    MHL: { name: "Marshall Islands", alpha2: "MH", number: "692" },
    MKD: { name: "Macedonia", alpha2: "MK", number: "389" },
    MLI: { name: "Mali", alpha2: "ML", number: "223" },
    MLT: { name: "Malta", alpha2: "MT", number: "356" },
    MMR: { name: "Myanmar", alpha2: "MM", number: "95" },
    MNE: { name: "Montenegro", alpha2: "ME", number: "382" },
    MNG: { name: "Mongolia", alpha2: "MN", number: "976" },
    MNP: { name: "Northern Mariana Islands", alpha2: "MP", number: "1" },
    MOZ: { name: "Mozambique", alpha2: "MZ", number: "258" },
    MRT: { name: "Mauritania", alpha2: "MR", number: "222" },
    MSR: { name: "Montserrat", alpha2: "MS", number: "1" },
    MTQ: { name: "Martinique", alpha2: "MQ", number: "596" },
    MUS: { name: "Mauritius", alpha2: "MU", number: "230" },
    MWI: { name: "Malawi", alpha2: "MW", number: "265" },
    MYS: { name: "Malaysia", alpha2: "MY", number: "60" },
    MYT: { name: "Mayotte", alpha2: "YT", number: "262" },
    NAM: { name: "Namibia", alpha2: "NA", number: "264" },
    NCL: { name: "New Caledonia", alpha2: "NC", number: "687" },
    NER: { name: "Niger", alpha2: "NE", number: "227" },
    NFK: { name: "Norfolk Island", alpha2: "NF", number: "672" },
    NGA: { name: "Nigeria", alpha2: "NG", number: "234" },
    NIC: { name: "Nicaragua", alpha2: "NI", number: "505" },
    NIU: { name: "Niue", alpha2: "NU", number: "683" },
    NLD: { name: "Netherlands", alpha2: "NL", number: "31" },
    NOR: { name: "Norway", alpha2: "NO", number: "47" },
    NPL: { name: "Nepal", alpha2: "NP", number: "977" },
    NRU: { name: "Nauru", alpha2: "NR", number: "674" },
    NZL: { name: "New Zealand", alpha2: "NZ", number: "64" },
    OMN: { name: "Oman", alpha2: "OM", number: "968" },
    PAK: { name: "Pakistan", alpha2: "PK", number: "92" },
    PAN: { name: "Panama", alpha2: "PA", number: "507" },
    PCN: { name: "Pitcairn", alpha2: "PN", number: "64" },
    PER: { name: "Peru", alpha2: "PE", number: "51" },
    PHL: { name: "Philippines", alpha2: "PH", number: "63" },
    PLW: { name: "Palau", alpha2: "PW", number: "680" },
    PNG: { name: "Papua New Guinea", alpha2: "PG", number: "675" },
    POL: { name: "Poland", alpha2: "PL", number: "48" },
    PRI: { name: "Puerto Rico", alpha2: "PR", number: "1" },
    PRK: {
      name: "Korea (Democratic People's Republic of)",
      alpha2: "KP",
      number: "850",
    },
    PRT: { name: "Portugal", alpha2: "PT", number: "351" },
    PRY: { name: "Paraguay", alpha2: "PY", number: "595" },
    PSE: { name: "Palestine", alpha2: "PS", number: "970" },
    PYF: { name: "French Polynesia", alpha2: "PF", number: "689" },
    QAT: { name: "Qatar", alpha2: "QA", number: "974" },
    REU: { name: "Réunion", alpha2: "RE", number: "262" },
    ROU: { name: "Romania", alpha2: "RO", number: "40" },
    //  RUS: { name: "Russian Federation", alpha2: "RU", number: "7"},
    RWA: { name: "Rwanda", alpha2: "RW", number: "250" },
    SAU: { name: "Saudi Arabia", alpha2: "SA", number: "966" },
    SDN: { name: "Sudan", alpha2: "SD", number: "249" },
    SEN: { name: "Senegal", alpha2: "SN", number: "221" },
    SGP: { name: "Singapore", alpha2: "SG", number: "65" },
    SGS: {
      name: "South Georgia and the South Sandwich Islands",
      alpha2: "GS",
      number: "500",
    },
    SHN: { name: "Saint Helena", alpha2: "SH", number: "290" },
    SJM: { name: "Svalbard and Jan Mayen", alpha2: "SJ", number: "47" },
    SLB: { name: "Solomon Islands", alpha2: "SB", number: "677" },
    SLE: { name: "Sierra Leone", alpha2: "SL", number: "232" },
    SLV: { name: "El Salvador", alpha2: "SV", number: "503" },
    SMR: { name: "San Marino", alpha2: "SM", number: "378" },
    SOM: { name: "Somalia", alpha2: "SO", number: "252" },
    SPM: { name: "Saint Pierre and Miquelon", alpha2: "PM", number: "508" },
    SRB: { name: "Serbia", alpha2: "RS", number: "381" },
    SSD: { name: "South Sudan", alpha2: "SS", number: "211" },
    STP: { name: "São Tomé and Príncipe", alpha2: "ST", number: "239" },
    SUR: { name: "Suriname", alpha2: "SR", number: "597" },
    SVK: { name: "Slovakia", alpha2: "SK", number: "421" },
    SVN: { name: "Slovenia", alpha2: "SI", number: "386" },
    SWE: { name: "Sweden", alpha2: "SE", number: "46" },
    SWZ: { name: "Swaziland", alpha2: "SZ", number: "268" },
    SXM: { name: "Sint Maarten", alpha2: "SX", number: "1" },
    SYC: { name: "Seychelles", alpha2: "SC", number: "248" },
    SYR: { name: "Syria", alpha2: "SY", number: "963" },
    TCA: { name: "Turks and Caicos Islands", alpha2: "TC", number: "1" },
    TCD: { name: "Chad", alpha2: "TD", number: "235" },
    TGO: { name: "Togo", alpha2: "TG", number: "228" },
    THA: { name: "Thailand", alpha2: "TH", number: "66" },
    TJK: { name: "Tajikistan", alpha2: "TJ", number: "992" },
    TKL: { name: "Tokelau", alpha2: "TK", number: "690" },
    TKM: { name: "Turkmenistan", alpha2: "TM", number: "993" },
    TLS: { name: "East Timor", alpha2: "TL", number: "670" },
    TON: { name: "Tonga", alpha2: "TO", number: "676" },
    TTO: { name: "Trinidad and Tobago", alpha2: "TT", number: "1" },
    TUN: { name: "Tunisia", alpha2: "TN", number: "216" },
    TUR: { name: "Turkey", alpha2: "TR", number: "90" },
    TUV: { name: "Tuvalu", alpha2: "TV", number: "688" },
    TWN: { name: "Taiwan", alpha2: "TW", number: "886" },
    TZA: { name: "Tanzania, United Republic of", alpha2: "TZ", number: "255" },
    UGA: { name: "Uganda", alpha2: "UG", number: "256" },
    UKR: { name: "Ukraine", alpha2: "UA", number: "380" },
    UMI: {
      name: "United States Minor Outlying Islands",
      alpha2: "UM",
      number: "1",
    },
    URY: { name: "Uruguay", alpha2: "UY", number: "598" },
    USA: { name: "United States of America", alpha2: "US", number: "1" },
    UZB: { name: "Uzbekistan", alpha2: "UZ", number: "998" },
    VAT: { name: "Vatican City", alpha2: "VA", number: "39" },
    VCT: {
      name: "Saint Vincent and the Grenadines",
      alpha2: "VC",
      number: "1",
    },
    VEN: {
      name: "Venezuela (Bolivarian Republic of)",
      alpha2: "VE",
      number: "58",
    },
    VGB: { name: "British Virgin Islands", alpha2: "VG", number: "1" },
    VIR: {
      name: "Virgin Islands of the United States",
      alpha2: "VI",
      number: "1",
    },
    VNM: { name: "Vietnam", alpha2: "VN", number: "84" },
    VUT: { name: "Vanuatu", alpha2: "VU", number: "678" },
    WLF: { name: "Wallis and Futuna", alpha2: "WF", number: "681" },
    WSM: { name: "Samoa", alpha2: "WS", number: "685" },
    XKX: { name: "Kosovo", alpha2: "XK", number: "393" },
    YEM: { name: "Yemen", alpha2: "YE", number: "967" },
    ZAF: { name: "South Africa", alpha2: "ZA", number: "27" },
    ZMB: { name: "Zambia", alpha2: "ZM", number: "260" },
    ZWE: { name: "Zimbabwe", alpha2: "ZW", number: "263" },
  },
  recruiter_email_settings: [
    {
      value: "recruiter_candidate_updates",
      label: "Candidate Updates",
      immediate: { available: true, fixed: false },
      daily: { available: true, fixed: false },
    },
    {
      value: "recruiter_jobcast_updates",
      label: "JobCast Updates",
      immediate: { available: true, fixed: false },
      daily: { available: true, fixed: false },
    },
    {
      value: "individual_new_chat_message_updates",
      label: "New Chat Messages",
      immediate: { available: true, fixed: false },
      daily: { available: true, fixed: false },
    },
    {
      value: "recruiter_new_job_cast_updates",
      label: "New Community JobCasts",
      immediate: { available: true, fixed: false },
      daily: { available: true, fixed: false },
    },
    {
      value: "legacy_new_job_cast_updates",
      label: "New Preferred JobCasts",
      immediate: { available: true, fixed: false },
      daily: { available: true, fixed: false },
    },
  ],
  us_states: {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District of Columbia",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  },
  ca_provinces: {
    AB: "Alberta",
    BC: "British Columbia",
    MB: "Manitoba",
    NB: "New Brunswick",
    NL: "Newfoundland and Labrador",
    NS: "Nova Scotia",
    ON: "Ontario",
    PE: "Prince Edward Island",
    QC: "Quebec",
    SK: "Saskatchewan",
  },
  currency_options: {
    USD: { notation: "$", code: "USD", decimal: 2 },
    ARS: { notation: "$", code: "ARS", decimal: 2 },
    BGN: { notation: "Лв.", code: "BGN", decimal: 2 },
    CAD: { notation: "CAN$", code: "CAD", decimal: 2 },
    COP: { notation: "COL$", code: "COP", decimal: 2 },
    EUR: { notation: "€", code: "EUR", decimal: 2 },
    GBP: { notation: "£", code: "GBP", decimal: 2 },
    HKD: { notation: "HK$", code: "HKD", decimal: 2 },
    JPY: { notation: "¥", code: "JPY", decimal: 0 },
    INR: { notation: "₹", code: "INR", decimal: 2 },
    AED: { notation: "د.إ", code: "AED", decimal: 2 },
    AUD: { notation: "A$", code: "AUD", decimal: 2 },
    CNY: { notation: "¥", code: "CNY", decimal: 2 },
    BRL: { notation: "R$", code: "BRL", decimal: 2 },
    NZD: { notation: "NZ$", code: "NZD", decimal: 2 },
    SGD: { notation: "S$", code: "SGD", decimal: 2 },
    MXN: { notation: "MEX$", code: "MXN", decimal: 2 },
    KRW: { notation: "₩", code: "KRW", decimal: 0 },
    TWD: { notation: "NT$", code: "TWD", decimal: 2 },
    THB: { notation: "฿", code: "THB", decimal: 2 },
    CZK: { notation: "Kč", code: "CZK", decimal: 2 },
    IDR: { notation: "Rp", code: "IDR", decimal: 2 },
    MYR: { notation: "RM", code: "MYR", decimal: 2 },
    VND: { notation: "₫", code: "VND", decimal: 0 },
    PAB: { notation: "฿", code: "PAB", decimal: 2 },
    PHP: { notation: "₱", code: "PHP", decimal: 2 },
    NOK: { notation: "kr", code: "NOK", decimal: 2 },
    // RUB: { notation: "₽", code: "RUB", decimal: 2 },
    SAR: { notation: "ر.س", code: "SAR", decimal: 2 },
    ZAR: { notation: "R", code: "ZAR", decimal: 2 },
    HUF: { notation: "Ft", code: "HUF", decimal: 0 },
    SEK: { notation: "kr", code: "SEK", decimal: 2 },
    DKK: { notation: "kr", code: "DKK", decimal: 2 },
    JMD: { notation: "$", code: "JMD", decimal: 2 },
    EGP: { notation: "£", code: "EGP", decimal: 2 },
    MAD: { notation: "DH", code: "MAD", decimal: 2 },
    CRC: { notation: "₡", code: "CRC", decimal: 2 },
  },
  us_regions: {
    AL: "Southeast Sunbelt",
    AK: "Northwest",
    AZ: "Pacific Rim",
    AR: "Greater Southwest",
    CA: "Pacific Rim",
    CO: "Rocky Mountain",
    CT: "New England",
    DE: "Mid-Atlantic",
    FL: "Southeast Sunbelt",
    GA: "Southeast Sunbelt",
    HI: "Northwest",
    ID: "Northwest",
    IL: "Great Lakes",
    IN: "Great Lakes",
    IA: "Heartland",
    KS: "Heartland",
    KY: "Southeast Sunbelt",
    LA: "Greater Southwest",
    ME: "New England",
    MD: "Mid-Atlantic",
    MA: "New England",
    MI: "Great Lakes",
    MN: "Great Lakes",
    MS: "Southeast Sunbelt",
    MO: "Heartland",
    MT: "Rocky Mountain",
    NE: "Heartland",
    NV: "Pacific Rim",
    NH: "New England",
    NJ: "Northeast",
    NM: "Greater Southwest",
    NY: "Northeast",
    NC: "Southeast Sunbelt",
    ND: "Rocky Mountain",
    OH: "Great Lakes",
    OK: "Greater Southwest",
    OR: "Northwest",
    PA: "Mid-Atlantic",
    RI: "New England",
    SC: "Southeast Sunbelt",
    SD: "Rocky Mountain",
    TN: "Southeast Sunbelt",
    TX: "Greater Southwest",
    UT: "Rocky Mountain",
    VT: "New England",
    VA: "Mid-Atlantic",
    DC: "Mid-Atlantic",
    WA: "Northwest",
    WV: "Mid-Atlantic",
    WI: "Great Lakes",
    WY: "Rocky Mountain",
  },
  regions: {
    ALB: "Albania",
    ARG: "Argentina",
    AUS: "Australia",
    AUT: "Austria",
    BHR: "Bahrain",
    BLR: "Belarus",
    BEL: "Belgium",
    BLZ: "Belize",
    BRA: "Brazil",
    BGR: "Bulgaria",
    CAN: "Canada",
    CYM: "Cayman Islands",
    CHL: "Chile",
    CHN: "China",
    COL: "Colombia",
    CRI: "Costa Rica",
    HRV: "Croatia",
    CZE: "Czech Republic",
    DNK: "Denmark",
    DOM: "Dominican Republic",
    EGY: "Egypt",
    EST: "Estonia",
    FIN: "Finland",
    FRA: "France",
    DEU: "Germany",
    GRC: "Greece",
    GTM: "Guatemala",
    GUY: "Guyana",
    HKG: "Hong Kong",
    HUN: "Hungary",
    IND: "India",
    IDN: "Indonesia",
    IRL: "Ireland",
    ISR: "Israel",
    ITA: "Italy",
    JAM: "Jamaica",
    JPN: "Japan",
    KEN: "Kenya",
    KOR: "Korea, Republic of",
    XKX: "Kosovo",
    LTU: "Lithuania",
    MAC: "Macau",
    MKD: "Macedonia",
    MYS: "Malaysia",
    MEX: "Mexico",
    NLD: "Netherlands",
    NZL: "New Zealand",
    NGA: "Nigeria",
    NOR: "Norway",
    PAN: "Panama",
    PER: "Peru",
    PHL: "Philippines",
    POL: "Poland",
    PRT: "Portugal",
    ROU: "Romania",
    // RUS: "Russian Federation",
    SAU: "Saudi Arabia",
    SRB: "Serbia",
    SGP: "Singapore",
    SVN: "Slovenia",
    ZAF: "South Africa",
    ESP: "Spain",
    SWE: "Sweden",
    CHE: "Switzerland",
    TWN: "Taiwan",
    THA: "Thailand",
    TUR: "Turkey",
    UKR: "Ukraine",
    ARE: "United Arab Emirates",
    GBR: "United Kingdom",
    USA: "United States of America",
    VNM: "Vietnam",
  },
  industries: [
    "Agriculture",
    "Art",
    "Construction",
    "Corporate",
    "Education",
    "Finance",
    "Goods",
    "Government",
    "Health",
    "Legal",
    "Manufacturing",
    "Media",
    "Non-Profit Organization",
    "Recreation",
    "Service",
    "Technology",
    "Transportation",
  ],
  guaranteeFailureReasons: [
    "Candidate no longer with company",
    "Candidate internally sourced within the last 180 days",
    "Other",
  ],
  genericSuccessContent: [
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_rocket.png",
      title: "You're a star!",
      bodyText: "That's one small step for man, one giant leap for mankind.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_rocket.png",
      title: "Mission accomplished!",
      bodyText: "Shoot for the moon - if you miss you'll end up in the stars.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_smile.png",
      title: "Happy days!",
      bodyText:
        "Choose a job you love, and you will never have to work a day in your life.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_smile.png",
      title: "Celebrate!",
      bodyText:
        "Doing what you like is freedom. Liking what you do is happiness.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_five.png",
      title: "Sky's the limit!",
      bodyText: "You deserve the highest of fives.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_five.png",
      title: "Keep it up!",
      bodyText: "Teamwork makes the dream work.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_cup.png",
      title: "Victory!",
      bodyText:
        "Winners are not people who never fail, but people who never quit.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_cup.png",
      title: "Success!",
      bodyText: "Winning takes talent. To repeat takes character.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_ribbon.png",
      title: "Top dog!",
      bodyText: "Victory belongs to the most persevering.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_ribbon.png",
      title: "Best in show!",
      bodyText:
        "Accept the challenges so that you can feel the exhilaration of victory.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_peace.png",
      title: "Serenity Now!",
      bodyText: "Stay humble. Work hard. Be kind.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_peace.png",
      title: "Far Out!",
      bodyText: "There is no greater wealth in this world than peace of mind.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_flag.png",
      title: "Top of the world!",
      bodyText: "The best view comes after the hardest climb.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_flag.png",
      title: "Top of your game!",
      bodyText: "Every mountain top is within reach if you just keep climbing.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_crown.png",
      title: "Crowning achievement!",
      bodyText: "Every accomplishment starts with the decision to try.",
    },
    {
      imageURL: "https://recruitifi-public.s3.amazonaws.com/success_crown.png",
      title: "Worthy of a crown!",
      bodyText:
        "The achievement of your goal is assured the moment you commit yourself to it.",
    },
  ],
  genericEmptyStateContent: [
    {
      imageURL:
        "https://recruitifi-public.s3.amazonaws.com/candidate-empty/Empty_Mail.png",
      title: "Package hasn't arrived yet! Check back later.",
    },
    {
      imageURL:
        "https://recruitifi-public.s3.amazonaws.com/candidate-empty/Empty_Hourglass.png",
      title: "It’s not quite time for something to be here!",
    },
    {
      imageURL:
        "https://recruitifi-public.s3.amazonaws.com/candidate-empty/Empty_Binoc.png",
      title: "Nothing to see here!",
    },
    {
      imageURL:
        "https://recruitifi-public.s3.amazonaws.com/candidate-empty/Empty_Map.png",
      title: "There’s nothing at this destination!",
    },
    {
      imageURL:
        "https://recruitifi-public.s3.amazonaws.com/candidate-empty/Empty_Lighthouse.png",
      title: "Looks like your ship hasn’t come in yet. Check back soon!",
    },
  ],
  joinRecruitifiContent: [
    {
      imageURL:
        "https://recruitifi-public.s3.amazonaws.com/images/registration_icons/target.png",
      title: "Invitations to work on jobs based on your specializations",
      alt: "Targeted Jobs",
    },
    {
      imageURL:
        "https://recruitifi-public.s3.amazonaws.com/images/registration_icons/communication.png",
      title:
        "Live updates on your candidates, and direct communication with employers",
      alt: "Consolidated Communication",
    },
    {
      imageURL:
        "https://recruitifi-public.s3.amazonaws.com/images/registration_icons/gold_bars.png",
      title: "Automated collections and payouts directly to your bank account",
      alt: "Lower Costs",
    },
  ],
  publicEmailDomains: [
    /* Default domains included */
    "aol.com",
    "att.net",
    "comcast.net",
    "facebook.com",
    "gmail.com",
    "gmx.com",
    "googlemail.com",
    "google.com",
    "hotmail.com",
    "hotmail.co.uk",
    "mac.com",
    "me.com",
    "mail.com",
    "msn.com",
    "live.com",
    "sbcglobal.net",
    "verizon.net",
    "yahoo.com",
    "yahoo.co.uk",

    /* Other global domains */
    "email.com",
    "fastmail.fm",
    "games.com" /* AOL */,
    "gmx.net",
    "hush.com",
    "hushmail.com",
    "icloud.com",
    "iname.com",
    "inbox.com",
    "lavabit.com",
    "love.com" /* AOL */,
    "outlook.com",
    "pobox.com",
    "protonmail.ch",
    "protonmail.com",
    "tutanota.de",
    "tutanota.com",
    "tutamail.com",
    "tuta.io",
    "keemail.me",
    "rocketmail.com" /* Yahoo */,
    "safe-mail.net",
    "wow.com" /* AOL */,
    "ygm.com" /* AOL */,
    "ymail.com" /* Yahoo */,
    "zoho.com",
    "yandex.com",

    /* United States ISP domains */
    "bellsouth.net",
    "charter.net",
    "cox.net",
    "earthlink.net",
    "juno.com",

    /* British ISP domains */
    "btinternet.com",
    "virginmedia.com",
    "blueyonder.co.uk",
    "freeserve.co.uk",
    "live.co.uk",
    "ntlworld.com",
    "o2.co.uk",
    "orange.net",
    "sky.com",
    "talktalk.co.uk",
    "tiscali.co.uk",
    "virgin.net",
    "wanadoo.co.uk",
    "bt.com",

    /* Domains used in Asia */
    "sina.com",
    "sina.cn",
    "qq.com",
    "naver.com",
    "hanmail.net",
    "daum.net",
    "nate.com",
    "yahoo.co.jp",
    "yahoo.co.kr",
    "yahoo.co.id",
    "yahoo.co.in",
    "yahoo.com.sg",
    "yahoo.com.ph",
    "163.com",
    "yeah.net",
    "126.com",
    "21cn.com",
    "aliyun.com",
    "foxmail.com",

    /* French ISP domains */
    "hotmail.fr",
    "live.fr",
    "laposte.net",
    "yahoo.fr",
    "wanadoo.fr",
    "orange.fr",
    "gmx.fr",
    "sfr.fr",
    "neuf.fr",
    "free.fr",

    /* German ISP domains */
    "gmx.de",
    "hotmail.de",
    "live.de",
    "online.de",
    "t-online.de" /* T-Mobile */,
    "web.de",
    "yahoo.de",

    /* Italian ISP domains */
    "libero.it",
    "virgilio.it",
    "hotmail.it",
    "aol.it",
    "tiscali.it",
    "alice.it",
    "live.it",
    "yahoo.it",
    "email.it",
    "tin.it",
    "poste.it",
    "teletu.it",

    /* Russian ISP domains */
    "mail.ru",
    "rambler.ru",
    "yandex.ru",
    "ya.ru",
    "list.ru",

    /* Belgian ISP domains */
    "hotmail.be",
    "live.be",
    "skynet.be",
    "voo.be",
    "tvcablenet.be",
    "telenet.be",

    /* Argentinian ISP domains */
    "hotmail.com.ar",
    "live.com.ar",
    "yahoo.com.ar",
    "fibertel.com.ar",
    "speedy.com.ar",
    "arnet.com.ar",

    /* Domains used in Mexico */
    "yahoo.com.mx",
    "live.com.mx",
    "hotmail.es",
    "hotmail.com.mx",
    "prodigy.net.mx",

    /* Domains used in Canada */
    "yahoo.ca",
    "hotmail.ca",
    "bell.net",
    "shaw.ca",
    "sympatico.ca",
    "rogers.com",

    /* Domains used in Brazil */
    "yahoo.com.br",
    "hotmail.com.br",
    "outlook.com.br",
    "uol.com.br",
    "bol.com.br",
    "terra.com.br",
    "ig.com.br",
    "itelefonica.com.br",
    "r7.com",
    "zipmail.com.br",
    "globo.com",
    "globomail.com",
    "oi.com.br",
  ],
  // public email domain addresses that we dont want to fail public email address validation
  whitelistedEmailAddresses: [],
  candidateStages: ["REVIEWING", "INTERVIEWING", "OFFER", "HIRE"],
};
