import { SvgIcon } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { openModal, openProfileDrawer } from '../../../actions/shared/uiActions';
import { getCandidate } from "../../../selectors/candidateSelectors";
import { CONTRACT_WORK } from '../../employer/JobCasts/view/Candidates/constants';
import EmployerCandidateDrawer from '../../employer/JobCasts/view/Candidates/view/CandidateDrawer';

import ActionMenu from '../../General/ActionMenu';
import SubmitHoursModal from '../../recruiter/Candidates/actions/modals/SubmitHoursModal';
import RecruiterCandidateDrawer from '../../recruiter/Candidates/view/CandidateDrawer';

import CloseContractModal from './CloseContractModal';
import EditContractModal from './EditContractModal';

const SUBMIT_HOURS = "add_hours";
const CLOSE_CONTRACT = 'end_contract';
const CONVERT_CONTRACT = 'convert_contract';
const EDIT_CONTRACT = 'edit_contract';

const getActions = (candidate, { recruiter, employer }) => {
  switch (candidate.attributes.status) {
    case "contract_active":
      return [
        {
          name: SUBMIT_HOURS,
          icon: <AttachMoneyIcon />,
          isVisible: recruiter && candidate.attributes.payPeriodsCount > 0,
        },
        {
          name: CONVERT_CONTRACT,
          icon: <CheckIcon />,
          isVisible: employer,
        },
        {
          name: CLOSE_CONTRACT,
          icon: (
            <SvgIcon>
              <path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M9,9H15V15H9" />
            </SvgIcon>
          ),
          style: { color: "#008dae" },
          isVisible: candidate.attributes.contractEndedAt == null,
        },
        {
          name: EDIT_CONTRACT,
          icon: <EditIcon />,
          isVisible: employer,
        }
      ];
    default:
      return [];
  }
};

const actionhandler = (candidate, dispatch) => (actionName) => {
  switch (actionName) {
    case CLOSE_CONTRACT:
      dispatch(openModal(<CloseContractModal candidate={candidate} />));
      break;
    case SUBMIT_HOURS:
      dispatch(openModal(
        <SubmitHoursModal candidateId={candidate.id} {...{ setSubmitted: () => {} }} />
      ));
      break;
    case EDIT_CONTRACT:
      dispatch(openModal(
        <EditContractModal candidate={candidate} />
      ));
      break;
    case CONVERT_CONTRACT:
      break;
    default:
      break;
  }
};

export function ActionMenuWrapper({ recruiterSubmissionId, recruiter = false, employer = false }) {
  const dispatch = useDispatch();
  const candidate = useSelector(
    (state) => getCandidate(state, recruiterSubmissionId),
    shallowEqual
  );

  return (
    <ActionMenu
      actions={getActions(candidate, { recruiter, employer })}
      handleActionSelection={actionhandler(candidate, dispatch)}
    />
  );
}

export function RecruiterSubmissionName(
  { employer = false, recruiter = false, recruiterSubmissionId }
) {
  const dispatch = useDispatch();
  const recruiterSubmission = useSelector((state) => (
    getCandidate(state, recruiterSubmissionId)
  ));

  if (!employer && !recruiter) throw new Error("Provide either recruiter or employer as prop");

  function handleClick() {
    const CandidateDrawer = (() => {
      if (employer) return EmployerCandidateDrawer;
      if (recruiter) return RecruiterCandidateDrawer;
      return null;
    })();

    dispatch(openProfileDrawer(
      <CandidateDrawer candidateId={recruiterSubmissionId} navSelection={CONTRACT_WORK} />,
      recruiterSubmission.attributes.jobcastId
    ));
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className="truncate psuedo-link"
      style={{
        color: "#008DAE",
        textDecoration: "underline",
        cursor: "pointer"
      }}
      onClick={handleClick}
      onKeyDown={handleClick}
    >
      {recruiterSubmission.attributes.firstName}
      {" "}
      {recruiterSubmission.attributes.lastName}
    </div>
  );
}
