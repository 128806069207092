import Avatar from "@material-ui/core/Avatar";
import React from "react";

import logo from "../../assets/images/logo-square.svg";

const isDefaultUrl = (avatarUrl) => avatarUrl && avatarUrl.match(/default_avatars/);

class GlobalAvatar extends React.Component {
  constructor(props) {
    super(props);
    let height, width, size;

    if (typeof props.size === "number") {
      size = props.size;
    } else {
      switch (props.size) {
        case "extra large":
          size = 100;
          break;
        case "large":
          size = 80;
          break;
        case "medium":
        case "small":
        default:
          size = 40;
      }
    }

    if (props.height && props.width) {
      width = props.width;
      height = props.height;
    } else {
      width = size;
      height = size;
    }

    this.state = {
      style: {
        width,
        height,
        fontSize: size / 2,
        borderRadius: "50%",
        cursor: props.onClick ? "pointer" : "default",
      },
    };
    this.style = this.props.overrideStyle
      ? Object.assign(this.state.style, this.props.overrideStyle)
      : this.state.style;
  }

  static getColor = (_f, _l, firstName = String(_f), lastName = String(_l)) => {
    const str = `${firstName} ${lastName}`;

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    return color;
  };

  static getInitialsFromMulti = (
    _f,
    _l,
    firstName = String(_f),
    lastName = String(_l)
  ) => {
    const firstInitial = (firstName && firstName[0].toUpperCase()) || "";
    const lastInitial = (lastName && lastName[0].toUpperCase()) || "";

    return firstInitial + lastInitial || "?";
  };

  static getInitialsFromSingle = (_n, name = String(_n)) => name.slice(0, 2).toUpperCase();

  renderAvatar(firstName, lastName, avatar_url) {
    const alt = firstName && lastName ? `${firstName} ${lastName}` : "avatar";

    return (
      <Avatar
        alt={alt}
        src={avatar_url}
        style={this.style}
        imgProps={{
          onError: (e) => {
            e.target.src = logo;
            e.target.style.cssText = this.style;
          },
        }}
        onClick={this.props.onClick}
      />
    );
  }

  renderImage(avatarIcon) {
    const { avatarAlt, organization } = this.props;

    return (
      <img
        src={avatarIcon}
        style={this.style}
        alt={
          avatarAlt ||
          (organization && organization.name) ||
          avatarIcon
        }
        onClick={this.props.onClick}
      />
    );
  }

  renderTextMulti(firstName, lastName) {
    const backgroundColor = this.constructor.getColor(firstName, lastName);
    return (
      <Avatar
        style={{ backgroundColor, ...this.state.style }}
        onClick={this.props.onClick}
      >
        {this.constructor.getInitialsFromMulti(firstName, lastName)}
      </Avatar>
    );
  }

  renderTextSingle(name) {
    const backgroundColor = this.constructor.getColor(name);
    return (
      <Avatar
        style={{ backgroundColor, ...this.state.style }}
        onClick={this.props.onClick}
      >
        {this.constructor.getInitialsFromSingle(name)}
      </Avatar>
    );
  }

  renderNoAvatar() {
    const { height, width } = this.state.style;
    return (
      <>
        <div
          style={{
            height,
            width,
            backgroundColor: "#F5F5F5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
        {`Preview of sizing ${width} x ${height}`}
      </>
    );
  }

  render() {
    if (this.props.avatarIcon) {
      return this.renderImage(this.props.avatarIcon);
    }
    if (this.props.individual) {
      const { avatarIcon, firstName, lastName } = this.props.individual;
      if (
        avatarIcon &&
        avatarIcon.length &&
        (!isDefaultUrl(avatarIcon) || !(firstName && lastName))
      ) {
        return this.renderAvatar(firstName, lastName, avatarIcon);
      }
      return this.renderTextMulti(firstName, lastName);
    }
    if (this.props.organization) {
      const { avatarIcon } = this.props.organization;
      const { jobcastSearch, isEditing } = this.props;

      if (this.props.overrideDefault) {
        return this.renderNoAvatar();
      }

      // Render default corporate logo
      if (avatarIcon) {
        if (jobcastSearch || !(isEditing && isDefaultUrl(avatarIcon))) {
          return this.renderImage(avatarIcon);
        }
      }

      return this.renderNoAvatar();
    }
    return this.renderTextSingle("?");
  }
}

export default GlobalAvatar;
