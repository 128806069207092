import React from "react";

import {
  BASIC_CONFIRMATION_FIELD,
  DATE_FIELD,
  DOCUMENT_FIELD,
  SOW_FIELD,
  determineChecklistItemType,
} from "../fieldHelpers";

import BasicConfirmationFieldRow from "./BasicConfirmationFieldRow";
import DateFieldRow from "./DateFieldRow";
import DocumentFieldRow from "./DocumentFieldRow";
import SOWFieldRow from "./SOWFieldRow";

function TypedChecklistItemRow({ checklistItem, editable, fieldId }) {
  const rowType = determineChecklistItemType(checklistItem);

  const RowComponent = {
    [SOW_FIELD]: SOWFieldRow,
    [BASIC_CONFIRMATION_FIELD]: BasicConfirmationFieldRow,
    [DATE_FIELD]: DateFieldRow,
    [DOCUMENT_FIELD]: DocumentFieldRow,
  }[rowType];

  return React.createElement(RowComponent, {
    checklistItem,
    editable,
    fieldId,
  });
}

export default TypedChecklistItemRow;
