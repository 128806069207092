import React from 'react';
import { useDispatch } from "react-redux";

import { fetchJobcastRequest } from "../../../../../../actions/recruiters/jobcastActions";
import Button from "../../../../../forms/custom/Button";

import useSnackbar from "../../../../../General/useSnackbar";

const convertContract = (candidateId) => (dispatch) => Promise.resolve();

function ConvertContractButton({ candidate, overrideStyle, overloadedClasses }) {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const handleClick = () => {
    dispatch(convertContract(candidate.id))
      .then(() => {
        snackbar.showMessage("Contract converted");
      })
      .then(() => {
        dispatch(fetchJobcastRequest(candidate.attributes.requestId));
      });
  };

  return (
    <Button
      color="blue"
      variant="primary"
      onClick={handleClick}
      style={overrideStyle || {}}
      overloadedClasses={overloadedClasses}
      data-cy="convert-contract-button"
    >
      Hire
    </Button>
  );
}

export default ConvertContractButton;
