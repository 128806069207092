import TextField from "@material-ui/core/TextField";
import { useFormikContext } from "formik";
import sum from "lodash/sum";
import React, {
  useMemo, useEffect, useState, useRef
} from "react";
import { Row, Col } from "react-grid-system";
import { useSelector } from "react-redux";

import { makeGetFilteredContracts } from "../../../../../../selectors/contractSelector";

import { makeGetPayPeriodItems } from "../../../../../../selectors/payPeriodSelectors";
import { makeGetCandidate } from "../../../../../../selectors/recruiter/candidateSelectors";
import Checkbox from "../../../../../forms/custom/Checkbox";

import FormContextNumberInput from "../../../../../forms/formik/FormContextInputs/FormContextNumberInput";
import FormContextTextField from "../../../../../forms/formik/FormContextInputs/FormContextTextField";
import {
  calculateEarnings,
  calculateTotalBill,
  customSubTextFieldProps,
  formatter,
  payPeriodStatusMessage,
} from "../utils";

const disabledProps = (disabled) => (disabled ? {
  InputProps: {
    style: { color: "#90A4AE !important" },
  },
  InputLabelProps: { className: "disabled-input-label", shrink: true },
} : {});

export default function PayPeriodForm({ payPeriodId }) {
  const {
    values, setFieldValue
  } = useFormikContext();

  const getPayPeriods = useMemo(makeGetPayPeriodItems, []);
  const payPeriod = useSelector(
    (state) => getPayPeriods(state)[payPeriodId]
  );

  const getContracts = useMemo(makeGetFilteredContracts, []);
  const contract = useSelector(
    (state) => getContracts(state, { contractId: payPeriod?.attributes?.contractId })[0]
  );

  const getCandidate = useMemo(makeGetCandidate, []);
  const candidate = useSelector(
    (state) => getCandidate(state, contract?.attributes?.recruiterSubmissionId)
  );

  useEffect(() => {
    setFieldValue(
      "totalHours",
      sum([
        values.standardHours || 0,
        values.nonStandardHours || 0,
        values.doubleTimeHours || 0,
      ])
    );
  }, [
    values.standardHours,
    values.nonStandardHours,
    values.doubleTimeHours,
    setFieldValue,
  ]);

  // TODO: loading state?
  if (!payPeriod) return null;

  return (
    <div>
      <Row style={{ paddingTop: 12 }}>
        <Col>
          <Checkbox
            id="zeroHours"
            label="Zero hours worked on this contract during this work week"
            value={values.zeroHours}
            disabled={!!payPeriod.attributes.submittedAt}
            onChange={() => {
              setFieldValue("zeroHours", !values.zeroHours);
              setFieldValue("standardHours", values.zeroHours ? null : 0);
              setFieldValue("nonStandardHours", values.zeroHours ? null : 0);
              setFieldValue("doubleTimeHours", values.zeroHours ? null : 0);
              setFieldValue("additionalBilling", values.zeroHours ? null : 0);
              setFieldValue("additionalBillingReason", values.zeroHours ? null : "");
            }}
          />
        </Col>
      </Row>
      <Row style={{ padding: "0 0 12px 0" }}>
        <Col>
          <FormContextNumberInput
            disabled={!!payPeriod.attributes.submittedAt || values.zeroHours}
            decimalScale={2}
            label="Standard Hours"
            id="standardHours"
          />
        </Col>
        <Col>
          <FormContextNumberInput
            disabled={!!payPeriod.attributes.submittedAt || values.zeroHours}
            decimalScale={2}
            label="Overtime Hours"
            id="nonStandardHours"
          />
        </Col>
        <Col>
          <FormContextNumberInput
            disabled={!!payPeriod.attributes.submittedAt || values.zeroHours}
            decimalScale={2}
            label="Doubletime Hours"
            id="doubleTimeHours"
          />
        </Col>
      </Row>
      <Row style={{ padding: "0 0 12px 0" }}>
        <Col md={4}>
          <FormContextNumberInput
            disabled={!!payPeriod.attributes.submittedAt || values.zeroHours}
            decimalScale={2}
            label="Additional Billing"
            id="additionalBilling"
            InputProps={{
              startAdornment: "$",
              inputProps: { max: 5000 }
            }}
          />
        </Col>
        <Col>
          <FormContextTextField
            disabled={!!payPeriod.attributes.submittedAt || values.zeroHours}
            id="additionalBillingReason"
            label="Additional Billing Explanation"
            {...disabledProps(!!payPeriod.attributes.submittedAt)}
            inputProps={{
              maxLength: 1000
            }}
          />
        </Col>
      </Row>
      <Row style={{ padding: "0 0 12px 0" }}>
        <Col md={4}>
          <TextField
            {...customSubTextFieldProps}
            id="total-bill"
            label="Total Bill"
            value={formatter.format(
              (payPeriod.attributes.employerCollectionAmount || 0) > 0
                ? payPeriod.attributes.employerCollectionAmount
                : calculateTotalBill(values, candidate)
            )}
          />
        </Col>
        <Col md={4}>
          <TextField
            {...customSubTextFieldProps}
            id="total-earn"
            label="Total Earn"
            value={formatter.format(
              (payPeriod.attributes.recruiterPayoutAmount || 0) > 0
                ? payPeriod.attributes.recruiterPayoutAmount
                : calculateEarnings(values, candidate)
            )}
          />
        </Col>
      </Row>
      <Row style={{ padding: "0 0 12px 0" }}>
        <Col>
          <TextField
            {...customSubTextFieldProps}
            id="payperiod-status"
            label="Status"
            value={payPeriodStatusMessage(payPeriod)}
            multiline
          />
        </Col>
      </Row>
    </div>
  );
}
